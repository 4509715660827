export default class Constante {
    // seqUsuario = 91;
    // seqUsuarioVendedor = 91;
    // seqPessoaPerfilAdministrativo = 15;
    // seqPessoaPerfilPolo = 16;
    // seqPessoaPerfilAluno = 17;
    // seqPessoaPerfilParenteAluno = 18;
    // seqPessoaCaracteristicaSolteiro = 65;
    // seqPessoaCaracteristicaCasado = 66;
    // seqPessoaCaracteristicaViuvo = 67;
    // seqTipoRelacionamentoMae = 11;
    // seqTipoRelacionamentoPai = 12;
    // seqTipoRelacionamentoConjuge = 13;
    // seqTabelaPreco = 41;
    // seqTipoDocumento = 30;
    // seqTipoContatoCelular = 1;
    // seqTipoContatoWhatsApp = 3;
    // seqTipoContatoEmail = 2;
    // seqTipoArquivoAnexoRg = 12;
    // seqTipoArquivoAnexoCpf = 13;
    // seqTipoArquivoAnexoComprovanteResidencia = 14;
    // seqTipoArquivoAnexoCertidaoNascimento = 15;
    // seqTipoArquivoAnexoCertidaoCasamento = 16;
    // seqTipoArquivoAnexoHistoricoEscolar = 17;
    // seqTipoArquivoAnexoCertificadoEnsinoMedio = 18;
    // seqTipoArquivoAnexoFotoTresPorQuatro = 19;
    // seqTipoArquivoAnexoCertidaoReservista = 20;
    // seqTipoArquivoAnexoTituloEleitor = 21;


    seqUsuario = 91;
    seqUsuarioVendedor = 91;
    seqPessoaPerfilAluno = 15;
    seqPessoaPerfilAdministrativo = 16;
    seqPessoaPerfilPolo = 17;
    seqPessoaPerfilParenteAluno = 18;
    // seqPessoaCaracteristicaSolteiro = 62;
    // seqPessoaCaracteristicaCasado = 63;
    // seqPessoaCaracteristicaViuvo = 64;
    seqTipoRelacionamentoPai = 9;
    seqTipoRelacionamentoMae = 10;
    seqTipoRelacionamentoConjuge = 11;
    seqTipoRelacionamentoPolo = 12;
    seqTipoRelacionamentoAluno = 14;
    seqTabelaPreco = 43;
    seqTipoDocumento = 31;
    seqTipoContatoCelular = 1;
    seqTipoContatoWhatsApp = 3;
    seqTipoContatoEmail = 2;
    seqTipoArquivoAnexoRg = 12;
    seqTipoArquivoAnexoCpf = 13;
    seqTipoArquivoAnexoComprovanteResidencia = 14;
    seqTipoArquivoAnexoCertidaoNascimento = 15;
    seqTipoArquivoAnexoCertidaoCasamento = 16;
    seqTipoArquivoAnexoHistoricoEscolar = 17;
    seqTipoArquivoAnexoCertificadoEnsinoMedio = 18;
    seqTipoArquivoAnexoFotoTresPorQuatro = 19;
    seqTipoArquivoAnexoCertidaoReservista = 20;
    seqTipoArquivoAnexoTituloEleitor = 21;
    seqTipoArquivoAnexoQuitacaoEleitoral = 23;
    seqTipoArquivoAnexoDiarioOficial = 24;
    seqDocumentoCaracteristicaSolteiro = 8;
    seqDocumentoCaracteristicaCasado = 9;
    seqDocumentoCaracteristicaViuvo = 10;
    seqTipoDocumentoProcessoNovo = 53;
    seqTipoAtendimentoPadrao = 12;
}