import React, { Component } from 'react'

export default class HistoricoEscolarTecnico extends Component {
    state = {
        tela: 1
    }
    render() {
        return (
            <div className="container">
                <div className='historico-container'>
                    {this.state.tela == 1 ?
                        <>
                            <section className="secao-1">

                                <div className="box-centralizador">
                                    <h1 className="texto-medio">Parecer nº 001.MV.01055019/SEEDYC/COGIE/2019 Processo Nº E-03/016/2196/2019</h1>
                                </div>

                                <div className="box-centralizador">
                                    <div className="box-titulo1-historico">
                                        <h1 className="texto-grande">HISTÓRICO ESCOLAR</h1>
                                        <div className="box-borda-quadrada">
                                            <p className="texto-pequeno">
                                                Código Escolar INEP
                                            </p>
                                            <p className="texto-pequeno">
                                                3318829
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-texto-linha">
                                    <h1 className="text-medio">Aluno (a):</h1>
                                    <h1 className="text-medio">HENRIQUE VIEIRA MONTEIRO</h1>
                                </div>

                                <div className="box-texto-linha-2">
                                    <div className="box-texto-linha">
                                        <h1 className="text-medio">Identidade nº: </h1>
                                        <p className="text-medio">09609902-3</p>
                                    </div>
                                    <div className="box-texto-linha">
                                        <h1 className="text-medio">Órgãos emissores: </h1>
                                        <p className="text-medio"> IFP / RJ</p>
                                    </div>
                                </div>

                                <div className="box-texto-linha">
                                    <h1 className="text-medio">Filiação: </h1>
                                    <p className="text-medio">VALMIR RODRIGUES MONTEIRO E ALICE VIEIRA MONTEIRO</p>
                                </div>

                                <div className="box-texto-linha-2">
                                    <div className="box-texto-linha">
                                        <h1 className="text-medio">Nascido (a) em: </h1>
                                        <p className="text-medio">20/02/1974</p>
                                    </div>
                                    <div className="box-texto-linha">
                                        <h1 className="text-medio">Naturalidade: </h1>
                                        <p className="text-medio"> RIO DE JANEIRO</p>
                                    </div>
                                </div>
                            </section>

                            <section className="secao-2">
                                <div className="box-centralizador">
                                    <div className="box-titulo1-historico">
                                        <h1 className="texto-grande">CURSO ANTERIOR</h1>
                                    </div>
                                </div><br />

                                <div className="box-texto-linha">
                                    <h1 className="text-medio">ESTABELECIMENTO: </h1>
                                    <p className="text-medio">CIEP BRISOLÃO 336 OCTAVIO MALTA - CAMPO GRANDE - RJ</p>
                                </div>

                                <div className="box-texto-linha">
                                    <h1 className="text-medio">MODALIDADE: </h1>
                                    <p className="text-medio">Ensino Médio /2007</p>
                                </div>


                                <div className="box-texto-linha">
                                    <h1 className="text-medio">HABILITAÇÃO: </h1>
                                    <h1 className="text-medio">TÉCNICO EM ELETROTÉCNICO - EIXO CONTROLE E PROCESSOS INDUSTRIAIS</h1>
                                </div>
                            </section>

                            <div className="container-tabela">
                                <table className="tabela-historico-tec">
                                    <thead className="tabela-historico-tec-head">
                                        <tr className="tabela-historico-tr-head">

                                            <th className="tabela-historico-tec-th-head-1">
                                                Disciplinas
                                            </th>

                                            <th className="tabela-historico-tec-th-head-2">
                                                <p className="tabela-historico-paragrafo">
                                                    2ºS/2020
                                                </p>
                                                <p className="texto-medio">
                                                    1º PERIODO NOTA
                                                </p>
                                            </th>

                                            <th className="tabela-historico-tec-th-head-1">
                                                <p className="texto-medio">
                                                    CARGA HORARIA
                                                </p>
                                            </th>

                                            <th className="tabela-historico-tec-th-head-2">
                                                <p className="tabela-historico-paragrafo">
                                                    2ºS/2020
                                                </p>
                                                <p className="texto-medio">
                                                    1º PERIODO NOTA
                                                </p>
                                            </th>

                                            <th className="tabela-historico-tec-th-head-1">
                                                CARGA HORARIA
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="tabela-historico-tec-body">
                                        <tr className="tabela-historico-tec-tr-body">
                                            <td className="tabela-historico-tec-td-body-1">Projetos de instalções eletricas</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">100</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">50</td>
                                        </tr>

                                        <tr className="tabela-historico-tec-tr-body">
                                            <td className="tabela-historico-tec-td-body-1">Projetos de instalções eletricas</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">100</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">50</td>
                                        </tr>
                                        <tr className="tabela-historico-tec-tr-body">
                                            <td className="tabela-historico-tec-td-body-1">Projetos de instalções eletricas</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">100</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">50</td>
                                        </tr>

                                        <tr className="tabela-historico-tec-tr-body">
                                            <td className="tabela-historico-tec-td-body-1">Projetos de instalções eletricas</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">100</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">50</td>
                                        </tr>
                                        <tr className="tabela-historico-tec-tr-body">
                                            <td className="tabela-historico-tec-td-body-1">Projetos de instalções eletricas</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">100</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">50</td>
                                        </tr>
                                        <tr className="tabela-historico-tec-tr-body">
                                            <td className="tabela-historico-tec-td-body-1">Projetos de instalções eletricas</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">100</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">50</td>
                                        </tr>
                                        <tr className="tabela-historico-tec-tr-body">
                                            <td className="tabela-historico-tec-td-body-1">Projetos de instalções eletricas</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">100</td>
                                            <td className="tabela-historico-tec-td-body-2">8,0</td>
                                            <td className="tabela-historico-tec-td-body-2">50</td>
                                        </tr>


                                        <tr className="tabela-historico-tec-tr-body">
                                            <td className="tabela-historico-tec-td-body-3">ESTÁGIO SUPERVISIONADO</td>
                                            <td className="tabela-historico-tec-td-body-0"></td>
                                            <td className="tabela-historico-tec-td-body-0"></td>
                                            <td className="tabela-historico-tec-td-body-0"></td>
                                            <td className="tabela-historico-tec-td-body-4">480</td>
                                        </tr>


                                        <tr className="tabela-historico-tec-tr-body">
                                            <td className="tabela-historico-tec-td-body-3">Carga Horaria</td>
                                            <td className="tabela-historico-tec-td-body-0"></td>
                                            <td className="tabela-historico-tec-td-body-0"></td>
                                            <td className="tabela-historico-tec-td-body-0"></td>
                                            <td className="tabela-historico-tec-td-body-4">480</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div>
                                    <div>
                                        <h1>
                                            Observação:
                                        </h1>
                                        <h1>
                                            Média para aprovação = 6,0 (SEIS)
                                        </h1>
                                    </div>

                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="box-centralizador">
                                <h1>
                                    PERFIL PROFISSIONAL DE CONCLUSÃO
                                </h1>
                            </div><br />
                            <div className="box-centralizador">
                                <h2>
                                    COMPETENCIAS PROFISSIONAIS GERAIS DO TÉCNICO EM ELETROTÉNICA.
                                </h2>
                            </div>

                            <div className="corpo-texto">
                                <p>- Coordenar e desenvolver equipes de trabalho que atuam na instalação, na produção, aplicando métodos e técnicas de gestão administrativas e de pessoas.</p><br />
                                <p>- Aplicar normas de saúde e segurança no trabalho e de controle de qualidade no processo industiral.</p><br />
                                <p>- Aplicar métodos, processos e logística na produção, instalação e infraestrutura.</p><br />
                                <p>- Projetar desenhos, utilizando técnicas de representação gráfica com seus fundamentos matemáticos e geométricos</p><br />
                                <p>- Elaborar projetos "layouts", diagramas e esquemas correlacionando-os com as normas técnicas e com os princípios científicos e tecnológicos.</p><br />
                                <p>- Aplicar técnicas de medição e ensaios visando à melhoria da qualidade de produto e serviços da planta industiral.</p><br />
                                <p>- Avaliar as características e propriedades dos materiais e insumos correlacionando-as com seus fundamentos, matemáticos, físicos e químicos para a aplicação nos processos de controle de qualidade.</p>
                            </div>

                            <div className="box-centralizador">
                                <h2>
                                    COMPETÊNCIA ESPECIFICA DO TÉCNICO EM ELETROTÉCNICA.
                                </h2>
                            </div>

                            <div className="corpo-texto">
                                <p>- Conhecer equipamentos eletrônicos.</p><br/>
                                <p>- Especificar os componentes e materiais utilizados na automoção industrial.</p><br/>
                                <p>- Conhecer os diversos processos de automoção industrial.</p><br/>
                                <p>- Elaborar e desenvolver projetos em sistemas de automoção.</p><br/>
                                <p>- Elaborar e desenvolver projetos em sistemas digitais.</p><br/>
                                <p>- Conhecer os fundamentos da eletrônica digital.</p><br/>
                                <p>- Identificar falhas utilizadas no processo industrial.</p><br/>

                            </div>
                        </>
                    }
                </div>
            </div >
        )
    }
}