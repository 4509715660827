import Usuario from 'lirida-main-js-service/service/Usuario/Usuario';
import UsuarioService from 'lirida-main-js-service/service/Usuario/UsuarioService';
import React, { Component } from 'react';
import Util from '../../../util/Util';
import Container from '../sistema-components/Container/Container';
import Header from '../sistema-components/Header/Header';
import Sidebar from '../sistema-components/Sidebar/Sidebar';
import MsgOK from '../../../component/MsgOK/MsgOK';
import MsgError from '../../../component/MsgError/MsgError';
import '../sistema.css';
import Input from '../../../component/Input/Input';
import { Navigate } from 'react-router-dom';
import Pessoa from 'lirida-main-js-service/service/Pessoa/Pessoa';
import PessoaService from 'lirida-main-js-service/service/Pessoa/PessoaService';

export default class Perfil extends Component {
    util = new Util();
    usuarioService = new UsuarioService();
    pessoaService = new PessoaService();

    state = {
        pessoaUsuario: new Pessoa(),
        filtro: 'usuario',
        senhaAtual: '',
        novaSenha: '',
        confirmarNovaSenha: '',
        msgOKVisivel: false,
        msgErrorVisivel: false,
        mensagem: '',
        carregando: false,
        urlBaseArquivo: '',
        path: '',
    }

    async componentDidMount() {
        let pessoaUsuario = await this.util.buscarLocalStorage('PESSOA');
        let urlBaseArquivo = await this.util.buscarUrlBaseArquivo();
        console.log(pessoaUsuario);

        this.setState({
            urlBaseArquivo: urlBaseArquivo,
            pessoaUsuario: pessoaUsuario
        });
    }

    async selecionarArquivo(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({ path: reader.result.split(',')[1] });
                console.log(' ===> ' + reader.result);
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            console.log(this.arquivo);
        }
    }

    async salvar() {
        let pessoaUsuario = this.state.pessoaUsuario;
        pessoaUsuario.documento = '';
        let retornoPessoa = await this.pessoaService.salvar(pessoaUsuario);
        console.log(this.state.pessoaUsuario.foto);
        console.log(this.state.path.length);
        console.log(retornoPessoa)

        this.setState({
            carregando: true,
            msgOKVisivel: false,
            msgErrorVisivel: false,
            pessoaUsuario: retornoPessoa.objeto,
        })

        if (retornoPessoa.resultado === true) {
            this.util.salvarLocalStorage('PESSOA', retornoPessoa.objeto);
            this.setState({
                msgOKVisivel: true,
                mensagem: retornoPessoa.msg,
                carregando: false,
                pessoaUsuario: retornoPessoa.objeto,
            })
        }
    }

    async alterarSenha(pSubmit) {
        pSubmit.preventDefault();
        this.setState({
            carregando: true,
            msgOKVisivel: false,
            msgErrorVisivel: false
        });

        let pessoaUsuario = this.state.pessoaUsuario;
        console.log(pessoaUsuario)

        if (this.state.senhaAtual === pessoaUsuario.senha) {
            pessoaUsuario.senha = this.state.novaSenha;
            let retorno = await this.pessoaService.salvar(pessoaUsuario);
            console.log(retorno);
            this.setState({
                msgOKVisivel: true,
                mensagem: retorno.msg
            })
        } else {
            this.setState({
                msgErrorVisivel: true,
                mensagem: 'A senha atual digitada não é igual à sua senha de acesso'
            })
        }
    }

    render() {
        return (
            <Container>
                {!this.state.pessoaUsuario ? <Navigate to="/login" /> : null}
                <Header selecionado="perfil" />
                <div className='sistema-wrapper-lista'>

                    <Sidebar bgi="bgi-perfil">
                        {this.state.filtro === 'usuario' ?
                            <button className="btn-lg btn-com-fundo">Usuário</button> :
                            <button
                                className="btn-lg btn-secundario"
                                onClick={() => this.setState({
                                    filtro: 'usuario',
                                    msgOKVisivel: false,
                                    msgErrorVisivel: false,
                                })}>Usuário</button>
                        }
                        {this.state.filtro === 'alterar senha' ?
                            <button className="btn-lg btn-com-fundo">Alterar senha</button> :
                            <button
                                className="btn-lg btn-secundario"
                                onClick={() => this.setState({
                                    filtro: 'alterar senha',
                                    msgOKVisivel: false,
                                    msgErrorVisivel: false,
                                })}>Alterar senha</button>
                        }
                    </Sidebar>
                    <div className='sistema-conteudo-wrapper'>
                        {this.state.filtro === 'usuario' ?
                            <section className='sistema-perfil-wrapper'>
                                <MsgError
                                    msg={this.state.mensagem}
                                    visivel={this.state.msgErrorVisivel} />
                                <MsgOK
                                    msg={this.state.mensagem}
                                    visivel={this.state.msgOKVisivel} />
                                
                                <Input
                                    titulo="Nome"
                                    value={this.state.pessoaUsuario.nome}
                                    onChange={pTexto => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            nome: pTexto.target.value
                                        }
                                    })}
                                />

                                <div>
                                    <h1>Email</h1>
                                    <p>{this.state.pessoaUsuario.emailPlataformaLirida}</p>
                                </div>

                                <button
                                    type="button"
                                    onClick={() =>
                                        this.salvar()}
                                    className='btn btn-com-fundo'
                                >Salvar</button>

                            </section>
                            :
                            this.state.filtro === 'alterar senha' ?
                                <form
                                    onSubmit={pSubmit => this.alterarSenha(pSubmit)}
                                    className='sistema-perfil-wrapper'>

                                    <MsgError
                                        msg={this.state.mensagem}
                                        visivel={this.state.msgErrorVisivel} />
                                    <MsgOK
                                        msg={this.state.mensagem}
                                        visivel={this.state.msgOKVisivel} />

                                    <Input
                                        titulo="Senha atual"
                                        type="password"
                                        onChange={pTexto => this.setState({ senhaAtual: pTexto.target.value })}
                                        value={this.state.senhaAtual}
                                        required
                                    />
                                    <Input
                                        titulo="Nova senha"
                                        type="password"
                                        onChange={pTexto => this.setState({ novaSenha: pTexto.target.value })}
                                        value={this.state.novaSenha}
                                        required
                                    />
                                    <Input
                                        titulo="Confirmar nova senha"
                                        type="password"
                                        onChange={pTexto => this.setState({ confirmarNovaSenha: pTexto.target.value })}
                                        value={this.state.confirmarNovaSenha}
                                        required
                                    />
                                    <input
                                        value="Alterar"
                                        type="submit"
                                        className='btn btn-com-fundo'
                                    />
                                </form>
                                :
                                null}
                    </div>
                </div>
            </Container>
        )
    }
}