import React, { Component } from 'react';
import './input.css';

export default class Input extends Component {
    render() {
        return (
            <div>
                {this.props.titulo ? <><label>{this.props.titulo}</label><br /></> : null}
                <input
                    accept={this.props.accept}
                    style={this.props.style}
                    ref={this.props.ref}
                    type={this.props.type}
                    className={this.props.tam === 'grande' ? 'input-lg' : null}
                    onChange={this.props.onChange}
                    value={this?.props?.value}
                    required={this.props.required}
                    list={this.props.list}
                    autoComplete={this.props.autoComplete}
                />
            </div>
        )
    }
}