import React, { Component } from 'react';
import Container from '../sistema/sistema-components/Container/Container';
import './checkoutstyles.css'
import Input from '../../component/Input/Input';
import SecaoCheckout from '../../component/SecaoCheckout/SecaoCheckout';
import Pessoa from 'lirida-main-js-service/service/Pessoa/Pessoa';
import Contato from 'lirida-main-js-service/service/Contato/Contato';
import PessoaService from 'lirida-main-js-service/service/Pessoa/PessoaService';
import Constante from '../../util/Constante';
import Endereco from 'lirida-main-js-service/service/Endereco/Endereco';
import ContainerInput from '../../component/SecaoCheckout/ContainerInputs';
import Util from '../../util/Util';
import GoogleAPI from 'lirida-main-js-service/service/Google/GoogleAPI';
import TabelaPrecoService from 'lirida-main-js-service/service/TabelaPreco/TabelaPrecoService';
import TabelaPreco from 'lirida-main-js-service/service/TabelaPreco/TabelaPreco';
import DocumentoItem from 'lirida-main-js-service/service/Documento/DocumentoItem';
import Documento from 'lirida-main-js-service/service/Documento/Documento';
import DocumentoService from 'lirida-main-js-service/service/Documento/DocumentoService';
import { FaPen } from 'react-icons/fa';
import logo from '../../assets/image/logo.png';




export default class Checkout extends Component {
    util = new Util();
    constante = new Constante();
    pessoaService = new PessoaService();
    tabelaPrecoService = new TabelaPrecoService();
    documentoService = new DocumentoService();
    googleAPI = new GoogleAPI();


    state = {
        pessoa: new Pessoa(),
        contatoWhatsApp: new Contato(),
        contatoCelular: new Contato(),
        contatoEmail: new Contato(),
        endereco: new Endereco(),
        documento: new Documento(),
        documentoItem: new DocumentoItem(),
        listaDocumentoItem: [],
        quantidade: 1,
        tabelaPreco: new TabelaPreco(),
        listaTabelaPreco: [],
        listaEnderecoGoogle: [],
        tela: 'equipamento'
    }


    async componentDidMount() {
        await this.iniciar();
    }


    async iniciar() {
        let retornoTabelaPreco = await this.tabelaPrecoService.listarTabelaPreco(
            this.constante.seqTabelaPreco
        )
        this.setState({
            listaTabelaPreco: retornoTabelaPreco.objeto
        })
    }

    async salvarDocumento() {
        let documento = this.state.documento;
        documento.seqUsuario = this.constante.seqUsuario;
        documento.seqTipoDocumento = this.constante.seqTipoDocumento;

        console.log(documento);

        let retorno = await this.documentoService.salvar(documento);
        console.log(retorno);

        documento = retorno.objeto

        this.setState({
            mensagemVisivel: true,
            mensagem: "Informações salvas com sucesso!",
            documento: documento
        });
    }

    async salvar(pSubmit) {
        pSubmit.preventDefault()
        let pessoa = this.state.pessoa;
        console.log("=======>>>>>");
        // validar documento        
        let retornoValidarDocumento = await this.util.validarDocumento(pessoa.documento)
        if (retornoValidarDocumento != true) {
            return
        }


        pessoa.seqUsuario = this.constante.seqUsuario
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfil
        let retornoPessoa = await this.pessoaService.salvar(pessoa);
        console.log(retornoPessoa);
        pessoa = retornoPessoa.objeto;
        this.setState({ pessoa: pessoa });
        console.log('=====> state' + this.state.pessoa.seqPessoa);
        console.log('=====> obj' + pessoa.seqPessoa);

        //Salvar celular
        let contatoCelular = this.state.contatoCelular;
        contatoCelular.seqPessoa = pessoa.seqPessoa;
        contatoCelular.seqTipoContato = this.constante.seqTipoContatoCelular;
        let retornoContatoCelular = await this.pessoaService.salvarContato(contatoCelular);

        // Salvar WhatsApp
        let contatoWhatsApp = this.state.contatoWhatsApp;
        contatoWhatsApp.seqPessoa = pessoa.seqPessoa;
        contatoWhatsApp.seqTipoContato = this.constante.seqTipoContatoWhatsApp;
        if (contatoWhatsApp.contato != undefined) {
            contatoWhatsApp = await this.pessoaService.salvarContato(contatoWhatsApp);
        }

        // Salvar email
        let contatoEmail = this.state.contatoEmail;
        contatoEmail.seqPessoa = pessoa.seqPessoa;
        contatoEmail.seqTipoContato = this.constante.seqTipoContatoEmail;
        if (contatoEmail.contato != undefined) {
            contatoEmail = await this.pessoaService.salvarContato(contatoEmail);
        }


        this.salvarEndereco()



        // Salvar documento
        let documento = this.state.documento;
        documento.seqUsuario = this.constante.seqUsuario;
        documento.seqUsuarioVendedor = this.constante.seqUsuarioVendedor;
        documento.seqTipoDocumento = this.constante.seqTipoDocumento;
        documento.seqPessoa = pessoa.seqPessoa;
        let retornoDocumento = await this.documentoService.salvar(documento);
        documento = retornoDocumento.objeto;


        // Salvar Item
        console.log("===> " + this.state.listaDocumentoItem.length);
        console.log("===> " + this.state.listaDocumentoItem.length);
        console.log(this.state.listaDocumentoItem);

        for (let i = 0; i < this.state.listaDocumentoItem.length; i++) {
            let documentoItem = new DocumentoItem();
            documentoItem = this.state.listaDocumentoItem[i];
            documentoItem.seqPessoa = pessoa.seqPessoa;
            documentoItem.seqDocumento = documento.seqDocumento;
            await this.documentoService.salvarItem(documentoItem);
        }




    }

    async salvarEndereco() {
        // pSubmit.preventDefault()
        let pessoa = this.state.pessoa;

        let endereco = this.state.endereco;
        endereco.seqPessoa = pessoa.seqPessoa;
        endereco.seqUsuario = this.constante.seqUsuario;
        console.log("=============>>>>>>");
        console.log(endereco);

        let retornoEndereco = await this.pessoaService.salvarEndereco(endereco);

        console.log(retornoEndereco);

        endereco = retornoEndereco.objeto;
        console.log(endereco);
    }

    buscarTabelaPrecoItem(pItem) {
        console.log(pItem)
        this.listaTabelaPreco = this.state.listaTabelaPreco;
        console.log(this.listaTabelaPreco)
        for (let i = 0; i < this.listaTabelaPreco.length; i++) {
            if (this.listaTabelaPreco[i].seqTabelaPrecoItem === Number(pItem)) {
                this.setState({
                    tabelaPreco: this.listaTabelaPreco[i],
                })
                console.log(this.listaTabelaPreco[i]);
            }
        }
    }

    async salvarItem() {
        console.log(this.state.documento)
        let documentoItem = new DocumentoItem();

        if (this.state.documentoItem.seqDocumento) {
            documentoItem = this.state.documentoItem;
        }

        if (this.state.tabelaPreco) {
            documentoItem.seqItem = this.state.tabelaPreco.seqItem;
        }

        documentoItem.quantidade = this.state.quantidade;
        documentoItem.seqDocumento = this.state.documento.seqDocumento;
        documentoItem.seqUsuario = this.constante.seqUsuario;

        console.log(documentoItem)

        // let retorno = await this.documentoService.salvarItem(documentoItem);
        // console.log(retorno);

        // this.listarDocumentoItem();



        this.setState({
            documentoItem: new DocumentoItem(),
            tabelaPreco: new TabelaPreco(),
            quantidade: 1,
            carregando: false,
            listaDocumentoItem: [...this.state.listaDocumentoItem, documentoItem]
        });
    }

    async listarDocumentoItem(pDocumento) {
        let documento;

        if (pDocumento) {
            documento = pDocumento;
        } else {
            documento = this.state.documento;
        }

        let retornoDocumentoItem = await this.documentoService.listarDocumentoItem(
            documento
        );
        this.setState({ listaDocumentoItem: retornoDocumentoItem.objeto });
        console.log(retornoDocumentoItem);
    }



    async listarEnderecoGoogle(pTexto) {
        this.retorno = await this.googleAPI.listarEndereco(pTexto);
        console.log(this.retorno.predictions)
        this.setState({
            listaEnderecoGoogle: this.retorno.predictions
        })
        if (this.retorno.predictions.length === 1 && pTexto === this.retorno.predictions[0].description) {
            this.buscarEnderecoGoogle(this.retorno.predictions[0].place_id)
        }
    }

    async buscarEnderecoGoogle(pPlaceID) {
        console.log(pPlaceID)
        this.retorno = await this.googleAPI.buscarEndereco(pPlaceID);

        console.log(this.retorno.result.address_components[2].long_name)

        this.setState({
            endereco: {
                ...this.state.endereco,
                latitude: this.retorno.result.geometry.location.lat,
                longitude: this.retorno.result.geometry.location.lng,
                logradouro: this.retorno.result.address_components[1].long_name,
                bairro: this.retorno.result.address_components[2].long_name,
                numero: this.retorno.result.address_components[0].long_name,
                cidade: this.retorno.result.address_components[3].long_name,
                uf: this.retorno.result.address_components[4].short_name,
            }
        })

    }

    render() {
        return (
            <div className="container-principal-tela-checkout">
                <div className='div-imagem-checkout'>
                    <img src={logo} alt="onework logo" className='sistema-header-logo-principal' style={{ width: '100%', height: '100%' }} />
                    {/* <h1 style={{ fontSize: 35 }}>Digitação rápida de OS</h1> */}
                </div>
                <div className='container-form-checkout'>

                    {this.state.tela == 'equipamento' &&
                        <>
                            <div className='titulo-container-checkout'>
                                <h1>
                                    Equipamentos
                                </h1>
                            </div>

                            <div className='container-principal-equipamentos'>
                                <span className='container-secundario-equipamentos'>
                                    <button className='equipamentos-lista'>
                                    </button>

                                    <button className='equipamentos-lista'>
                                    </button>

                                    <button className='equipamentos-lista'>
                                    </button>
                                </span>
                            </div>

                            <div className='secao-form-botao-container' style={{ marginBottom: 10 }}>
                                <button className='btn btn-com-fundo' onClick={() => this.setState({ tela: 'tipoDeServico' })}>Salvar</button>
                            </div>
                        </>
                    }


                    {this.state.tela == 'tipoDeServico' &&
                        <>
                            <div className='titulo-container-checkout'>
                                <button
                                    onClick={() => this.setState({ tela: 'equipamento' })}>
                                    VOLTAR
                                </button>

                                <h1>
                                    Escolha o tipo de serviço
                                </h1>
                            </div>

                            <div className='container-principal-tipoServico'>
                                <span className='container-secundario-equipamentos'>
                                    <button className='btn-instalacao'>
                                        <h1>Intalação</h1>
                                    </button>

                                    <button className='btn-manutencao'>
                                        <h1>Manuntenção</h1>
                                    </button>
                                </span>
                            </div>

                            <div className='secao-form-botao-container' style={{ marginBottom: 10 }}>
                                <button className='btn btn-com-fundo' onClick={() => this.setState({ tela: 'listaEquipamento' })}>Salvar</button>
                            </div>
                        </>

                    }


                    {this.state.tela == 'listaEquipamento' &&
                        <>
                            <div className='titulo-container-checkout'>
                                <button
                                    onClick={() => this.setState({ tela: 'tipoDeServico' })}>
                                    VOLTAR
                                </button>

                                <h1>
                                    Escolha o modelo
                                </h1>
                            </div>

                            <div className='container-principal-lista-equipamento'>
                                <div className='div-img'>
                                    <img style={{ width: '100%', height: '100%' }} src='https://instalacaoexpressupload.s3-sa-east-1.amazonaws.com/1623840272314611529' />
                                </div>


                                <div className='div-menu-selecao-aparelho'>
                                    <label>Categoria do aparelho</label><br />
                                    <select
                                        style={{ width: '100%' }}
                                        value={this.state.documentoItem.seqItem}
                                        onChange={pEvento => this.buscarTabelaPrecoItem(pEvento.target.value)}
                                        className='input'>

                                        <option selected={this.state.documentoItem.seqItem === undefined ?
                                            true : false} value={null}>Selecione um item</option>

                                        {this.state.listaTabelaPreco.map((item, index) => (
                                            <option
                                                key={index}
                                                selected={
                                                    this.state.documentoItem.seqItem === item.seqTabelaPrecoItem ?
                                                        true : false
                                                }
                                                value={item.seqTabelaPrecoItem}>{item.itemNomeVitrine}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>

                            <div className='secao-form-botao-container' style={{ marginBottom: 10 }}>
                                <button className='btn btn-com-fundo' onClick={() => this.setState({ tela: 'fabricante' })}>Salvar</button>
                            </div>

                        </>


                    }




                    {this.state.tela == 'fabricante' &&
                        <>
                            <div className='titulo-container-checkout'>
                                <button
                                    onClick={() => this.setState({ tela: 'equipamento' })}>
                                    VOLTAR
                                </button>

                                <h1>
                                    Escolha o Fabricante
                                </h1>
                            </div>

                            <div className='container-resumo-tela-fabricante'>
                                <div className='div-img-item-tela-fabricante'>
                                    <img style={{ width: '100%', height: '100%' }} src='https://instalacaoexpressupload.s3-sa-east-1.amazonaws.com/1623840272314611529' />
                                </div>
                                <h1>TV - 32 polegadas</h1>
                            </div>

                            <div className="container-fabricantes-tela-fabricante">
                                <button className="btn-fabricantes-tela-fabricante">
                                    <div className='div-bt-image-fabricante-tela-fabricante'>
                                        <img style={{ width: '100%', height: '100%' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRW-OhUvDqxCaprIDUrijJxOfOxwYArfQysNA&usqp=CAU' />
                                    </div>
                                </button>
                                <button className="btn-fabricantes-tela-fabricante">
                                    <div className='div-bt-image-fabricante-tela-fabricante'>
                                        <img style={{ width: '100%', height: '100%' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRW-OhUvDqxCaprIDUrijJxOfOxwYArfQysNA&usqp=CAU' />
                                    </div>
                                </button>
                                <button className="btn-fabricantes-tela-fabricante">
                                    <div className='div-bt-image-fabricante-tela-fabricante'>
                                        <img style={{ width: '100%', height: '100%' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRW-OhUvDqxCaprIDUrijJxOfOxwYArfQysNA&usqp=CAU' />
                                    </div>
                                </button>
                            </div>
                            <div className='secao-form-botao-container' style={{ marginBottom: 10 }}>
                                <button className='btn btn-com-fundo' onClick={() => this.setState({ tela: 'dadosPessoais' })}>Salvar</button>
                            </div>
                        </>
                    }


                    {this.state.tela == 'dadosPessoais' &&
                        <form onSubmit={(pSubmit) => this.salvar(pSubmit)} >
                            {/* DADOS CL1IENTE */}
                            <div className='container-secao-form-checkout'>
                                <h1>Dados cliente:</h1>
                                <div className='secao-chekout-container-input'>
                                    <Input
                                        style={{ width: 300 }}
                                        titulo='Nome completo'
                                        type='text'
                                        onChange={(pTexto) => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa, nome: pTexto.target.value
                                            }
                                        })}
                                    />

                                    <Input
                                        style={{ width: 300 }}
                                        required
                                        titulo='CPF/CNPJ'
                                        type='text'
                                        onChange={(pTexto) => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa, documento: pTexto.target.value
                                            }
                                        })}
                                    />

                                    <Input
                                        style={{ width: 300 }}
                                        required
                                        titulo='Telefone celular'
                                        type='tel'
                                        onChange={(pTexto) => this.setState({
                                            contatoCelular: {
                                                ...this.state.contatoCelular, contato: pTexto.target.value
                                            }
                                        })}
                                    />

                                    <Input
                                        style={{ width: 300 }}
                                        titulo='Telefone whatsApp'
                                        type='tel'
                                        onChange={(pTexto) => this.setState({
                                            contatoWhatsApp: {
                                                ...this.state.contatoWhatsApp, contato: pTexto.target.value
                                            }
                                        })}
                                    />

                                    <Input
                                        style={{ width: 300 }}
                                        titulo='Email'
                                        type='email'
                                        onChange={(pTexto) => this.setState({
                                            contatoEmail: {
                                                ...this.state.contatoEmail, contato: pTexto.target.value
                                            }
                                        })}
                                    />
                                    <div className="div-para-alinhar"></div>
                                </div>
                            </div>


                            {/* ENDEREÇO */}
                            <div className='container-secao-form-checkout'>
                                <h1>Local de serviço</h1>
                                <div style={{ width: '100%' }}>
                                    <Input
                                        style={{ width: '100%' }}
                                        titulo="Pesquisa de endereço digitando"
                                        onChange={pTexto => this.listarEnderecoGoogle(pTexto.target.value)}
                                        list="listagoogle"
                                    />
                                    <datalist id="listagoogle">
                                        {this.state.listaEnderecoGoogle.map(item => <option value={item.description}>{item.description}</option>)}
                                    </datalist>

                                    <Input
                                        style={{ width: '100%' }}
                                        required
                                        titulo='Complemento'
                                        type='text'
                                        onChange={(pTexto) => this.setState({
                                            endereco: {
                                                ...this.state.endereco, complemento: pTexto.target.value
                                            }
                                        })}
                                        value={this.state.endereco.complemento}
                                    />
                                </div>
                            </div>



                            {/* <div className='container-secao-form-checkout'>
                                <h1>Dados Serviçocs</h1>
                                <div className='secao-form-inputs-checkout'>

                                    <div>
                                        <label>Item</label><br />
                                        <select
                                            style={{ width: '100%' }}
                                            value={this.state.documentoItem.seqItem}
                                            onChange={pEvento => this.buscarTabelaPrecoItem(pEvento.target.value)}
                                            className='input'>

                                            <option selected={this.state.documentoItem.seqItem === undefined ?
                                                true : false} value={null}>Selecione um item</option>

                                            {this.state.listaTabelaPreco.map((item, index) => (
                                                <option
                                                    key={index}
                                                    selected={
                                                        this.state.documentoItem.seqItem === item.seqTabelaPrecoItem ?
                                                            true : false
                                                    }
                                                    value={item.seqTabelaPrecoItem}>{item.itemNomeVitrine}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <Input
                                        style={{ width: 300 }}
                                        titulo="Quantidade"
                                        type="number"
                                        onChange={pTexto => this.setState({ quantidade: pTexto.target.value })}
                                        value={this.state.quantidade}
                                    />

                                    <div className='secao-form-botao-container'>
                                        <button type="button" className='btn btn-com-fundo'
                                            onClick={() => this.salvarItem()}>Adicionar</button>
                                    </div>


                                    <div className='tabela-checkout'>
                                        <table className="sistema-table">
                                            <thead className="sistema-thead">
                                                <tr className="sistema-tr-head">
                                                    <th>Id</th>
                                                    <th>Nome</th>
                                                    <th>Email</th>
                                                    <th>Opção</th>
                                                </tr>
                                            </thead>
                                            <tbody className="sistema-tbody" style={{ maxHeight: 535 }}>
                                                {this.state.listaDocumentoItem.map((item, index) => (
                                                    <tr key={index} className="sistema-tr-body">
                                                        <td>{item.itemNomeVitrine}</td>
                                                        <td>{item.quantidade}</td>
                                                        <td>{item.valorUnitario}</td>
                                                        <td>{item.valorTotal}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => this.selecionarDocumentoItem(item)}
                                                                className="btn btn-icone btn-com-fundo"
                                                            ><FaPen /></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div> */}
                            <div className='secao-form-botao-container' style={{ marginBottom: 10 }}>
                                <button className='btn btn-com-fundo'
                                    type='submit'>Salvar</button>
                            </div>

                        </form>
                    }

                </div>
            </div >
        )
    }
}