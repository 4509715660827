import Util_api from 'lirida-main-js-service/service/Util/Util';

export default class Util {
  util_api = new Util_api();

  async buscarUrlBaseArquivo() {
    let retorno = await this.util_api.buscarUrlBaseArquivo();
    retorno = await retorno.json();
    return retorno;
  }

  async buscarLocalStorage(pCampo) {
    let retorno = localStorage.getItem(pCampo);
    retorno = await JSON.parse(retorno);
    console.log(retorno);
    return retorno;
  }

  salvarLocalStorage(pCampo, pValor) {
    let valor = JSON.stringify(pValor);
    localStorage.setItem(pCampo, valor);
    console.log(pCampo + ' salvo localstorage com sucesso')
  }

  async removerLocalStorage(pCampo) {
    let retorno = localStorage.removeItem(pCampo);
    return retorno;
  }


  formatarTelefone(value) {
    let r = value.replace(/\D/g, '');
    r = r.replace(/^0/, '');

    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (value.trim() !== '') {
      r = r.replace(/^(\d*)/, '($1');
    }

    return r;
  }


  formatarCPFCNPJ(pTexto) {
    let t = pTexto;

    if (t.length > 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }

    if (t.length <= 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    }
  }

  formatarCPF(pTexto) {
    let t = pTexto;
    return t.replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  }

  formatarCNPJ(pTexto) {
    let t = pTexto;

    if (t.length > 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }

  }

  // formatarRG(pTexto) {
  //   let t = pTexto
  //   return t.replace(/\D/g,'')
  //   .replace(/(\d{2})(\d)/, '$1.$2')
  //   .replace(/(\d{3})(\d)/, '$1.$2')
  //   .replace(/(\d{3})(\d)/, '$1-$2')
  //   .replace(/(-\d{1})\d+?$/, '$1') 
  // }

  formatarParaSenha(pTexto) {
    let t = pTexto;

    if (t.length > 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1$2')
        .replace(/(\d{3})(\d)/, '$1$2')
        .replace(/(\d{3})(\d{1,2})/, '$1$2')
        .replace(/(\d{4})(\d{1,2})/, '$1$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }

    if (t.length <= 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1$2')
        .replace(/(\d{3})(\d)/, '$1$2')
        .replace(/(\d{3})(\d{1,2})/, '$1$2')
    }
  }

  async buscarCep(pCep) {
    let retorno = await fetch('https://viacep.com.br/ws/' + pCep + '/json')
    retorno = await retorno.json();

    return retorno;
  }

  async validarDocumento(pTipoDocumento, pDocumento) {
    this.retorno = await this.util_api.validarDocumento(pTipoDocumento, pDocumento);
    this.retorno = await this.retorno.json();
    return this.retorno;
  }

  buscarParametrosUrl() {
    if (!window.location.search) {
      return null;
    }

    let query = window.location.search.slice(1);
    let partes = query.split("&");
    let data = {};
    partes.forEach(function (parte) {
      let chaveValor = parte.split("=");
      let chave = chaveValor[0];
      let valor = chaveValor[1];
      data[chave] = valor;
    });

    return data;
  }
}
