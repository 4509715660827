import React, { Component } from "react";

export default class VersoCertificadoEm extends Component {
    render() {
        return (
            <div className="ctd-container-geral">
                <div className="container-grid-verso1-em">

                    <div className="container-area1">

                        <div className="sub-container-area1-1">

                            <div className="sub-container-area1-1-conteudo-1">
                                <h1 className="texto-medio">CURSO ANTERIOR ENSINO FUNDAMENTAL</h1>
                            </div>

                            <div className="sub-container-area1-1-conteudo-2">
                                <h1 className="texto-medio"> ANO DE CONCLUSÃO</h1>

                            </div>

                            <div className="sub-container-area1-1-conteudo-3">
                                <h1 className="texto-medio"> INSTITUIÇÃO DE ENSINO</h1>

                            </div>

                            <div className="sub-container-area1-1-conteudo-4">
                                <h1 className="texto-medio">LOCALIDADE</h1>

                            </div>

                            <div className="sub-container-area1-1-conteudo-5">
                                <p className="texto-medio">2012</p>
                            </div>

                            <div className="sub-container-area1-1-conteudo-6">
                                <p className="texto-medio"> C.M PRESIDENTE CASTELO BRANCO</p>

                            </div>

                            <div className="sub-container-area1-1-conteudo-7">
                                <p className="texto-medio">MESQUITA - RJ</p>

                            </div>
                        </div>

                        <div className="sub-container-area1-2">

                            <div className="sub-container-area1-2-conteudo-1">
                                <h1 className="texto-medio">CURSO ANTERIOR ENSINO MÉDIO</h1>
                            </div>

                            <div className="sub-container-area1-2-conteudo-2">
                                <h1 className="texto-medio"> ANO DE CONCLUSÃO</h1>

                            </div>

                            <div className="sub-container-area1-2-conteudo-3">
                                <h1 className="texto-medio"> INSTITUIÇÃO DE ENSINO</h1>
                            </div>

                            <div className="sub-container-area1-2-conteudo-4">
                                <h1 className="texto-medio">LOCALIDADE</h1>

                            </div>

                            <div className="sub-container-area1-2-conteudo-5">
                                <p className="texto-medio">1ª SÉRIE: 2019</p><br />
                                <p className="texto-medio">2ª SÉRIE: 2020</p><br />
                                <p className="texto-medio">3ª SÉRIE: 2020</p>
                            </div>

                            <div className="sub-container-area1-2-conteudo-6">
                                <p className="texto-medio"> COLÉGIO EL CURSOS SHADAY</p><br />
                                <p className="texto-medio"> COLÉGIO EL CURSOS SHADAY</p><br />
                                <p className="texto-medio"> COLÉGIO EL CURSOS SHADAY</p>
                            </div>

                            <div className="sub-container-area1-2-conteudo-7">
                                <p className="texto-medio">DUQUE DE CAXIAS - RJ</p><br />
                                <p className="texto-medio">DUQUE DE CAXIAS - RJ</p><br />
                                <p className="texto-medio">DUQUE DE CAXIAS - RJ</p>
                            </div>
                        </div>
                    </div >

                    <div className="container-area2">
                        <div className="box-area2-documento">
                            <p className="texto-grande">
                                O presente documento foi registrstrado sob o N _______ em folhas  _______  do livro N  _______  deste Estabelecimento de Ensino.
                            </p>
                        </div>

                        <div className="box-area2-censo-escolar">
                            <h1 className="texto-medio">
                                Censo Escolar N: 33188289
                            </h1>
                        </div>
                    </div>

                    <div className="container-area3">
                        <h1 className="texto-grande">
                            CURSO DE ENSINO MEDIO
                        </h1>
                    </div>

                    <div className="container-area4">

                        <div className="sub-container-area4-1">
                            <div className="conteudo-header-area4">
                                <div className="box-conteudo-header">
                                    <h1 className="conteudo-box-header1">COMPONENTES CURRICULARES</h1>
                                    <h1 className="conteudo-box-header2">SITUACAO</h1>
                                </div>
                            </div>

                            <div className="conteudo-conteudo-area4">
                                <div className="box-conteudo-conteudo">
                                    <h1 className="conteudo-box-conteudo1">Lingua Portuguesa</h1>
                                    <h1 className="conteudo-box-conteudo2">APROVADO</h1>
                                </div>
                                <div className="box-conteudo-conteudo">
                                    <h1 className="conteudo-box-conteudo1">Matematica</h1>
                                    <h1 className="conteudo-box-conteudo2">APROVADO</h1>
                                </div>
                                <div className="box-conteudo-conteudo">
                                    <h1 className="conteudo-box-conteudo1">Matematica</h1>
                                    <h1 className="conteudo-box-conteudo2">APROVADO</h1>
                                </div>
                                <div className="box-conteudo-conteudo">
                                    <h1 className="conteudo-box-conteudo1">Matematica</h1>
                                    <h1 className="conteudo-box-conteudo2">APROVADO</h1>
                                </div>
                                <div className="box-conteudo-conteudo">
                                    <h1 className="conteudo-box-conteudo1">Matematica</h1>
                                    <h1 className="conteudo-box-conteudo2">APROVADO</h1>
                                </div>
                            </div>
                        </div>



                        <div className="sub-container-area4-2">
                            <div className="container-conteudo-area4-1">
                                <p className="texto-medio">
                                    COLEGIO EL CURSOS SHADAY
                                </p>
                                <p className="texto-medio">
                                    ENTIDADE MANTEDORA: EL CURSOS SHADAY - LTDA
                                </p>
                            </div>

                            <div className="container-conteudo-area4-2">
                                <p className="texto-medio">
                                    Estabelecimento de ensino
                                </p>
                            </div>

                            <div className="container-conteudo-area4-3">
                                <p className="texto-medio">
                                    ANO DE CONLUSÃO: <strong className="texto-medio">DEZ./2021</strong>
                                </p>
                                <p className="texto-medio">
                                    DUQUE DE CAXIAS - RJ
                                </p>
                            </div>

                            <div className="container-conteudo-area4-4">
                                <p className="texto-medio">
                                    Localidade e unidade da ferederação
                                </p>
                            </div>

                            <di className="container-conteudo-area4-5">
                                <h1 className="texto-medio">
                                    Observações:
                                </h1>
                                <strong className="texto-medio">Documento emitido conforme previsto na Delbieracao CEE N 357, de 26 de julho de 2016.</strong>
                                <strong className="texto-medio">Homologada pela Portaria CEE N 3.514 de 27.07.2017 Publicada em D.O em 15.05.2016</strong>
                                <strong className="texto-medio">Paginas 10 e 11</strong>
                            </di>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}