import React, { Component } from "react";
import Header from "../Header/Header";
import "./AgenciamentoDeServiços.css";

export default class AgenciamentoDeServiços extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="agenciamento-de-serviços-container">
          <div className="agenciamento-de-serviços-wrapper">
            <div className="agenciamento-de-serviços-titulos">
              <h1 className="h1-agenciamento-de-serviços"> OneWork </h1>
              <h2 className="h2-agenciamento-de-serviços">
                {" "}
                CONTRATO DE AGENCIAMENTO DE SERVIÇOS
              </h2>
            </div>
            <div className="agenciamento-de-serviços">
              <p>
                Por meio deste instrumento são estabelecidos os termos e as
                condições gerais para a contratação da ONEWORK SOLUÇÕES 
                INTELIGENTES  (denominada simplesmente “ONEWORK”) pelo
                “PRESTADOR DE SERVIÇO”, sendo a adesão integral por parte deste
                uma das condições essenciais para a prestação dos serviços aqui
                descritos pela ONEWORK.
              </p>
            </div>
            <div className="definiçoes">
              <h2 className="center">1. Definições</h2>
              <p>
                {" "}
                1.1. Os termos e expressões utilizados neste contrato, quando em
                maiúsculo, significarão exatamente o descrito abaixo:
              </p>
              <br />
              <p>
                {" "}
                I. Usuário: Pessoa física ou jurídica que, após o seu cadastro,
                utiliza o App Onework para interagir com Prestadores de Serviço
                e contratar os Jobs destes, conforme disponibilidade.
              </p>
              <br />
              <p>
                {" "}
                II. Prestador de Serviço: Pessoa física ou jurídica que celebra
                este Contrato de Agenciamento com a Onework, a fim de ser
                devidamente cadastrada como Prestador de Serviço no App e
                habilitada para divulgação e oferecimento de seus Jobs.
              </p>
              <br />
              <p>
                {" "}
                III. Perfil: conta individual do Prestador de Serviço no App
                Onework, contendo seu nome, marca, endereços, telefones e demais
                formas de contato, bem como rol de Jobs para contratação.
              </p>
              <br />
              <p>
                {" "}
                IV. Login: Identificação de e-mail ou telefone do Usuário e/ou
                do Prestador de Serviço para acesso ao App.
              </p>
              <br />
              <p>
                {" "}
                V. App ou App Onework: App desenvolvido pela Onework para
                disponibilização dos Serviços Ofertados.
              </p>
              <br />
              <p>
                {" "}
                VI. Serviços Ofertados: Serviços de intermediação
                disponibilizados pela Onework aos Prestadores de Serviços,
                descritos com maior detalhe na cláusula 2 abaixo.
              </p>
              <br />
              <p>
                {" "}
                VII. Jobs: São os serviços disponibilizados pelos Prestadores de
                Serviço cadastrados no App aos Usuários, podendo incluir:
                gerência de festas, serviços de limpeza, fotografia, etc.
              </p>
              <br />
              <p>
                {" "}
                VIII. Onework: ONEWORK SOLUÇÕES INTELIGENTES, pessoa
                jurídica de direito privado, inscrita no CNPJ/ME sob nº
                45.669.894/0001-45, com sede à AV Eusebio de Queiroz, nº1450 no bairro Tamatanduba , responsável
                pela disponibilização do App e prestação dos Serviços Ofertados.
              </p>
              <br />
              <p>
                {" "}
                IX. Contrato: O presente Contrato celebrado entre a Onework e o
                Prestador de Serviço para a prestação dos serviços de
                agenciamento, incluindo todos os seus anexos, políticas e termos
                adicionais, por meio do qual o Prestador de Serviço manifesta a
                sua expressa concordância, sem quaisquer reservas ou ressalvas,
                a todos os termos e condições descritos.
              </p>
              <br />

              <p>
                {" "}
                X. Senha: Conjunto de caracteres sigilosos intransferíveis do
                Usuário, utilizado em conjunto com o Login para a validação de
                acesso ao App.
              </p>
              <br />
              <p>
                {" "}
                XI. Partes: Onework e Prestador de Serviços, quando referidas em
                conjunto.
              </p>
              <br />
              <p>
                {" "}
                XII.Comissão: Valor variável baseado em percentual sobre a
                Receita Bruta obtida pelo Prestador de Serviço decorrente de Job
                realizado por intermédio do App Onework, que será pago pelo
                Prestador de Serviço à Onework pela prestação dos serviços
                descritos neste Contrato.
              </p>
              <br />
              <p>
                XIII. XIII. Termo de Adesão: Campo virtual onde o Prestador de
                Serviço confirma sua aceitação expressa e inequívoca a todos os
                termos do Contrato.
              </p>
              <br />
            </div>
            <div className="objeto">
              <h2 className="center">2. OBJETO</h2>
              <p>
                {" "}
                2.1. Consoante condições estipuladas no Contrato, a Onework
                disponibilizará ao Prestador de Serviço, mediante o pagamento de
                Comissão, um espaço virtual App Onework para a exposição de seus
                Jobs aos Usuários, facilitando a contratação entre essas partes.
              </p>
              <br />
              <p>
                2.1.1. Paralelamente ao serviço acima indicado, a Onework
                ofertará, de maneira opcional, serviços de apoio contábil,
                auxílio na abertura de MEI, capacitações, além de serviços de
                rankeamento e rodízio de Prestadores de Serviço, inclusive
                mediante a realização de parcerias, os quais deverão ser
                adquiridos separadamente, conforme descrito no item 6.8 do
                Contrato.
              </p>
              <br />
              <p>
                {" "}
                2.2. A oferta e contratação dos Jobs por meio do App Onework se
                dará exclusivamente dentro dos limites do Território brasileiro.
              </p>
              <br />
              <p>
                2.3. O Prestador de Serviço reconhece e concorda que os serviços
                prestados pela Onework estão limitados aos descritos no item 2.1
                deste Contrato, não abrangendo os serviços de produção,
                qualidade, procedência, logística e/ou entrega dos Jobs, além de
                quaisquer outros não previstos expressamente neste Contrato.
              </p>
              <br />
              <p>
                2.4 A Onework poderá, a qualquer momento e a seu exclusivo
                critério, oferecer serviços adicionais aos Prestadores de
                Serviços por meio do App Onework, inclusive os previstos no item
                2.3 supra, ocasião na qual será informado ao Prestador de
                Serviço, sendo efetuada a atualização do presente Contrato, que
                ficará disponível no App.
              </p>
              <br />
              <p>
                2.5 Oferecidos novos serviços, na forma do item 2.4, caso o
                Prestador de Serviço solicite o incremento destes ao seu Perfil,
                a Onework, a seu critério, disponibilizará os serviços
                adicionais mediante formalização de novas condições do Contrato.
              </p>
              <br />
              <p>
                {" "}
                2.6. A relação existente entre as Partes é de prestação de
                serviços, portanto o Contrato não configura qualquer tipo de
                relação de consumo, trabalho, terceirização, parceria, joint
                venture, sociedade, representação comercial ou de qualquer outra
                natureza entre as Partes que não a estabelecida expressamente.
              </p>
              <br />
            </div>
            <div className="adesao">
              <h2 className="center">
                3. ADESÃO E OPERACIONALIZAÇÃO DOS SERVIÇOS
              </h2>
              <p>
                3.1 Como condição imprescindível para prestação dos serviços
                pela Onework, o Prestador de Serviço se compromete, por meio da
                assinatura eletrônica a este Contrato, a cumprir integralmente
                as obrigações previstas no Contrato, nos Termos de Uso/Políticas
                de Privacidade e nas orientações repassadas pela Onework.
              </p>
              <br />
              <p>
                3.2 O Prestador de Serviço deverá fornecer à Onework todos os
                documentos cadastrais solicitados, bem como mantê-los
                atualizados, responsabilizando-se civil e criminalmente por
                todas as informações apresentadas à Onework.{" "}
              </p>
              <br />
              <p>
                3.3. Após a finalização do cadastro do Prestador de Serviço,
                contendo todas as informações essenciais solicitadas, a Onework
                avaliará, a seu critério, a adequação do Prestador de Serviço ao
                App Onework, podendo, após isso: (i) Recusar o pedido de
                cadastro do Prestador de Serviço ou (ii) Liberar o Prestador de
                Serviço para a criação do seu Perfil no App Onework.
              </p>
              <br />
              <p>
                3.4 O acesso do Prestador de Serviço ao App Onework será
                realizado por meio de Login e Senha criados pelo próprio
                Prestador de Serviço, os quais são de uso pessoal e
                intransferível. A guarda e utilização de tais informações de
                acesso são de responsabilidade exclusiva do Prestador de
                Serviço, que se responsabiliza por todo e qualquer acesso ao App
                Onework por meio do seu Login e Senha.
              </p>
              <br />
              <p>
                3.5. Após o desenvolvimento e a disponibilização do Perfil pela
                Onework, caberá exclusivamente ao Prestador de Serviço divulgar,
                gerenciar e manter atualizadas suas informações a todo tempo,
                inclusive quanto a oferta de Jobs. As informações divulgadas
                pelo Prestador de Serviço no Perfil deverão prevalecer sobre
                todas e quaisquer informações divulgadas em outros meios.
              </p>
              <br />
              <p>
                3.6 Todos os Jobs divulgados pelo Prestador de Serviço no
                Perfil, assim como eventuais informações extras que sejam
                disponibilizadas para o Usuário, serão previamente analisadas
                pela Onework antes de serem publicadas no App, a fim de avaliar
                se estão de acordo com a lei e com os bons costumes. Caso a
                Onework identifique alguma irregularidade na publicação que
                possa indicar desrespeito a direitos de terceiros de qualquer
                natureza, uso de linguagem ou imagem pejorativa, discriminação,
                ofensas, pornografia e/ou conteúdos similares, poderá recusar a
                publicação e notificar o Prestador de Serviço, devendo este
                exclusivamente arcar por eventuais prejuízos e danos causados.{" "}
              </p>
              <br />
              <p>
                {" "}
                3.7. O Prestador de Serviço concorda que os preços dos Jobs
                oferecidos aos Usuários, incluindo eventuais taxas aplicáveis,
                não poderão ser abusivamente superiores aos preços cobrados pelo
                Prestador de Serviço no(s) seu(s) estabelecimento(s) próprio(s),
                físicos ou virtuais.
              </p>
              <br />
              <p>
                3.8 Caso a Onework entenda, a seu critério, que a conduta do
                Prestador de Serviço oferece riscos de qualquer natureza aos
                Usuários e/ou à Onework, a Onework terá o direito de suspender a
                prestação de seus serviços, retirando do ar o Perfil, sem
                prejuízo da rescisão do Contrato.
              </p>
              <br />
              <p>
                3.9 A nomenclatura/marca utilizada pelo Prestador de Serviço,
                bem como a sua identificação, ou a de seus Jobs,
                independentemente da natureza, não poderá conter: (i) semelhança
                com os nomes/marca da Onework; (ii) qualquer nome que insinue ou
                sugira que os Jobs são oferecidos pela Onework; (iii)
                nomes/expressões/sinais pejorativos.
              </p>
              <br />
              <p>
                3.10 O Prestador de Serviços será responsável por preencher
                corretamente e completamente todos os campos referentes aos
                Jobs, especialmente descrição técnica e especificações do
                serviço, assim como disponibilizar aos Usuários eventuais
                imagens e descrições que retratem fielmente os Jobs ofertados.
              </p>
              <br />
              <p>
                3.9.1. O Prestador de Serviço e os Usuários são livres para
                negociar valores e especificidades dos Jobs contratados por meio
                do App Onework, sendo o Prestador de Serviço inteiramente
                responsável por sua garantia e cumprimento nos termos acordados.
                A Onework não terá qualquer responsabilidade diante da
                negociação ou quando da realização do serviço entre as partes.
              </p>
              <br />
              <p>
                {" "}
                3.9.2 A Onework não garante a veracidade das informações dos
                Jobs, não sendo responsável por eventuais divergências ou
                inconsistências destes. Eventuais problemas causados por estas
                publicações serão de responsabilidade exclusiva do Prestador de
                Serviço.
              </p>
              <br />
              <p>
                {" "}
                3.11. Exceto se prévia e expressamente autorizado pela Onework,
                o Prestador de Serviço compromete-se ainda a abster-se de
                realizar contato direito com o Usuário em canais distintos da
                App, direcionar o Usuário para quaisquer outros sites, páginas
                ou canais de comércio eletrônico e/ou físico, sob pena de ser
                descadastrado do App, sem prejuízo da responsabilização em
                perdas, danos e prejuízos de qualquer natureza.
              </p>
              <br />
              <p>
                {" "}
                3.12. É vedada a oferta de qualquer Job de origem ilícita, que
                atente ou possa atentar contra a dignidade humana, os bons
                costumes, as práticas normais de comércio, o direito à livre
                concorrência, não possuam as devidas autorizações, violem as
                normas aplicáveis ou de qualquer maneira ou forma estejam
                impedidos de serem oferecidos por lei, estatuto ou norma de
                ordem pública ou privada.
              </p>
              <br />
              <p>
                {" "}
                3.13 Caso o Job oferecido pelo Prestador de Serviço exija alguma
                permissão específica de agente regulador ou órgão público para a
                sua realização, a obtenção desta, bem como a eventual adequação
                ao cumprimento de medidas sanitárias necessárias, são de inteira
                responsabilidade deste, não tendo a Onework qualquer
                responsabilidade de fiscalização ou acompanhamento ao
                cumprimento destes.
              </p>
              <br />
              <p>
                {" "}
                3.14 Ocorrendo reclamações de Usuários quanto a execução do Job,
                caberá ao Prestador de Serviço responsabilizar-se de maneira
                exclusiva por eventuais falhas na prestação de serviços, bem
                como por eventuais prejuízos causados ao Usuário, inclusive em
                caso de judicialização do conflito, não tendo a Onework qualquer
                responsabilidade quanto à efetiva prestação de serviços
                contratada.
              </p>
              <br />
              <p>
                3.14.1 Havendo reclamação de Usuários, caso o Prestador de
                Serviço reconheça a ocorrência de falhas durante a execução do
                Job, este terá liberdade de negociar descontos, abatimentos,
                promoções e/ou outras formas de recompensar o Usuário. Contudo,
                a Comissão da Onework continuará sendo calculada considerando o
                valor inicialmente contratado pelo Usuário, de modo que futuras
                negociações serão de responsabilidade exclusiva das partes.
              </p>
              <br />
              <p>
                3.15 Os atendimentos aos Usuários efetuados pelo Prestador de
                Serviços serão realizados mediante ferramenta de atendimento
                disponibilizada no App Onework, utilizando a língua portuguesa
                de forma polida e suficientemente clara de maneira a não causar
                confusão ou dificuldade ao consumidor.
              </p>
              <br />
              <p>
                3.16 Os contatos entre Prestador de Serviço e Usuário serão
                monitorados e auditados pela Onework, declarando o Prestador de
                Serviço sua expressa anuência com o disposto nesta cláusula.
              </p>
              <br />
              <p>
                {" "}
                <strong>
                  {" "}
                  3.17 O Prestador de Serviço se compromete a corrigir, no prazo
                  máximo de 24 (vinte e quatro) horas, eventuais anormalidades e
                  inconsistências veiculadas no Perfil que forem comunicadas
                  pela Onework, a critério desta.
                </strong>
              </p>
              <br />
            </div>
            <div className="NÃO-EXCLUSIVIDADE">
              <h2 className="center"> 4. NÃO-EXCLUSIVIDADE</h2>
              <p>
                4.1 O Prestador de Serviço declara que está ciente e de acordo
                com que a Onework preste os serviços objeto deste Contrato a
                quaisquer outras empresas, independentemente de que estas sejam
                direta ou indiretamente concorrentes do Prestador de Serviço.
              </p>
              <br />
              <p>
                {" "}
                4.2. De igual forma, ao Prestador de Serviço não é vedado o
                oferecimento dos Jobs em lojas físicas ou em plataformas
                virtuais, desde que oferecidas condições de preço e pagamento
                que não desfavoreçam o canal de vendas do App Onework.
              </p>
              <br />
            </div>
            <div className="execuçao-de-serviços">
              <h2 className="center">5. EXECUÇÃO DOS SERVIÇOS</h2>
              <p>
                {" "}
                5.1 O Prestador de Serviço se obriga a verificar a sua
                disponibilidade e capacidade para a execução dos Jobs,
                atualizando o status de disponibilidade no Perfil, devendo
                também, confirmar e providenciar a execução dos Jobs em estrita
                consonância às informações que são disponibilizadas no Perfil,
                fazendo uso das melhores práticas na sua área de atuação,
                levando em consideração todos e quaisquer comentários
                eventualmente feitos pelos Usuários.
              </p>
              <br />
              <p>
                {" "}
                5.2 O Prestador de Serviço se compromete a obedecer a todas as
                normas civis e consumeristas, assim como a todas as legislações
                aplicáveis à execução de suas atividades.
              </p>
              <br />
              <p>
                5.3 O Prestador de Serviço deverá abster-se de cancelar
                injustificadamente a execução do Job contratado, e, caso o
                cancelamento seja inevitável, por motivo de caso fortuito ou
                força maior, deverá informar à Onework e ao Usuário que o
                contratou de imediato.
              </p>
              <br />
              <p>
                5.4. O Prestador de Serviço se compromete a não realizar os Jobs
                que tiverem sido cancelados pelo Usuário que o contratou,
                independentemente do motivo do cancelamento.
              </p>
              <br />
              <p>
                {" "}
                5.5. O Prestador de Serviços será o único e exclusivo
                responsável por providenciar a emissão e a entrega de nota
                fiscal, recibo ou documento equivalente para os Usuários com
                relação aos Jobs por eles contratados, além de cumprir com todas
                as suas obrigações fiscais/tributárias nos termos da legislação
                aplicável.
              </p>
              <br />
            </div>
            <div className=" PAGAMENTO">
              <h2 className="center">6. PAGAMENTO</h2>
              <p>
                {" "}
                6.1. A Onework poderá providenciar, a seu critério, as
                ferramentas necessárias para disponibilização das formas de
                pagamento aos Usuários, responsabilizando-se pela contratação
                dos serviços ou mecanismos para controle e validação dos
                pagamentos. Quando oferecidas tais funcionalidades, seu uso será
                obrigatório pelo Prestador de Serviço.
              </p>
              <br />
              <p>
                {" "}
                6.2. Não poderá o Prestador de Serviço restringir ou negar a
                opção do Usuário quanto à forma de pagamento, desde que esta
                tenha sido disponibilizada previamente.
              </p>
              <br />
              <p>
                {" "}
                6.3. O Prestador de Serviço pagará, sempre que contratado para a
                execução de um Job, Comissão indicada no App Onework, que poderá
                variar conforme mecânicas promocionais indicadas no App Onework,
                mediante a retenção devida antes da efetivação do repasse.
              </p>
              <br />
              <p>
                {" "}
                6.3.1. Em caso de cancelamento do Job por iniciativa do Usuário,
                cuja retenção da Comissão e repasse já tenham sido efetuados, a
                Comissão será abatida no próximo repasse, salvo em caso de
                cancelamento por falha do Prestador de Serviço, hipóteses na
                qual este ainda ficará obrigada ao pagamento da Comissão à
                Onework.
              </p>
              <br />
              <p>
                {" "}
                6.3.2. Não sendo estabelecida Comissão específica no App para o
                Job do Prestador de Serviço, fica desde já estabelecida alíquota
                de Comissão no percentual padrão de [inserir percentual] sobre
                quaisquer Jobs realizados.
              </p>
              <br />
              <p>
                {" "}
                6.3.3. É facultado à Onework a alteração da Comissão, conforme
                divulgação prévia no App Onework. Da mesma forma, a Onework
                poderá isentar, a seu critério, a cobrança da Comissão em
                determinados períodos ou a determinados tipos de Jobs.
              </p>
              <br />
              <p>
                {" "}
                6.4. Quando disponibilizada plataforma de meios de pagamento
                indicada na cláusula 6.1 acima, o Prestador de Serviço receberá
                diretamente os valores devidos pelas vendas dos Jobs
                concretizadas por meio do App Onework, descontada a Comissão
                devida à Onework, mediante crédito em conta corrente indicada
                pelo Prestador de Serviço no App.
              </p>
              <br />
              <p>
                {" "}
                6.5. O pagamento da Comissão, quando não for disponibilizada o
                sistema de meios de pagamento pela Onework na Plataforma será
                realizado mediante depósito ou transferência, em conta bancária
                indicada pela Onework, ou mediante boleto bancário, a exclusivo
                critério da Onework.
              </p>
              <br />
              <p>
                {" "}
                6.6. Em caso de atrasos no pagamento da remuneração devida à
                Onework, será cobrada multa de 2% (dois por cento) sobre o valor
                total e juros de mora de 1% (um por cento) ao mês, além de
                correção monetária pelo índice IGPM/FGV, desde a data do
                vencimento até a data do efetivo pagamento.
              </p>
              <br />
              <p>
                {" "}
                6.7. A Onework poderá suspender o repasse de valores referentes
                aos Jobs enquanto existam reclamações pendentes de resolução ou
                sem atendimento satisfatório, ou, ainda, em caso de qualquer
                violação, pelo Prestador de Serviço, do disposto no presente
                Contrato.
              </p>
              <br />
              <p>
                {" "}
                6.8. Será permitido para o Prestador de Serviço a compra de
                serviços de apoio contábil, auxílio na abertura de MEI,
                capacitações, além de serviços de rankeamento e rodízio de
                Prestadores de Serviço no App Onework, o que terá um custo
                adicional para o Prestador de Serviço, a ser acordado entre as
                Partes.
              </p>
              <br />
            </div>
            <div className="direito-propriedade">
              <h2 className="center">DIREITOS DE PROPRIEDADE INTELECTUAL</h2>
              <p>
                {" "}
                7.1 O Prestador de Serviço concede à Onework licença gratuita
                para uso de suas marcas, logotipos e demais sinais distintivos,
                os quais serão veiculados no App Onework e em outras mídias,
                para fins de cumprimento e divulgação dos serviços descritos
                neste Contrato.
              </p>
              <br />
              <p>
                {" "}
                7.2 Todos e quaisquer direitos de propriedade intelectual ou
                industrial decorrentes e/ou relativos aos serviços descritos
                neste Contrato, ou ao App Onework, bem como a quaisquer outros
                conteúdos e/ou plataformas que a Onework disponibilizar ao
                Prestador de Serviço, incluindo, sem limitação, direitos
                autorais patrimoniais, pertencem única e exclusivamente à
                Onework. Em nenhuma hipótese o Contrato implica transferência,
                no todo ou em parte, de qualquer direito de propriedade
                intelectual ou industrial pela Onework ao Prestador de Serviço.
                As determinações desta Cláusula serão aplicáveis a todo e
                qualquer software cujo uso venha a ser licenciado pela Onework
                ao Prestador de Serviço no âmbito da prestação dos serviços
                descritos no presente Contrato.
              </p>
              <br />
              <p>
                {" "}
                7.3. Caso o Prestador de Serviço deseje veicular a marca, o site
                e/ou outros sinais distintivos da Onework em seus
                estabelecimentos, encartes ou em qualquer outro material de
                divulgação, o Prestador de Serviço reconhece e concorda que
                deverá obter a prévia autorização e orientação da Onework, nos
                limites de referida autorização.
              </p>
              <br />
            </div>
            <div className="vigencia">
              <h2 className="center">8. VIGÊNCIA</h2>
              <p>
                8.1. Este Contrato será celebrado por prazo indeterminado, a
                partir da decisão do Prestador de Serviço em aderi-lo
                eletronicamente, ficando os serviços prestados pela Onework,
                salvo disposições em sentido contrário contidas neste
                instrumento, disponíveis até o término do Contrato.
              </p>
              <br />
              <p>
                8.2. As Partes poderão a qualquer momento e em caráter
                imotivado, rescindir o Contrato, com aviso prévio de 30 (trinta)
                dias, sem que desta rescisão decorra qualquer ônus ou obrigação
                de indenizar. As comissões pendentes de pagamento deverão ser
                quitadas pelo Prestador de Serviço antes da saída deste do App
                Onework. Permanecerão exigíveis todas as demais obrigações ainda
                pendentes de cumprimento pelas Partes.
              </p>
              <br />
            </div>
            <div className="RESPONSABILIDADES">
              <h2 className="center">9. RESPONSABILIDADES</h2>
              <p>
                {" "}
                9.1. A Onework será responsável pela disponibilização de Perfil
                no App Onework ao Prestador de Serviço, a fim de que esta possa
                vender seus Jobs aos Usuários, de acordo com o ajustado no
                presente Contrato.
              </p>
              <br />
              <p>
                {" "}
                9.2. Os Jobs são de exclusiva responsabilidade do Prestador de
                Serviço, podendo a Onework, a seu exclusivo critério e sem a
                necessidade de aviso ao Prestador de Serviço, vedar, suspender
                ou interromper a comercialização de Jobs, especialmente quando
                houver a violação do Contrato ou da legislação vigente.
              </p>
              <br />
              <p>
                {" "}
                9.3. Em nenhuma hipótese a Onework poderá ser responsabilizada
                pela execução dos Jobs, a qual caberá exclusivamente ao
                Prestador de Serviço.
              </p>
              <br />
              <p>
                {" "}
                9.4. O Prestador de Serviço desde já reconhece que, em caso de
                demanda judicial ou processo administrativo em que a Onework for
                demandada, sendo os fatos da demanda fundada em ações ou
                omissões do Prestador de Serviço, incluindo, mas não se
                limitando, à falhas na prestação de serviços, responsabilidades
                trabalhistas, tributárias, entre outros, o Prestador de Serviços
                será acionado para solucionar a reclamação, fornecer os
                subsídios necessários para promover a defesa e, quando possível,
                chamado ao processo, devendo em qualquer hipótese arcar com
                todos os ônus decorrentes, especialmente indenizações,
                obrigações, perdas e danos, honorários advocatícios e custas
                judiciais, mediante apresentação do comprovante de pagamento.
              </p>
              <br />
              <p>
                9.4.1 Caso a Onework permaneça nos autos, por sua opção ou não
                ter sido acolhida sua exclusão da lide, esta constituirá seus
                advogados para sua defesa, correndo por conta do Prestador de
                Serviço todas as despesas pertinentes.
              </p>
              <br />
              <p>
                {" "}
                9.4.2 O Prestador de Serviço deverá reembolsar a Onework de
                todas as despesas incorridas mediante compensação no repasse,
                corrigido monetariamente com base na variação do INPC (ou na sua
                falta pelo índice que vier a substituí-lo), acrescido de juros
                moratórios de 1% (um por cento) ao mês pro rata die e multa de
                2% (dois por cento). Inexistindo saldo para reembolso, a Onework
                poderá demandar judicialmente, independentemente de prévia
                notificação ou aviso.
              </p>
              <br />
              <p>
                {" "}
                9.5. O Prestador de Serviço responderá por qualquer fraude
                realizada por si ou por terceiros a ele vinculados, em meio
                virtual ou físico, de ações ou omissões culposas ou dolosas, ou
                ainda, decorrentes da violação de quaisquer condições
                estabelecidas no Contrato.
              </p>
              <br />
              <p>
                9.5.1. A Onework poderá reter ou não concretizar operações caso,
                a seu exclusivo critério, verifique a possibilidade de estarem
                sendo cometidas fraudes ou outros crimes, em meio virtual ou
                físico, relacionados aos Usuários e/ou ao Prestador de Serviço.
              </p>
              <br />
            </div>
            <div className="alteraçao-contrato">
              <h2 className="center"> 10. ALTERAÇÃO DESTE CONTRATO</h2>
              <p>
                {" "}
                10.1. O Prestador de Serviço reconhece e concorda que a Onework
                poderá alterar o presente Contrato a qualquer tempo, a seu
                exclusivo critério, incluindo as condições comerciais, pagamento
                de Comissões.
              </p>
              <br />
              <p>
                {" "}
                10.2. Havendo alterações neste Contrato que impliquem em
                modificações substanciais de suas condições, a Onework dará
                ciência ao Prestador de Serviço por meio de avisos no App
                Onework ou mediante e-mail.
              </p>
              <br />
              <p>
                {" "}
                10.3. Caso o Prestador de Serviço não concorde com as alterações
                do Contrato, poderá optar por cancelar sua conta no App Onework.
                A utilização do App após suas alterações implica em aceitação
                tácita da nova versão do Contrato.
              </p>
              <br />
            </div>
            <div className="proteçao-dados">
              <h2 className="center">11. PROTEÇÃO DE DADOS PESSOAIS</h2>
              <p>
                11.1. No desenvolvimento de quaisquer atividades relacionadas a
                execução do Contrato, as Partes se obrigam a observar o regime
                legal da proteção de dados pessoais, em especial, mas não se
                limitando, ao Regime da Lei 13.709/2018, de modo a proceder a
                todo e qualquer tratamento dos dados pessoais que venha a se
                mostrar necessário ao desenvolvimento do Contrato no estrito e
                rigoroso cumprimento da lei.
              </p>
              <br />
              <p>
                11.2. Para os fins da presente Cláusula o Prestador de Serviços
                se obriga a:
                <br /> <strong> a)</strong> Tratar e usar os dados pessoais nos
                termos legalmente permitidos, em especial recolhendo,
                registrando, organizando, conservando, consultando ou
                transmitindo estes, apenas e somente nos casos em que o seu
                titular tenha dado o consentimento inequívoco ou nas demais
                hipóteses legalmente previstas;
                <br />
                <br /> <strong> b)</strong> Tratar os dados de modo compatível
                com as finalidades para os quais tenham sido recolhidos;
                <br />
                <br /> <strong> c)</strong> Conservar os dados apenas durante o
                período necessário à prossecução das finalidades da coleta ou do
                tratamento posterior, garantindo a sua confidencialidade;
                <br />
                <br /> <strong> d)</strong> Implementar as medidas técnicas e
                organizativas necessárias para proteger os dados contra a
                destruição, acidental ou ilícita, a perda acidental, a
                alteração, a difusão ou o acesso não autorizado, bem como contra
                qualquer outra forma de tratamento ilícito destes; <br />
                <br /> <strong> e)</strong> Informar imediatamente à outra
                Parte, devendo prestar toda a colaboração necessária a qualquer
                investigação que venha a ser realizada, caso exista alguma
                quebra de segurança, ou suspeita desta, independentemente de
                colocar ou não em causa a segurança e integridade dos dados
                pessoais; <br />
                <br /> <strong> f)</strong> Garantir o exercício, pelos
                titulares, dos direitos previstos na legislação aplicável;
                <br /> <br /> <strong> g)</strong>
                Anonimizar, bloquear ou eliminar dados desnecessários,
                excessivos ou tratados em desconformidade com o disposto na Lei
                13.709/2018 ou legislação aplicável;
                <br /> <br /> <strong> h)</strong> Assegurar a portabilidade dos
                dados a outro fornecedor de serviço ou produto, mediante
                requisição expressa e observados os segredos comercial e
                industrial, de acordo com a regulamentação vigente à época da
                requisição;
                <br /> <br /> <strong> i)</strong> Assegurar que os respetivos
                colaboradores ou os prestadores de serviços externos por si
                contratados e que venham a ter acesso a dados pessoais no
                contexto do Contrato cumpram as disposições legais aplicáveis em
                matéria de proteção de dados pessoais, designadamente, não
                cedendo ou divulgando tais dados pessoais a terceiros, nem deles
                fazendo uso para quaisquer fins que não os estritamente
                consentidos pelos respetivos titulares.
              </p>
              <br />
              <p>
                11.3. Caso a Onework venha a ser penalizada em razão de qualquer
                descumprimento da legislação de proteção de dados, por culpa
                exclusiva do Prestador de Serviço, então o Prestador de Serviço
                se obriga, de maneira irrevogável e irretratável a indenizar e
                manter indene a Onework, sem prejuízo da sua responsabilização
                pelas perdas e danos decorrentes.
              </p>
              <br />
            </div>
            <div className="disposiçoes-gerais">
              <h2 className="center">12. DISPOSIÇÕES GERAIS</h2>
              <p>
                12.1. A relação jurídica estabelecida entre as Partes é de
                prestação de serviços, de modo que o presente Contrato não
                estabelece relação de relação de consumo, trabalho,
                terceirização de trabalho, representação comercial, franquia ou
                de qualquer outra natureza entre as Partes e entre as pessoas
                que colaboram com as Partes, sendo certo que as Partes são e
                permanecerão a todo tempo autônomas e independentes entre si.
              </p>
              <br />
              <p>
                12.2. Os direitos e as obrigações do Prestador de Serviços
                previstos neste Contrato não poderão ser cedidos ou de qualquer
                outra forma transferidos, no todo em parte, pelo Prestador de
                Serviço a quaisquer terceiros sem a prévia concordância por
                escrito da Onework. O Prestador de Serviço, por sua vez,
                reconhece e concorda que a Onework poderá ceder e transferir os
                seus direitos e obrigações previstos no Contrato a quaisquer
                terceiros.
              </p>
              <br />
              <p>
                12.3. As relações jurídicas estabelecidas por este Contrato são
                celebradas em caráter irrevogável e irretratável, obrigando as
                Partes e seus sucessores, seja qual for o título da sucessão. A
                eventual tolerância por qualquer das Partes quanto ao inexato ou
                impontual cumprimento das obrigações da outra Parte valerá
                tão-somente de forma isolada, não constituindo renúncia ou
                novação de qualquer espécie.
              </p>
              <br />
              <p>
                12.4. Caso qualquer disposição deste Contrato se torne nula ou
                ineficaz, a validade ou eficácia das disposições restantes não
                será afetada, permanecendo em pleno vigor e efeito e, em tal
                caso, as Partes entrarão em negociações de boa-fé visando a
                substituir a disposição ineficaz por outra que, tanto quanto
                possível e de forma razoável, atinja a finalidade e os efeitos
                desejados.
              </p>
              <br />
              <p>
                12.5. As disposições contidas neste Contrato representam a
                totalidade dos entendimentos mantidos entre as Partes
                relativamente aos assuntos de que ele trata, superando todos e
                quaisquer entendimentos anteriores, verbais ou escritos, havidos
                entre as Partes, consubstanciando-se na declaração final de suas
                vontades.
              </p>
              <br />
              <p>
                12.6. Ao clicar em “Eu Aceito” ou ao utilizar efetivamente o App
                Onework, o Prestador de Serviço declara que leu e concordou com
                a versão mais recente deste Contrato, estando ele
                automaticamente vinculado. Tal aceite, juntamente com os
                documentos enviados pelo Prestador de Serviço, serão
                considerados como assinatura eletrônica reconhecida pelas Partes
                para todos os fins deste instrumento, nos termos do art. 10,
                parágrafo 2º, da MP 2200-2/2001.
              </p>
              <br />
              <p>
                12.7. O Prestador de Serviço reconhece e aceita que a Onework
                poderá, a seu exclusivo critério, parar (permanente ou
                temporariamente) de prestar seus serviços (ou qualquer parte dos
                serviços) a si ou à generalidade dos utilizadores, sem avisar
                previamente.
              </p>
              <br />
              <p>
                12.8. A pessoa física representante do Prestador de Serviço,
                quando este for pessoa jurídica, declara e garante possuir
                capacidade jurídica para celebrar este Contrato e para
                comercializar os Jobs, não ferindo quaisquer direitos de
                terceiros.
              </p>
              <br />
              <p>
                12.9. O Prestador de Serviço declara que leu, que está ciente e
                de pleno acordo com todos os termos e condições deste Contrato,
                com as condições específicas e comerciais estabelecidas no App
                Onework, concorda irrestritamente com os Termos de Uso do App
                Onework e está ciente da Política de Privacidade do App.
              </p>
              <br />
              <p>
                12.10. Nenhuma das Partes responde pelos insucessos comerciais
                da outra e por reclamações de terceiros, clientes destas, exceto
                nos casos em que for comprovada a ação ou omissão deliberada a
                fim de prejudicar a outra, caracterizando o dolo.
              </p>
              <br />
              <p>
                12.11. O Contrato poderá ser levado a registro público perante
                quaisquer dos Cartórios de Registros e Títulos e Documentos da
                Comarca de [inserir], nos termos da legislação vigente, sendo
                exigível do Prestador de Serviço no momento de concretização de
                sua adesão.
              </p>
              <br />
              <p>
                12.12. O Prestador de Serviço deverá respeitar integralmente a
                legislação brasileira e os princípios de direitos humanos, não
                se utilizando direta ou indiretamente de trabalho forçado,
                infantil e/ou análogo ao escravo; não se utilizando de práticas
                de discriminação negativa e limitativas para o acesso e
                manutenção do emprego, tais como por motivo de sexo, origem,
                raça, cor, condição física, religião, estado civil, idade,
                situação familiar, estado gravídico, etc.
              </p>
              <br />
            </div>
            <div className="contato">
              <h2 className="center">13. CONTATO</h2>
              <p>
                13.1. Fica estipulado que qualquer comunicação relativa ao
                vínculo estabelecido por meio do presente Contrato será
                direcionada exclusivamente ao e-mail atendimento@onework.app.br (quando
                para a Onework) e para o e-mail indicado no momento do cadastro
                (quando para o Prestador de Serviço) sendo de inteira
                responsabilidade do Prestador de Serviço as informações por si
                inseridas.
              </p>
              <br />
            </div>
            <div className="PENALIDADES">
              <h2 className="center">14. PENALIDADES</h2>
              <p>
                14.1. Qualquer Parte que infringir o Contrato, de forma
                injustificada e comprovada, será responsável por todas as
                perdas, danos e prejuízos ocasionados à Parte contrária, sendo
                obrigada à sua reparação, na exata extensão do dano sofrido.
              </p>
              <br />
            </div>
            <div className="legislaçao-aplicavel">
              <h2 className="center">15. LEGISLAÇÃO APLICÁVEL E FORO</h2>
              <p>
                15.1. O presente Contrato será regido e interpretado de acordo
                com a legislação da República Federativa do Brasil.
              </p>
              <br />
              <p>
                15.2. No caso de qualquer reivindicação ou controvérsia
                decorrente deste Contrato, ou a ele relacionada, as Partes
                elegem desde já o foro da Comarca de [inserir], para a solução
                da reivindicação ou controvérsia, com a exclusão de qualquer
                outro, por mais privilegiado que seja.
              </p>
             
            </div>
          <a href="/principal"> voltar a pagina principal</a>
          </div>
        </div>
      </>
    );
  }
}
