import React, { Component } from 'react';
import logoBranca from '../../../../assets/image/logobranca.png';
import './footer.css';

export default class Footer extends Component {
    render() {
        return (
            <footer className='footer-container'>
                <footer className='footer-wrapper'>
                    <img src={logoBranca} alt="logo" className='footer-logo' />
                    <div className='footer-nav-container'>

                        <nav className='footer-nav'>
                            <a className='footer-texto' href="#sobre-container">QUEM SOMOS</a>
                            <a className='footer-texto' href="#prova-social-container">DEPOIMENTOS</a>
                        </nav>
                        <nav className='footer-nav'>
                            <a className='footer-texto' href="#funcionalidade-container">COMO USAR</a>
                            <a className='footer-texto' href="/">TERMOS DE USO</a>
                            <a className='footer-texto' href="/">POLITICA E PRIVACIDADE</a>
                        </nav>
                        <nav className='footer-nav'>
                            <a className='footer-texto' href="https://www.instagram.com/instalacaoexpress/" target="_blank" rel="noreferrer">INSTAGRAM</a>
                            <a className='footer-texto' href="https://www.facebook.com/InstalacaoExpress" target="_blank" rel="noreferrer">FACEBOOK</a>
                            <a className='footer-texto' href="https://www.youtube.com/channel/UCG4jClyCHyCEuAgGqIaSRKQ" target="_blank" rel="noreferrer">YOUTUBE</a>
                            <a className='footer-texto' href="https://www.linkedin.com/company/instalacaoexpress/" target="_blank" rel="noreferrer">LINKEDIN</a>
                        </nav>
                    </div>
                </footer>
            </footer>
        )
    }
}