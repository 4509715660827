import React, { Component } from 'react';
import loading from '../../assets/image/loading.gif';

export default class Loading extends Component {
    render() {
        return this.props.visivel ? <div style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: 100 }}>
            <img src={loading} alt="loading" style={{ width: 150, margin: '0 auto' }} />
        </div> : null

    }
}