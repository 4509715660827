import PessoaService from 'lirida-main-js-service/service/Pessoa/PessoaService';
import TipoContatoService from 'lirida-main-js-service/service/TipoContato/TipoContatoService';
import Pessoa from 'lirida-main-js-service/service/Pessoa/Pessoa';
import Usuario from 'lirida-main-js-service/service/Usuario/Usuario';
import React, { Component } from 'react';
import Util from '../../../util/Util';
import Container from '../sistema-components/Container/Container';
import Header from '../sistema-components/Header/Header';
import Sidebar from '../sistema-components/Sidebar/Sidebar';
import '../sistema.css';
import { FaArrowLeft, FaPen, FaSearch, FaTrash } from 'react-icons/fa';
import MsgOK from '../../../component/MsgOK/MsgOK';
import Input from '../../../component/Input/Input';
import Loading from '../../../component/Loading/Loading';
import PopUp from '../../../component/PopUp/PopUp';
import MsgError from '../../../component/MsgError/MsgError';
import Contato from 'lirida-main-js-service/service/Contato/Contato';
import TipoContato from 'lirida-main-js-service/service/TipoContato/TipoContato';
import TipoEndereco from 'lirida-main-js-service/service/TipoEndereco/TipoEndereco';
import PessoaCaracteristicaPessoaService from 'lirida-main-js-service/service/PessoaCaracteristicaPessoa/PessoaCaracteristicaPessoaService';
import TipoRelacionamentoService from 'lirida-main-js-service/service/TipoRelacionamento/TipoRelacionamentoService';
import TipoRelacionamento from 'lirida-main-js-service/service/TipoRelacionamento/TipoRelacionamento';
import PessoaRelacionamento from 'lirida-main-js-service/service/PessoaRelacionamento/PessoaRelacionamento';
import PessoaPerfilService from 'lirida-main-js-service/service/PessoaPerfil/PessoaPerfilService';
import PessoaPerfil from 'lirida-main-js-service/service/PessoaPerfil/PessoaPerfil';
import TipoEnderecoService from 'lirida-main-js-service/service/TipoEndereco/TipoEnderecoService';
import TipoArquivo from 'lirida-main-js-service/service/TipoArquivo/TipoArquivo';
import PessoaArquivo from 'lirida-main-js-service/service/PessoaArquivo/PessoaArquivo';
import TipoArquivoService from 'lirida-main-js-service/service/TipoArquivo/TipoArquivoService';
import TipoAtendimento from 'lirida-main-js-service/service/TipoAtendimento/TipoAtendimento';
import PessoaAtendimento from 'lirida-main-js-service/service/PessoaAtendimento/PessoaAtendimento';
import TipoAtendimentoService from 'lirida-main-js-service/service/TipoAtendimento/TipoAtendimentoService';
import Endereco from 'lirida-main-js-service/service/Endereco/Endereco';
import Constante from '../../../util/Constante';
import { Navigate } from 'react-router-dom';
import GoogleAPI from 'lirida-main-js-service/service/Google/GoogleAPI';

export default class PessoaPg extends Component {
    util = new Util();
    pessoaService = new PessoaService();
    tipoContatoService = new TipoContatoService();
    constante = new Constante();
    tipoEnderecoService = new TipoEnderecoService();
    pessoaCaracteristicaPessoaService = new PessoaCaracteristicaPessoaService();
    tipoRelacionamentoService = new TipoRelacionamentoService();
    tipoArquivoService = new TipoArquivoService();
    tipoAtendimentoService = new TipoAtendimentoService();
    pessoaPerfilService = new PessoaPerfilService();
    googleAPI = new GoogleAPI();

    state = {
        pessoaUsuario: new Pessoa(),
        pessoa: new Pessoa(),
        filtro: 'com filtro',
        listaPessoa: [],
        tela: '1',
        conteudo: '',
        popupDeletarPessoa: false,
        msgOKVisivel: false,
        msgErrorVisivel: false,
        mensagem: '',
        carregando: false,
        tag: 'Enderecos',
        contato: new Contato(),
        listaTipoContato: [],
        listaContato: [],
        tipoContato: new TipoContato(),
        popupDeletarContatoVisivel: false,
        endereco: new Endereco(),
        listaTipoEndereco: [],
        listaEndereco: [],
        tipoEndereco: new TipoEndereco(),
        listaPessoaCaracteristica: [],
        listaPessoaPerfil: [],
        listaPessoaAtendimento: [],
        listaTipoAtendimento: [],
        pessoaAtendimento: new PessoaAtendimento(),
        tipoAtendimento: new TipoAtendimento(),
        popupDeletarEnderecoVisivel: false,
        listaTipoArquivo: [],
        listaPessoaArquivo: [],
        pessoaArquivo: new PessoaArquivo(),
        tipoArquivo: new TipoArquivo(),
        popupDeletarPessoaArquivoVisivel: false,
        pessoaPerfil: new PessoaPerfil(),
        listaPessoaRelacionamento: [],
        pessoaRelacionamento: new PessoaRelacionamento(),
        tipoRelacionamento: new TipoRelacionamento(),
        listaTipoRelacionamento: [],
        listaPessoaDoRelacionamento: [],
        popupDeletarPessoaRelacionamentoVisivel: false,
        listaEnderecoGoogle: [],
        mudarTela: false,
    }

    async componentDidMount() {
        this.selecionarPessoaPerfil(null);
        await this.iniciar();
    }

    async iniciar() {
        let pessoaUsuario = await this.util.buscarLocalStorage('PESSOA');
        this.urlBaseUrlArquivoPessoa = await this.util.buscarLocalStorage('URL_BASE_ARQUIVO_PESSOA');

        // if (usuario == null || pessoaUsuario == null) {
        //     this.setState({
        //         mudarTela: true
        //     })
        // }

        this.setState({
            urlBaseUrlArquivoPessoa: this.urlBaseUrlArquivoPessoa,
            pessoaUsuario: pessoaUsuario
        });

        // if (this.state.pessoaUsuario.seqPessoaPerfil !== this.constante.seqPessoaPerfilAdministrativo) {
        //     await this.util.removerLocalStorage('USUARIO');
        //     await this.util.removerLocalStorage('PESSOA');
        //     this.setState({
        //         mudarTela: true
        //     })
        // }

        let parametros = this.buscarParametrosUrl();

        if (parametros) {

        }

        // this.listar();
        this.listarTipoContato();
        this.listarTipoEndereco();
        this.listarTipoArquivo();
        this.listarTipoAtendimento();
        this.listarPessoaPerfil();
        this.listarTipoRelacionamento();
    }

    async listarTipoArquivo() {
        let retorno = await this.tipoArquivoService.listar(this.constante.seqUsuario, 'P')
        this.setState({ listaTipoArquivo: retorno.objeto });
    }

    async listarTipoAtendimento() {
        let retorno = await this.tipoAtendimentoService.listar(this.constante.seqUsuario, 'P')
        this.setState({ listaTipoAtendimento: retorno.objeto });
    }

    async listarPessoaPerfil() {
        let retorno = await this.pessoaPerfilService.listar(this.constante.seqUsuario)
        this.setState({ listaPessoaPerfil: retorno.objeto });
    }

    async listarTipoRelacionamento() {
        let retorno = await this.tipoRelacionamentoService.listar(this.constante.seqUsuario, 'P');
        this.setState({ listaTipoRelacionamento: retorno.objeto })
    }

    async listarCaracteristicaPessoa(pPessoa) {
        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        let retorno = await this.pessoaCaracteristicaPessoaService.listar(pessoaUsuario);
        this.setState({
            listaPessoaCaracteristica: retorno.objeto,
        })

        console.log(retorno.objeto)
    }

    async listarTipoContato() {
        let retornoTipoContato = await this.tipoContatoService.listar()

        this.setState({
            listaTipoContato: retornoTipoContato.objeto
        });
    }
    async listarTipoEndereco() {
        let retornoTipoEndereco = await this.tipoEnderecoService.listar()

        this.setState({
            listaTipoEndereco: retornoTipoEndereco.objeto
        });
    }

    buscarParametrosUrl() {
        if (!window.location.search) {
            return null;
        }

        let query = window.location.search.slice(1);
        let partes = query.split('&');
        let data = {};
        partes.forEach(function (parte) {
            let chaveValor = parte.split('=');
            let chave = chaveValor[0];
            let valor = chaveValor[1];
            data[chave] = valor;
        });

        return data;
    }

    async listar() {
        this.setState({ carregando: true });
        console.log("---->" + this.state.pessoaPerfil.seqPessoaPerfil);
        let retorno;
        if (this.state.pessoaPerfil.seqPessoaPerfil == 0) {
            this.setState({ carregando: false });
            retorno = await this.pessoaService.listar(this.constante.seqUsuario, this.state.conteudo, '', '', '', '', '', '', '', '', '', '');
        } else {
            this.setState({ carregando: false });
            retorno = await this.pessoaService.listar(this.constante.seqUsuario, this.state.conteudo, this.state.pessoaPerfil.seqPessoaPerfil, '', '', '', '', '', '', '', '',);

        }

        this.setState({
            listaPessoa: retorno.objeto,
            carregando: false,
        })
    }

    async salvar(pSubmit) {
        pSubmit.preventDefault();
        this.setState({
            carregando: true,
            msgOKVisivel: false,
            msgErrorVisivel: false,
        });

        let pessoa = this.state.pessoa;
        // console.log(pessoaUsuario);

        // console.log(pessoaUsuario.documento);

        // this.retorno = await this.util.validarDocumento(pessoaUsuario.documento);
        // console.log(this.retorno);

        if (this.retorno == false) {
            return this.setState({
                msgErrorVisivel: true,
                mensagem: "O documento digitado não é um documento válido",
                carregando: false,
            })
        }

        pessoa.seqUsuario = this.constante.seqUsuario;
        if (pessoa.seqPessoa == null) {
            pessoa.senha = this.util.formatarParaSenha(pessoa.documento);
        }

        let retornoPessoa = await this.pessoaService.salvar(pessoa);
        console.log(retornoPessoa);

        if (retornoPessoa.resultado === false) {
            return;
        }

        pessoa = retornoPessoa.objeto;

        this.setState({
            pessoa: pessoa,
            msgOKVisivel: true,
            mensagem: retornoPessoa.msg,
            carregando: false,
        });

        // this.listar();
        // this.listarContato(pessoaUsuario);
        // this.listarEndereco(pessoaUsuario);
        // this.listarCaracteristicaPessoa(pessoaUsuario);
        // this.listarPessoaRelacionamento(pessoaUsuario);
        // this.listarPessoaArquivo(pessoaUsuario);
        // this.listarPessoaAtendimento(pessoaUsuario);
    }

    async novo() {
        this.limparDados();
        this.setState({
            tela: '2',
        });
    }

    limparDados() {
        this.setState({
            contato: new Contato(),
            tipoContato: new TipoContato(),
            endereco: new Endereco(),
            tipoEndereco: new TipoEndereco(),
            pessoaAtendimento: new PessoaAtendimento(),
            tipoAtendimento: new TipoAtendimento(),
            pessoaArquivo: new PessoaArquivo(),
            tipoArquivo: new TipoArquivo(),
            pessoaRelacionamento: new PessoaRelacionamento(),
            tipoRelacionamento: new TipoRelacionamento(),
            pessoaUsuario: new Pessoa(),
        })
    }

    async deletar() {
        this.setState({ carregando: true, })
        await this.pessoaService.deletar(this.state.pessoaUsuario.seqPessoa);
        this.setState({ popupDeletarPessoa: false, tela: '1', carregando: false, })
        this.listar();
    }

    selecionar(pItem) {
        this.limparDados();
        let pessoaUsuario = pItem;

        this.setState({
            pessoaUsuario: pessoaUsuario,
            tela: '2',
        })

        this.listarContato(pessoaUsuario);
        this.listarEndereco(pessoaUsuario);
        this.listarCaracteristicaPessoa(pessoaUsuario);
        this.listarPessoaRelacionamento(pessoaUsuario);
        this.listarPessoaArquivo(pessoaUsuario);
        this.listarPessoaAtendimento(pessoaUsuario);
    }

    alerta_popupDeletarPessoa() {
        this.setState({ popupDeletarPessoa: true });
    }


    alerta_deletarPessoaArquivo(pItem) {
        this.setState({
            pessoaArquivo: pItem,
            popupDeletarPessoaArquivoVisivel: true
        });
    }



    mostrarMensagem(pTipo, pMensagem) {
        if (pTipo === "0") {

        }

        if (pTipo === '1') {
            this.setState({
                mensagemVisivel: true,
                mensagem: pMensagem
            });
        }

        if (pTipo === "2") {

        }
    }

    formatarCPFCNPJ(pTexto) {
        this.setState({
            pessoa: {
                ...this.state.pessoa,
                documento: this.util.formatarCPFCNPJ(pTexto)
            }
        })
    }


    buscarTipoContato(pSeqTipoContato) {
        let listaTipoContato = this.state.listaTipoContato;
        console.log(pSeqTipoContato)

        for (let i = 0; i < listaTipoContato.length; i++) {
            if (listaTipoContato[i].seqTipoContato === Number(pSeqTipoContato)) {
                this.setState({
                    tipoContato: listaTipoContato[i]
                })
                console.log(listaTipoContato[i])
            }
        }
    }

    buscarTipoEndereco(pSeqTipoEndereco) {
        let listaTipoEndereco = this.state.listaTipoEndereco;
        console.log(pSeqTipoEndereco)

        for (let i = 0; i < listaTipoEndereco.length; i++) {
            if (listaTipoEndereco[i].seqTipoEndereco === Number(pSeqTipoEndereco)) {
                this.setState({
                    tipoEndereco: listaTipoEndereco[i]
                })
                console.log(listaTipoEndereco[i])
            }
        }
    }

    buscarTipoRelacionamento(pSeqTipoRelacionamento) {
        let listaTipoRelacionamento = this.state.listaTipoRelacionamento;
        console.log(pSeqTipoRelacionamento)

        for (let i = 0; i < listaTipoRelacionamento.length; i++) {
            if (listaTipoRelacionamento[i].seqTipoRelacionamento === Number(pSeqTipoRelacionamento)) {
                this.setState({
                    tipoRelacionamento: listaTipoRelacionamento[i]
                })
                console.log(listaTipoRelacionamento[i])
            }
        }
    }

    buscarTipoAtendimento(pSeqTipoAtendimento) {
        let listaTipoAtendimento = this.state.listaTipoAtendimento;
        console.log(pSeqTipoAtendimento)

        console.log(listaTipoAtendimento)

        for (let i = 0; i < listaTipoAtendimento.length; i++) {
            if (listaTipoAtendimento[i].seqTipoAtendimento === Number(pSeqTipoAtendimento)) {
                this.setState({
                    tipoAtendimento: listaTipoAtendimento[i]
                })
                console.log(listaTipoAtendimento[i])
            }
        }
    }

    async listarContato(pPessoa) {
        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        let retornoListaContato = await this.pessoaService.listarContato(pessoaUsuario);
        this.setState({
            listaContato: retornoListaContato.objeto
        })
        console.log(retornoListaContato.objeto);
    }

    novoContato() {
        this.setState({
            contato: new Contato(),
            tipoContato: new TipoContato(),
        })
    }

    async salvarContato() {
        this.setState({ carregando: true, })
        let contato = this.state.contato;
        contato.seqPessoa = this.state.pessoaUsuario.seqPessoa;
        contato.seqUsuario = this.state.pessoaUsuario.seqUsuario;

        console.log(contato)
        await this.pessoaService.salvarContato(contato);

        this.novoContato();
        this.listarContato();
        this.setState({ carregando: false, })
    }

    alerta_deletarContato() {
        this.setState({
            popupDeletarContatoVisivel: true
        })
    }

    async deletarContato() {
        await this.pessoaService.deletarContato(this.state.contato);

        this.setState({
            contato: new Contato(),
            popupDeletarContatoVisivel: false,
        })

        this.novoContato();
        this.listarContato();
    }

    selecionarContato(pContato) {
        let contato = pContato;
        this.setState({
            contato: contato,
        })
        this.buscarTipoContato(contato.seqTipoContato);
    }

    async listarEndereco(pPessoa) {
        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        let retornoListaEndereco = await this.pessoaService.listarEndereco(pessoaUsuario);
        this.setState({
            listaEndereco: retornoListaEndereco.objeto
        })
        console.log(retornoListaEndereco.objeto);
    }

    novoEndereco() {
        this.setState({
            endereco: new Endereco(),
            tipoEndereco: new TipoEndereco(),
        })
    }

    async salvarEndereco() {
        this.setState({ carregando: true, })
        let endereco = this.state.endereco;
        endereco.seqPessoa = this.state.pessoaUsuario.seqPessoa;
        endereco.seqUsuario = this.state.pessoaUsuario.seqUsuario;

        console.log(endereco)
        await this.pessoaService.salvarEndereco(endereco);

        this.novoEndereco();
        this.listarEndereco();
        this.setState({ carregando: false, })
    }

    alerta_deletarEndereco() {
        this.setState({
            popupDeletarEnderecoVisivel: true
        })
    }

    async deletarEndereco() {
        await this.pessoaService.deletarEndereco(this.state.endereco);

        this.setState({
            endereco: new Endereco(),
            popupDeletarEnderecoVisivel: false,
        })

        this.novoEndereco();
        this.listarEndereco();
    }

    selecionarEndereco(pEndereco) {
        let endereco = pEndereco;
        this.setState({
            endereco: endereco,
        })
        this.buscarTipoEndereco(endereco.seqTipoEndereco);
    }

    selecionarPessoaArquivo(pPessoaArquivo) {
        let pessoaArquivo = pPessoaArquivo;
        this.setState({
            pessoaArquivo: pessoaArquivo,
        })
        this.buscarTipoArquivo(pessoaArquivo.seqTipoPessoaArquivo);
    }

    selecionarPessoaAtendimento(pPessoaAtendimento) {
        let pessoaAtendimento = pPessoaAtendimento;
        this.setState({
            pessoaAtendimento: pessoaAtendimento,
        })
        this.buscarTipoAtendimento(pessoaAtendimento.seqPessoaAtendimento);
    }

    async buscarCep() {
        this.setState({ carregando: true })
        let retornoEndereco;
        try {
            retornoEndereco = await this.util.buscarCep(this.state.endereco.cep);
        } catch {
            this.setState({
                carregando: false,
            })
        }
        console.log(retornoEndereco)

        this.setState({
            carregando: false,
            endereco: {
                ...this.state.endereco,
                bairro: retornoEndereco.bairro,
                cidade: retornoEndereco.localidade,
                logradouro: retornoEndereco.logradouro,
                uf: retornoEndereco.uf
            }
        })
    }


    marcarDesmarcarCaracteristica(pIndex) {
        let listaPessoaCaracteristica = this.state.listaPessoaCaracteristica;

        if (listaPessoaCaracteristica[pIndex].marcado === "true") {
            listaPessoaCaracteristica[pIndex].marcado = "false";
        } else if (listaPessoaCaracteristica[pIndex].marcado === "false") {
            listaPessoaCaracteristica[pIndex].marcado = "true";
        }

        listaPessoaCaracteristica[pIndex].seqPessoa = this.state.pessoaUsuario.seqPessoa;

        this.setState({
            listaPessoaCaracteristica: listaPessoaCaracteristica,
        })
    }

    async salvarCaracteristica() {
        this.setState({ carregando: true, })
        let retorno = await this.pessoaCaracteristicaPessoaService.salvar(this.state.listaPessoaCaracteristica);
        console.log(retorno);

        if (retorno.resultado === true) {
            this.setState({
                msgOKVisivel: true,
                mensagem: retorno.msg,
                carregando: false
            })
        }

        if (retorno.resultado === false) {
            this.setState({
                msgErrorVisivel: true,
                mensagem: retorno.msg,
                carregando: false
            })
        }

        setTimeout(
            () => this.setState({ msgOKVisivel: false, msgErrorVisivel: false })
            , 2500
        )
    }


    novoPessoaAtendimento() {
        this.tipoAtendimento = this.state.tipoAtendimento;
        this.tipoAtendimento = new TipoAtendimento();
        console.log(this.tipoAtendimento.seqTipoAtendimento);


        this.setState({
            pessoaAtendimento: new PessoaAtendimento(),
            tipoAtendimento: new TipoAtendimento(),
        })
    }



    async listarPessoaAtendimento(pPessoa) {
        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        let retornoListaPessoaAtendimento = await this.pessoaService.listarAtendimento(pessoaUsuario);
        this.setState({
            listaPessoaAtendimento: retornoListaPessoaAtendimento.objeto
        })
        console.log(retornoListaPessoaAtendimento.objeto);
    }

    async salvarPessoaAtendimento() {
        this.setState({ carregando: true, })
        let pessoaAtendimento = this.state.pessoaAtendimento;
        pessoaAtendimento.seqPessoa = this.state.pessoaUsuario.seqPessoa;
        pessoaAtendimento.seqUsuario = this.state.pessoaUsuario.seqUsuario;

        console.log(pessoaAtendimento)
        await this.pessoaService.salvarAtendimento(pessoaAtendimento);

        this.novoPessoaAtendimento();
        this.listarPessoaAtendimento();
        this.setState({ carregando: false, })
    }

    async listarPessoaRelacionamento(pPessoa) {
        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        let retornoListaPessoaRelacionamento = await this.pessoaService.listarRelacionamento(pessoaUsuario);
        this.setState({
            listaPessoaRelacionamento: retornoListaPessoaRelacionamento.objeto
        })
        console.log(retornoListaPessoaRelacionamento.objeto);
    }

    novoPessoaRelacionamento() {
        this.setState({
            pessoaRelacionamento: new PessoaRelacionamento(),
            tipoRelacionamento: new TipoRelacionamento(),
        })
    }

    async salvarPessoaRelacionamento() {
        this.setState({ carregando: true, })
        let pessoaRelacionamento = this.state.pessoaRelacionamento;
        pessoaRelacionamento.seqPessoa = this.state.pessoaUsuario.seqPessoa;
        pessoaRelacionamento.seqUsuario = this.state.pessoaUsuario.seqUsuario;

        console.log(pessoaRelacionamento)
        await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);

        this.novoPessoaRelacionamento();
        this.listarPessoaRelacionamento();
        this.setState({ carregando: false, })
    }

    alerta_deletarPessoaRelacionamento() {
        this.setState({
            popupDeletarPessoaRelacionamentoVisivel: true
        })
    }

    async deletarPessoaRelacionamento() {
        await this.pessoaService.deletarRelacionamento(this.state.pessoaRelacionamento);

        this.setState({
            pessoaRelacionamento: new PessoaRelacionamento(),
            popupDeletarPessoaRelacionamentoVisivel: false,
        })

        this.novoPessoaRelacionamento();
        this.listarPessoaRelacionamento();
    }

    selecionarPessoaRelacionamento(pPessoaRelacionamento) {
        let pessoaRelacionamento = pPessoaRelacionamento;
        this.setState({
            pessoaRelacionamento: pessoaRelacionamento,
        })
        this.buscarTipoRelacionamento(pessoaRelacionamento.seqTipoRelacionamento);
    }

    alerta_deletarPessoaAtendimento() {
        this.setState({
            popupDeletarPessoaAtendimentoVisivel: true
        })
    }

    async deletarPessoaAtendimento() {
        await this.pessoaService.deletarAtendimento(this.state.pessoaAtendimento);

        this.setState({
            pessoaAtendimento: new PessoaAtendimento(),
            popupDeletarPessoaAtendimentoVisivel: false,
        })

        this.novoPessoaAtendimento();
        this.listarPessoaAtendimento();
    }


    novoPessoaArquivo() {
        this.setState({
            pessoaArquivo: new PessoaArquivo(),
            tipoArquivo: new TipoArquivo(),
        })
    }


    async deletarPessoaArquivo() {
        await this.pessoaService.deletarArquivo(this.state.pessoaArquivo);

        this.setState({
            pessoaArquivo: new PessoaArquivo(),
            popupDeletarPessoaArquivoVisivel: false,
        })

        this.novoPessoaArquivo();
        this.listarPessoaArquivo();
    }


    async selecionarArquivo(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivo: {
                        ...this.state.pessoaArquivo,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivo: {
                    ...this.state.pessoaArquivo,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }

    buscarTipoArquivo(pSeqTipoArquivo) {
        let listaTipoArquivo = this.state.listaTipoArquivo;
        console.log(pSeqTipoArquivo)

        for (let i = 0; i < listaTipoArquivo.length; i++) {
            if (listaTipoArquivo[i].seqTipoArquivo === Number(pSeqTipoArquivo)) {
                this.setState({
                    tipoArquivo: listaTipoArquivo[i]
                })
                console.log(listaTipoArquivo[i])
            }
        }
    }

    async salvarPessoaArquivo() {
        this.setState({ carregando: true })
        this.pessoaArquivo = this.state.pessoaArquivo;
        this.pessoaArquivo.tipoArquivoNome = this.state.tipoArquivo.nome;
        this.pessoaArquivo.seqTipoArquivo = this.state.tipoArquivo.seqTipoArquivo;
        this.pessoaArquivo.seqPessoa = this.state.pessoaUsuario.seqPessoa;
        console.log(this.pessoaArquivo)
        await this.pessoaService.salvarArquivo(this.pessoaArquivo);

        this.setState({
            pessoaArquivo: new PessoaArquivo(),
            tipoArquivo: new TipoArquivo(),
            carregando: false,
        })

        this.listarPessoaArquivo()
    }

    async listarPessoaArquivo(pPessoa) {
        if (pPessoa) {
            this.pessoaUsuario = pPessoa;
        } else {
            this.pessoaUsuario = this.state.pessoaUsuario;
        }
        this.listaPessoaArquivo = await this.pessoaService.listarArquivo(this.pessoaUsuario);
        this.setState({ listaPessoaArquivo: this.listaPessoaArquivo.objeto });
        console.log(this.listaPessoaArquivo.objeto)
    }

    async selecionarPessoaPerfil(pItem) {
        if (pItem === null) {
            this.setState({
                pessoaPerfil: {
                    seqPessoaPerfil: 0,
                },
                listaPessoa: [],
            });
            return;
        }

        this.setState({
            pessoaPerfil: pItem,
            listaPessoa: [],
        })
    }

    async listarEnderecoGoogle(pTexto) {
        this.retorno = await this.googleAPI.listarEndereco(pTexto);
        console.log(this.retorno.predictions)
        this.setState({
            listaEnderecoGoogle: this.retorno.predictions
        })
        if (this.retorno.predictions.length === 1) {
            this.buscarEnderecoGoogle(this.retorno.predictions[0].place_id)
        }
    }

    async buscarEnderecoGoogle(pPlaceID) {
        console.log(pPlaceID)
        this.retorno = await this.googleAPI.buscarEndereco(pPlaceID);
        console.log(this.retorno)
    }

    render() {
        return (
            <>
                {/* {this.state.mudarTela && (
                    <Navigate to='/login' replace={true} />
                )} */}
                <Loading visivel={this.state.carregando} />
                {this.state.tela === '1' ?
                    <Container>
                        {/* {!this.state.usuario ? <Navigate to="/login" /> : null} */}
                        <Header selecionado="pessoa" />
                        <div className='sistema-wrapper-lista'>
                            <Sidebar bgi="bgi-pessoaUsuario">
                                <button
                                    onClick={() => this.selecionarPessoaPerfil(null)}
                                    className={`btn-lg ${this.state.pessoaPerfil.seqPessoaPerfil ===
                                        0
                                        ? "btn-com-fundo"
                                        : "btn-secundario"
                                        }`}
                                >Todos</button>
                                {this.state.listaPessoaPerfil.map(item => (
                                    <button
                                        key={item.seqPessoaPerfil}
                                        onClick={() => this.selecionarPessoaPerfil(item)}
                                        className={`btn-lg ${this.state.pessoaPerfil ===
                                            item
                                            ? "btn-com-fundo"
                                            : "btn-secundario"
                                            }`}
                                    >{item.nome}</button>
                                ))}
                            </Sidebar>
                            <div className='sistema-conteudo-wrapper'>
                                <section className='sistema-input-wrapper'>
                                    <h1>Pessoa</h1>

                                    <div className='sistema-form-e-botao-wrapper'>

                                        <section>
                                            <label>Pesquisa</label><br />
                                            <input
                                                type="text"
                                                onChange={pTexto => this.setState({ conteudo: pTexto.target.value })}
                                                value={this.state.conteudo}
                                            />
                                        </section>

                                        <div className='sistema-input-wrapper-botao-wrapper'>
                                            <button
                                                onClick={() => this.listar()}
                                                className='btn btn-com-fundo'
                                            >Buscar</button>
                                            <button
                                                onClick={() => this.novo()}
                                                className='btn btn-com-fundo'
                                            >Novo</button>
                                        </div>
                                    </div>
                                </section>

                                <table className='sistema-table'>
                                    <thead className='sistema-thead'>
                                        <tr className='sistema-tr-head'>
                                            <th>Nome</th>
                                            <th>Email</th>
                                            <th>Documento</th>
                                            <th>Perfil</th>
                                            <th>Opção</th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        className='sistema-tbody'
                                        style={{ maxHeight: 640 }}>
                                        {this.state.listaPessoa.map(item => (
                                            <tr key={item.seqPessoa} className='sistema-tr-body'>
                                                <td className='sistema-td'>{item.nome}</td>
                                                <td className='sistema-td'>{item.emailPlataformaLirida}</td>
                                                <td className='sistema-td'>{item.documento}</td>
                                                <td className='sistema-td'>{item.perfilNome}</td>
                                                <td className='sistema-td'>
                                                    <button
                                                        onClick={() => this.selecionar(item)}
                                                        className='btn btn-icone btn-com-fundo'>
                                                        <FaPen />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </Container>
                    :
                    this.state.tela === '2' ?
                        <>
                            <PopUp
                                visivel={this.state.popupDeletarPessoa}
                                msg={"Deseja realmente excluir o pessoaUsuario " + this.state.pessoaUsuario.nome + " ?"}
                                aoConfirmar={() => this.deletar()}
                                aoCancelar={() => this.setState({ popupDeletarPessoa: false })}
                            />

                            <PopUp
                                visivel={this.state.popupDeletarContatoVisivel}
                                msg={"Deseja realmente excluir o contato " + this.state.contato.tipoContatoNome + " ?"}
                                aoConfirmar={() => this.deletarContato()}
                                aoCancelar={() => this.setState({ popupDeletarContatoVisivel: false, })}
                            />

                            <PopUp
                                visivel={this.state.popupDeletarEnderecoVisivel}
                                msg={"Deseja realmente excluir o endereço " + this.state.endereco.logradouro + " ?"}
                                aoConfirmar={() => this.deletarEndereco()}
                                aoCancelar={() => this.setState({ popupDeletarEnderecoVisivel: false, })}
                            />

                            <PopUp
                                visivel={this.state.popupDeletarPessoaRelacionamentoVisivel}
                                msg={"Deseja realmente excluir o relacionamento com " + this.state.pessoaRelacionamento.pessoaRelacionadaNome + " ?"}
                                aoConfirmar={() => this.deletarPessoaRelacionamento()}
                                aoCancelar={() => this.setState({ popupDeletarPessoaRelacionamentoVisivel: false, })}
                            />

                            <PopUp
                                visivel={this.state.popupDeletarPessoaArquivoVisivel}
                                msg={"Deseja realmente excluir o arquivo " + this.state.pessoaArquivo.arquivoOriginal + " ?"}
                                aoConfirmar={() => this.deletarPessoaArquivo()}
                                aoCancelar={() => this.setState({ popupDeletarPessoaArquivoVisivel: false, })}
                            />

                            <PopUp
                                visivel={this.state.popupDeletarPessoaAtendimentoVisivel}
                                msg={"Deseja realmente excluir o atendimento " + this.state.pessoaAtendimento.tipoAtendimentoNome + " ?"}
                                aoConfirmar={() => this.deletarPessoaAtendimento()}
                                aoCancelar={() => this.setState({ popupDeletarPessoaAtendimentoVisivel: false, })}
                            />
                            <Container>
                                <div className='sistema-wrapper-detalhe'>

                                    <Header selecionado="pessoaUsuario" />

                                    <div
                                        className='sistema-input-wrapper'>
                                        <MsgOK
                                            visivel={this.state.msgOKVisivel}
                                            msg={this.state.mensagem}
                                        />
                                        <MsgError
                                            visivel={this.state.msgErrorVisivel}
                                            msg={this.state.mensagem}
                                        />
                                        <form onSubmit={(pSubmit) => this.salvar(pSubmit)}>
                                            <div className='sistema-detalhe-voltar'>
                                                <button
                                                    type="button"
                                                    onClick={() => this.setState({
                                                        tela: '1',
                                                        msgOKVisivel: false,
                                                        msgErrorVisivel: false,
                                                        carregando: false,
                                                    })}
                                                    className='btn btn-icone btn-com-fundo'>
                                                    <FaArrowLeft />
                                                </button>

                                                <h1>Pessoa</h1>

                                                <div className='sistema-detalhe-botao'>
                                                    {/* {this.state.pessoaUsuario.seqPessoa ?
                                                        <button
                                                            type="button"
                                                            onClick={() => this.alerta_popupDeletarPessoa()}
                                                            className='btn btn-excluir'
                                                        >Excluir</button> : null} */}
                                                    <button type="submit" className={'btn btn-com-fundo'}>
                                                        Salvar
                                                    </button>
                                                </div>

                                            </div>
                                            <div className='sistema-form-wrapper'>
                                                <Input
                                                    titulo="Nome"
                                                    type="text"
                                                    onChange={pTexto => this.setState({
                                                        pessoa: {
                                                            ...this.state.pessoa,
                                                            nome: pTexto.target.value
                                                        }
                                                    })}
                                                    value={this.state.pessoa.nome}
                                                    required
                                                />
                                                <Input
                                                    titulo="Email"
                                                    type="email"
                                                    onChange={pTexto => this.setState({
                                                        pessoa: {
                                                            ...this.state.pessoa,
                                                            emailPlataformaLirida: pTexto.target.value
                                                        }
                                                    })}
                                                    value={this.state.pessoa.emailPlataformaLirida}
                                                    required
                                                />
                                                <Input
                                                    titulo="Documento"
                                                    type="text"
                                                    onChange={pTexto => this.formatarCPFCNPJ(pTexto.target.value)}
                                                    value={this.state.pessoa.documento}
                                                    required
                                                />
                                                <div>
                                                    <label>Perfil</label><br />
                                                    <select
                                                        required
                                                        value={this.state.pessoa.seqPessoaPerfil}
                                                        onChange={pTexto => this.setState({
                                                            pessoa: {
                                                                ...this.state.pessoa,
                                                                seqPessoaPerfil: pTexto.target.value
                                                            }
                                                        })}
                                                        style={{ width: '100%' }}
                                                        className='input'>

                                                        {!this.state.pessoa.seqPessoaPerfil ? <option value={null} selected>Selecione um perfil</option> : null}

                                                        {/* {this.state.listaPessoaPerfil.map(item => (
                                                            <option key={item.seqPessoaPerfil} value={item.seqPessoaPerfil}>{item.nome}</option>
                                                        ))} */}
                                                        <option value={this.constante.seqPessoaPerfilPolo}>Polo</option>
                                                        <option value={this.constante.seqPessoaPerfilAdministrativo}>Administrativo</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* {this.state.pessoaUsuario.seqPessoa ?
                                    <div className='sistema-estrutura-pessoa'>
                                        <div className="sistema-sidebar-detalhe">
                                            <button
                                                onClick={() => this.setState({ tag: 'Enderecos' })}
                                                className={`btn btn-lg ${this.state.tag === 'Enderecos' ?
                                                    'btn-com-fundo' :
                                                    'btn-secundario'
                                                    }`}>Endereços</button>
                                            <button
                                                onClick={() => this.setState({ tag: 'Contatos' })}
                                                className={`btn btn-lg ${this.state.tag === 'Contatos' ?
                                                    'btn-com-fundo' :
                                                    'btn-secundario'
                                                    }`}>Contatos</button>
                                            <button
                                                onClick={() => this.setState({ tag: 'Caracteristicas' })}
                                                className={`btn btn-lg ${this.state.tag === 'Caracteristicas' ?
                                                    'btn-com-fundo' :
                                                    'btn-secundario'
                                                    }`}>Características</button>
                                            <button
                                                onClick={() => this.setState({ tag: 'Relacionamentos' })}
                                                className={`btn btn-lg ${this.state.tag === 'Relacionamentos' ?
                                                    'btn-com-fundo' :
                                                    'btn-secundario'
                                                    }`}>Relacionamentos</button>
                                            <button
                                                onClick={() => this.setState({ tag: 'Arquivos_anexados' })}
                                                className={`btn btn-lg ${this.state.tag === 'Arquivos_anexados' ?
                                                    'btn-com-fundo' :
                                                    'btn-secundario'
                                                    }`}>Arquivos anexados</button>
                                            <button
                                                onClick={() => this.setState({ tag: 'Atendimento' })}
                                                className={`btn btn-lg ${this.state.tag === 'Atendimento' ?
                                                    'btn-com-fundo' :
                                                    'btn-secundario'
                                                    }`}>Atendimento</button>
                                        </div>

                                        <div className='sistema-campo-detalhe'>

                                            {this.state.tag === 'Enderecos' ?
                                                <>
                                                    <div
                                                        className='sistema-campo-detalhe-form'>
                                                        <div className='sistema-campo-detalhe-input-wrapper'>
                                                            <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                                                                <Input
                                                                    style={{ width: '100%' }}
                                                                    titulo="Cep"
                                                                    value={this.state.endereco.cep || ''}
                                                                    onChange={pTexto => this.setState({
                                                                        endereco: {
                                                                            ...this.state.endereco,
                                                                            cep: pTexto.target.value
                                                                        }
                                                                    })}
                                                                />

                                                                <button
                                                                    onClick={() => this.buscarCep()}
                                                                    className='btn btn-com-fundo' style={{ marginTop: 'auto' }}>
                                                                    <FaSearch size={20} color="white" />
                                                                </button>
                                                            </div>
                                                            <div>
                                                                <label>Tipo de endereço</label><br />
                                                                <select
                                                                    style={{ width: '100%' }}
                                                                    value={this.state.endereco.seqTipoEndereco}
                                                                    onChange={pEvento => this.setState({
                                                                        endereco: {
                                                                            ...this.state.endereco,
                                                                            seqTipoEndereco: pEvento.target.value
                                                                        }
                                                                    })}
                                                                    className='input'>

                                                                    <option selected={this.state.endereco.seqTipoEndereco === undefined ?
                                                                        true : false} value={null}>Selecione um tipo de endereco</option>

                                                                    {this.state.listaTipoEndereco.map((item) => (
                                                                        <option
                                                                            key={item.seqTipoEndereco}
                                                                            selected={
                                                                                this.state.endereco.seqTipoEndereco === item.seqTipoEndereco ?
                                                                                    true : false
                                                                            }
                                                                            value={item.seqTipoEndereco}>{item.nome}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <Input
                                                                style={{ width: '100%' }}
                                                                titulo="Logradouro"
                                                                onChange={pTexto => this.setState({
                                                                    endereco: {
                                                                        ...this.state.endereco,
                                                                        logradouro: pTexto.target.value
                                                                    }
                                                                })}
                                                                value={this.state.endereco.logradouro || ''}
                                                            />
                                                            <Input
                                                                style={{ width: '100%' }}
                                                                titulo="Número"
                                                                onChange={pTexto => this.setState({
                                                                    endereco: {
                                                                        ...this.state.endereco,
                                                                        numero: pTexto.target.value
                                                                    }
                                                                })}
                                                                value={this.state.endereco.numero || ''}
                                                            />
                                                            <Input
                                                                style={{ width: '100%' }}
                                                                titulo="Bairro"
                                                                onChange={pTexto => this.setState({
                                                                    endereco: {
                                                                        ...this.state.endereco,
                                                                        bairro: pTexto.target.value
                                                                    }
                                                                })}
                                                                value={this.state.endereco.bairro || ''}
                                                            />
                                                            <Input
                                                                style={{ width: '100%' }}
                                                                titulo="Cidade"
                                                                onChange={pTexto => this.setState({
                                                                    endereco: {
                                                                        ...this.state.endereco,
                                                                        cidade: pTexto.target.value
                                                                    }
                                                                })}
                                                                value={this.state.endereco.cidade || ''}
                                                            />
                                                            <Input
                                                                style={{ width: '100%' }}
                                                                titulo="Complemento"
                                                                onChange={pTexto => this.setState({
                                                                    endereco: {
                                                                        ...this.state.endereco,
                                                                        complemento: pTexto.target.value
                                                                    }
                                                                })}
                                                                value={this.state.endereco.complemento || ''}
                                                            />
                                                            <Input
                                                                style={{ width: '100%' }}
                                                                titulo="uf"
                                                                onChange={pTexto => this.setState({
                                                                    endereco: {
                                                                        ...this.state.endereco,
                                                                        uf: pTexto.target.value
                                                                    }
                                                                })}
                                                                value={this.state.endereco.uf || ''}
                                                            />

                                                            <Input
                                                                style={{ width: '100%' }}
                                                                titulo="Pesquisa de endereço digitando"
                                                                onChange={pTexto => this.listarEnderecoGoogle(pTexto.target.value)}
                                                                list="listagoogle"
                                                            />
                                                            <datalist id="listagoogle">
                                                                {this.state.listaEnderecoGoogle.map(item => <option value={item.description}>{item.description}</option>)}
                                                            </datalist>

                                                        </div>
                                                        <div className='sistema-campo-detalhe-button-wrapper'>
                                                            {this.state.endereco.seqEndereco ?
                                                                <button
                                                                    onClick={() => this.alerta_deletarEndereco()}
                                                                    className='btn btn-secundario'
                                                                    style={{
                                                                        borderColor: 'red',
                                                                        color: 'red'
                                                                    }}
                                                                >
                                                                    Excluir
                                                                </button>
                                                                : null}
                                                            {this.state.endereco.seqEndereco ? <button
                                                                onClick={() => this.novoEndereco()}
                                                                className='btn btn-secundario'>Cancelar</button> : null}

                                                            <button
                                                                onClick={() => this.salvarEndereco()}
                                                                className='btn btn-com-fundo'>Salvar</button>
                                                        </div>
                                                    </div>

                                                    <table className='sistema-table'>
                                                        <thead className='sistema-thead'>
                                                            <tr className='sistema-tr-head'>
                                                                <th>Logradouro</th>
                                                                <th>Número</th>
                                                                <th>Bairro</th>
                                                                <th>Cidade</th>
                                                                <th>Cep</th>
                                                                <th>UF</th>
                                                                <th>Ação</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            className='sistema-tbody'
                                                            style={{ maxHeight: 480 }}>
                                                            {this.state.listaEndereco.map(item => (
                                                                <tr key={item.seqEndereco} className='sistema-tr-body'>
                                                                    <td className='sistema-td'>{item.logradouro}</td>
                                                                    <td className='sistema-td'>{item.numero}</td>
                                                                    <td className='sistema-td'>{item.bairro}</td>
                                                                    <td className='sistema-td'>{item.cidade}</td>
                                                                    <td className='sistema-td'>{item.cep}</td>
                                                                    <td className='sistema-td'>{item.uf}</td>
                                                                    <td className='sistema-td'>
                                                                        <button
                                                                            onClick={() => this.selecionarEndereco(item)}
                                                                            className='btn btn-icone btn-com-fundo'>
                                                                            <FaPen />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </>
                                                : null}

                                            {this.state.tag === 'Contatos' ?
                                                <>
                                                    <div
                                                        className='sistema-campo-detalhe-form'>
                                                        <div className='sistema-campo-detalhe-input-wrapper-pessoaUsuario'>
                                                            <div>
                                                                <label>Tipo de contato</label><br />
                                                                <select
                                                                    style={{ width: '100%' }}
                                                                    onChange={pEvento => this.setState({
                                                                        contato: {
                                                                            ...this.state.contato,
                                                                            seqTipoContato: pEvento.target.value
                                                                        }
                                                                    })}
                                                                    className='input'>

                                                                    <option selected={this.state.contato.seqTipoContato === undefined ?
                                                                        true : false} value={null}>Selecione um tipo de contato</option>

                                                                    {this.state.listaTipoContato.map((item) => (
                                                                        <option
                                                                            key={item.seqTipoContato}
                                                                            selected={
                                                                                this.state.contato.seqTipoContato === item.seqTipoContato ?
                                                                                    true : false
                                                                            }
                                                                            value={item.seqTipoContato}>{item.nome}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <Input
                                                                style={{ width: '100%' }}
                                                                titulo="Contato"
                                                                onChange={pTexto => this.setState({
                                                                    contato: {
                                                                        ...this.state.contato,
                                                                        contato: pTexto.target.value
                                                                    }
                                                                })}
                                                                value={this.state.contato.contato || ''}
                                                            />
                                                        </div>
                                                        <div className='sistema-campo-detalhe-button-wrapper'>
                                                            {this.state.contato.seqContato ?
                                                                <button
                                                                    onClick={() => this.alerta_deletarContato()}
                                                                    className='btn btn-secundario'
                                                                    style={{
                                                                        borderColor: 'red',
                                                                        color: 'red'
                                                                    }}
                                                                >
                                                                    Excluir
                                                                </button>
                                                                : null}
                                                            {this.state.contato.seqContato ? <button
                                                                onClick={() => this.novoContato()}
                                                                className='btn btn-secundario'>Cancelar</button> : null}

                                                            <button
                                                                onClick={() => this.salvarContato()}
                                                                className='btn btn-com-fundo'>Salvar</button>
                                                        </div>
                                                    </div>

                                                    <table className='sistema-table'>
                                                        <thead className='sistema-thead'>
                                                            <tr className='sistema-tr-head'>
                                                                <th>tipo de contato</th>
                                                                <th>contato</th>
                                                                <th>Ação</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            className='sistema-tbody'
                                                            style={{ maxHeight: 480 }}>
                                                            {this.state.listaContato.map(item => (
                                                                <tr key={item.seqContato} className='sistema-tr-body'>
                                                                    <td className='sistema-td'>{item.tipoContatoNome}</td>
                                                                    <td className='sistema-td'>{item.contato}</td>
                                                                    <td className='sistema-td'>
                                                                        <button
                                                                            onClick={() => this.selecionarContato(item)}
                                                                            className='btn btn-icone btn-com-fundo'>
                                                                            <FaPen />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </>
                                                : null}

                                            {this.state.tag === 'Caracteristicas' ?
                                                <div
                                                    className='sistema-campo-detalhe-form'>
                                                    <div className='sistema-campo-detalhe-input-wrapper'>
                                                        {this.state.listaPessoaCaracteristica.map((item, index) => (
                                                            <div key={item.seqPessoaCaracteristica} style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                                <input
                                                                    onChange={() => this.marcarDesmarcarCaracteristica(index)}
                                                                    checked={item.marcado === "true" ? true : false}
                                                                    type="checkbox"
                                                                    style={{ width: 15, height: 15 }} />
                                                                <label>{item.nome}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <button
                                                        onClick={() => this.salvarCaracteristica()}
                                                        className='btn btn-com-fundo'>Salvar</button>
                                                </div>
                                                : null}

                                            {this.state.tag === 'Relacionamentos' ?
                                                <>
                                                    <div
                                                        className='sistema-campo-detalhe-form'>
                                                        <div className='sistema-campo-detalhe-input-wrapper-pessoaUsuario'>
                                                            <div>
                                                                <label>Pessoa</label><br />

                                                                <select
                                                                    style={{ width: '100%' }}
                                                                    value={this.state.pessoaRelacionamento.seqPessoaRelacionada}
                                                                    onChange={pEvento => this.setState({
                                                                        pessoaRelacionamento: {
                                                                            ...this.state.pessoaRelacionamento,
                                                                            seqPessoaRelacionada: Number(pEvento.target.value)
                                                                        }
                                                                    })}
                                                                    className='input'>

                                                                    <option selected={this.state.pessoaRelacionamento.seqPessoaRelacionada === undefined ?
                                                                        true : false} value={null}>Selecione uma pessoaUsuario</option>

                                                                    {this.state.listaPessoaDoRelacionamento.map((item) => (
                                                                        <option
                                                                            key={item.seqPessoaRelacionada}
                                                                            selected={
                                                                                this.state.pessoaRelacionamento.seqPessoaRelacionada === item.seqPessoa ?
                                                                                    true : false
                                                                            }
                                                                            value={item.seqPessoa}>{item.nome}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <label>Tipo de relacionamento</label><br />

                                                                <select
                                                                    style={{ width: '100%' }}
                                                                    value={this.state.tipoRelacionamento.seqTipoRelacionamento}
                                                                    onChange={pEvento => this.setState({
                                                                        pessoaRelacionamento: {
                                                                            ...this.state.pessoaRelacionamento,
                                                                            seqTipoRelacionamento: Number(pEvento.target.value)
                                                                        }
                                                                    })}
                                                                    className='input'>

                                                                    <option selected={this.state.tipoRelacionamento.seqTipoRelacionamento === undefined ?
                                                                        true : false} value={null}>Selecione um tipo de relacionamento</option>

                                                                    {this.state.listaTipoRelacionamento.map((item) => (
                                                                        <option
                                                                            key={item.seqTipoRelacionamento}
                                                                            selected={
                                                                                this.state.tipoRelacionamento.seqTipoRelacionamento === item.seqTipoRelacionamento ?
                                                                                    true : false
                                                                            }
                                                                            value={item.seqTipoRelacionamento}>{item.nome}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div>
                                                                <label>Situação</label><br />
                                                                <select
                                                                    value={this.state.pessoaRelacionamento.situacao}
                                                                    onChange={pEvento => this.setState({
                                                                        pessoaRelacionamento: {
                                                                            ...this.state.pessoaRelacionamento,
                                                                            situacao: pEvento.target.value
                                                                        }
                                                                    })}
                                                                    style={{ width: '100%' }}
                                                                    className='input'>
                                                                    <option value="ATIVO">ATIVO</option>
                                                                    <option value="INATIVO">INATIVO</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div className='sistema-campo-detalhe-button-wrapper'>
                                                            {this.state.pessoaRelacionamento.seqPessoaRelacionamento ?
                                                                <button
                                                                    onClick={() => this.alerta_deletarPessoaRelacionamento()}
                                                                    className='btn btn-secundario'
                                                                    style={{
                                                                        borderColor: 'red',
                                                                        color: 'red'
                                                                    }}
                                                                >
                                                                    Excluir
                                                                </button>
                                                                : null}
                                                            {this.state.pessoaRelacionamento.seqPessoaRelacionamento ? <button
                                                                onClick={() => this.novoPessoaRelacionamento()}
                                                                className='btn btn-secundario'>Cancelar</button> : null}

                                                            <button
                                                                onClick={() => this.salvarPessoaRelacionamento()}
                                                                className='btn btn-com-fundo'>Salvar</button>
                                                        </div>
                                                    </div>

                                                    <table className='sistema-table'>
                                                        <thead className='sistema-thead'>
                                                            <tr className='sistema-tr-head'>
                                                                <th>Pessoa</th>
                                                                <th>Tipo de Relacionamento</th>
                                                                <th>Situação</th>
                                                                <th>Dt. Cadastro</th>
                                                                <th>Detalhe</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            className='sistema-tbody'
                                                            style={{ maxHeight: 480 }}>
                                                            {this.state.listaPessoaRelacionamento.map(item => (
                                                                <tr key={item.seqPessoaRelacionamento} className='sistema-tr-body'>
                                                                    <td className='sistema-td'>{item.pessoaRelacionadaNome}</td>
                                                                    <td className='sistema-td'>{item.tipoRelacionamentoNome}</td>
                                                                    <td className='sistema-td'>{item.situacao}</td>
                                                                    <td className='sistema-td'>{item.dataCadastro}</td>
                                                                    <td className='sistema-td'>
                                                                        <button
                                                                            onClick={() => this.selecionarPessoaRelacionamento(item)}
                                                                            className='btn btn-icone btn-com-fundo'>
                                                                            <FaPen />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </>
                                                : null}

                                            {this.state.tag === 'Arquivos_anexados' ?
                                                <>

                                                    <div
                                                        className='sistema-campo-detalhe-form'>
                                                        <div className='sistema-campo-detalhe-input-wrapper-pessoaUsuario'>
                                                            <div>
                                                                <label>Tipo de arquivo</label><br />
                                                                <select
                                                                    style={{ width: '100%' }}
                                                                    value={this.state.tipoArquivo.seqTipoArquivo}
                                                                    onChange={pTexto => this.buscarTipoArquivo(pTexto.target.value)}
                                                                    className='input'>

                                                                    <option selected={this.state.tipoArquivo.seqTipoArquivo === undefined ?
                                                                        true : false} value={null}>Selecione um tipo de arquivo</option>

                                                                    {this.state.listaTipoArquivo.map((item) => (
                                                                        <option
                                                                            key={item.seqTipoArquivo}
                                                                            selected={
                                                                                this.state.tipoArquivo.seqTipoArquivo === item.seqTipoArquivo ?
                                                                                    true : false
                                                                            }
                                                                            value={item.seqTipoArquivo}>{item.nome}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <Input
                                                                titulo="Selecione o arquivo"
                                                                onChange={pEvento => this.selecionarArquivo(pEvento)}
                                                                type="file"
                                                            />
                                                        </div>

                                                        <button className='btn btn-com-fundo' onClick={() => this.salvarPessoaArquivo()}>Salvar</button>
                                                    </div>
                                                    <table className='sistema-table'>
                                                        <thead className='sistema-thead'>
                                                            <tr className='sistema-tr-head'>
                                                                <th>Nome</th>
                                                                <th>Tipo de Arquivo</th>
                                                                <th>Dt. Cadastro</th>
                                                                <th>Ação</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            className='sistema-tbody'
                                                            style={{ maxHeight: 440 }}>
                                                            {this.state.listaPessoaArquivo.map(item => (
                                                                <tr key={item.seqPessoaArquivo} className='sistema-tr-body'>
                                                                    <td className='sistema-td'><a href={this.state.urlBaseUrlArquivoPessoa + item.arquivo} target="_blank" rel="noreferrer">{item.arquivoOriginal}</a></td>
                                                                    <td className='sistema-td'>{item.tipoArquivoNome}</td>
                                                                    <td className='sistema-td'>{item.dataCadastro}</td>
                                                                    <td className='sistema-td'>
                                                                        <button
                                                                            onClick={() => this.alerta_deletarPessoaArquivo(item)}
                                                                            className='btn btn-icone btn-com-fundo'>
                                                                            <FaTrash />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </>
                                                : null}

                                            {this.state.tag === 'Atendimento' ?
                                                <>
                                                    <div
                                                        className='sistema-campo-detalhe-form'>
                                                        <div className='sistema-campo-detalhe-input-wrapper-atendimento'>
                                                            <div>
                                                                <label>Tipo de atendimento</label><br />

                                                                <select
                                                                    style={{ width: '100%' }}
                                                                    value={this.state.pessoaAtendimento.seqTipoAtendimento}
                                                                    onChange={pEvento => this.setState({
                                                                        pessoaAtendimento: {
                                                                            ...this.state.pessoaAtendimento,
                                                                            seqTipoAtendimento: Number(pEvento.target.value)
                                                                        }
                                                                    })}
                                                                    className='input'>

                                                                    <option selected={this.state.pessoaAtendimento.seqTipoAtendimento === undefined ?
                                                                        true : false} value={null}>Selecione um tipo de atendimento</option>

                                                                    {this.state.listaTipoAtendimento.map((item) => (
                                                                        <option
                                                                            key={item.seqTipoAtendimento}
                                                                            selected={
                                                                                this.state.tipoAtendimento.seqTipoAtendimento === item.seqTipoAtendimento ?
                                                                                    true : false
                                                                            }
                                                                            value={item.seqTipoAtendimento}>{item.nome}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div>
                                                                <label>Situação</label><br />
                                                                <select
                                                                    value={this.state.pessoaAtendimento.situacao}
                                                                    onChange={pEvento => this.setState({
                                                                        pessoaAtendimento: {
                                                                            ...this.state.pessoaAtendimento,
                                                                            situacao: pEvento.target.value
                                                                        }
                                                                    })}
                                                                    style={{ width: '100%' }}
                                                                    className='input'>
                                                                    <option value="ATIVO">ATIVO</option>
                                                                    <option value="INATIVO">INATIVO</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label>Descrição</label><br />
                                                            <textarea
                                                                style={{ width: '100%', height: 100 }}
                                                                className="input"
                                                                onChange={pTexto => this.setState({
                                                                    pessoaAtendimento: {
                                                                        ...this.state.pessoaAtendimento,
                                                                        descricao: pTexto.target.value
                                                                    }
                                                                })}
                                                                value={this.state.pessoaAtendimento.descricao || ''}
                                                            />
                                                        </div>
                                                        <div className='sistema-campo-detalhe-button-wrapper'>
                                                            {this.state.pessoaAtendimento.seqPessoaAtendimento ?
                                                                <button
                                                                    onClick={() => this.alerta_deletarPessoaAtendimento()}
                                                                    className='btn btn-secundario'
                                                                    style={{
                                                                        borderColor: 'red',
                                                                        color: 'red'
                                                                    }}
                                                                >
                                                                    Excluir
                                                                </button>
                                                                : null}
                                                            {this.state.pessoaAtendimento.seqPessoaAtendimento ? <button
                                                                onClick={() => this.novoPessoaAtendimento()}
                                                                className='btn btn-secundario'>Cancelar</button> : null}

                                                            <button
                                                                onClick={() => this.salvarPessoaAtendimento()}
                                                                className='btn btn-com-fundo'>Salvar</button>
                                                        </div>
                                                    </div>

                                                    <table className='sistema-table'>
                                                        <thead className='sistema-thead'>
                                                            <tr className='sistema-tr-head'>
                                                                <th>Tipo de Atendimento</th>
                                                                <th>Descricao</th>
                                                                <th>Dt. Cadastro</th>
                                                                <th>Detalhe</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            className='sistema-tbody'
                                                            style={{ maxHeight: 480 }}>
                                                            {this.state.listaPessoaAtendimento.map(item => (
                                                                <tr key={item.seqPessoaAtendimento} className='sistema-tr-body'>
                                                                    <td className='sistema-td'>{item.tipoAtendimentoNome}</td>
                                                                    <td className='sistema-td'>{item.descricao}</td>
                                                                    <td className='sistema-td'>{item.dataCadastro}</td>
                                                                    <td className='sistema-td'>
                                                                        <button
                                                                            onClick={() => this.selecionarPessoaAtendimento(item)}
                                                                            className='btn btn-icone btn-com-fundo'>
                                                                            <FaPen />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </>
                                                : null}
                                        </div>
                                    </div>
                                    : null} */}
                            </Container>
                        </>
                        : null}
            </>
        )
    }
}