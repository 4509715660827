import React, { Component } from 'react';
import '../styleCertificados.css';
import Util from '../../../util/Util';
import Constante from '../../../util/Constante';
import DocumentoService from 'lirida-main-js-service/service/Documento/DocumentoService';
import Documento from 'lirida-main-js-service/service/Documento/Documento';


export default class CertificadoTecnico extends Component {
    util = new Util();
    documentoService = new DocumentoService();
    constante = new Constante();

    state = {
        tela: 1,
        documento: new Documento(),
        listaDocumentoItem: [],
        mesConclusao: '',
        dataConclusao: [],
        dataNascimento: [],
        mesNascimento: ''
    }


    async componentDidMount() {
        this.iniciar();

    }

    async iniciar() {
        let parametros = this.util.buscarParametrosUrl();
        console.log(parametros);
        let retornoDocumento = await this.documentoService.buscar(this.constante.seqUsuario, parametros?.seq_documento);
        this.setState({
            documento: retornoDocumento.objeto
        })
        console.log(this.state.documento);
        this.listarDocumentoItem();
        let data = this.formatarDataConclusao();
        this.retornaSwitchDataConclusao(data);
        let dataNascimento = this.formatarDataNascimento();
        this.retornaSwitchDataNascimento(dataNascimento);
    }


    async listarDocumentoItem(pDocumento) {
        let documento;

        if (pDocumento) {
            documento = pDocumento;
        } else {
            documento = this.state.documento;
        }

        let retornoDocumentoItem = await this.documentoService.listarDocumentoItem(
            documento
        );
        this.setState({ listaDocumentoItem: retornoDocumentoItem.objeto });
        console.log(retornoDocumentoItem);
    }

    formatarDataConclusao() {
        this.data = String(this.state.documento.tagd1);
        this.data = this.data.split('T')[0]
        this.data = this.data.split('-').reverse().join('-')
        this.setState({
            documento: {
                ...this.state.documento, tagd1: this.data
            }
        })
        return this.data;
    }

    formatarDataNascimento() {
        this.data = String(this.state.documento.tagd0);
        this.data = this.data.split('T')[0]
        this.data = this.data.split('-').reverse().join('-')
        this.setState({
            documento: {
                ...this.state.documento, tagd0: this.data
            }
        })
        return this.data;
    }


    retornaSwitchDataConclusao(pData) {
        this.data = String(pData);
        this.data = this.data.split('-');
        this.setState({ dataConclusao: this.data });
        this.mes = this.data[1];
        console.log(this.mes);
        switch (this.mes) {
            case '01':
                return this.setState({ mesConclusao: 'JANEIRO' })

            case '02':
                return this.setState({ mesConclusao: 'FEVEREIRO' })

            case '03':
                return 'MARÇO'

            case '04':
                return this.setState({ mesConclusao: 'ABRIL' })

            case '05':
                return this.setState({ mesConclusao: 'MAIO' })

            case '06':
                return this.setState({ mesConclusao: 'JUNHO' })

            case '07':
                return this.setState({ mesConclusao: 'JULHO' })

            case '08':
                return this.setState({ mesConclusao: 'AGOSTO' })

            case '09':
                return this.setState({ mesConclusao: 'SETEMBRO' })

            case '10':
                return this.setState({ mesConclusao: 'OUTUBRO' })


            case '11':
                return this.setState({ mesConclusao: 'NOVEMBRO' })

            case '12':
                return this.setState({ mesConclusao: 'DEZEMBRO' })
        }
    }


    retornaSwitchDataNascimento(pData) {
        this.data = String(pData);
        this.data = this.data.split('-');
        this.setState({ dataNascimento: this.data });
        this.mes = this.data[1];
        switch (this.mes) {
            case '01':
                return this.setState({ mesNascimento: 'JANEIRO' })

            case '02':
                return this.setState({ mesNascimento: 'FEVEREIRO' })

            case '03':
                return this.setState({ mesNascimento: 'MARÇO' })

            case '04':
                return this.setState({ mesNascimento: 'ABRIL' })

            case '05':
                return this.setState({ mesNascimento: 'MAIO' })

            case '06':
                return this.setState({ mesNascimento: 'JUNHO' })

            case '07':
                return this.setState({ mesNascimento: 'JULHO' })

            case '08':
                return this.setState({ mesNascimento: 'AGOSTO' })

            case '09':
                return this.setState({ mesNascimento: 'SETEMBRO' })

            case '10':
                return this.setState({ mesNascimento: 'OUTUBRO' })


            case '11':
                return this.setState({ mesNascimento: 'NOVEMBRO' })

            case '12':
                return this.setState({ mesNascimento: 'DEZEMBRO' })
        }
    }

    render() {
        return (
            <main className="ctd-container-geral">
                {this.state.tela === 1 ?
                    <>
                        <article className="ctd-ensino-medio-container-conteudo">
                            <div className="ctd-ensino-medio-div-texto-titulo">
                                <h2>PARECER Nº 001.MV.01055019/SEEDUC/COGIE/2019</h2>
                                <h2>PROCESSO Nº E-03/016/2194/2019</h2>
                                <h1>Diploma</h1>
                            </div>
                            <div className="ctd-ensino-medio-div-texto-titulo">
                                <p>A  DIREÇÃO  DO  COLÉGIO  EL CURSOS SHADAY,  NOS  TERMOS  DO ARTIGO</p>
                                <p>Nº 24, INCISO VII DA LEI 9.394, DE 20 DE DEZEMBRO DE 1996, CONFERE O PRESENTE CERTIFICADO A:</p>
                            </div>

                            <section className="ctd-ensino-medio-div-container-nome-aluno">
                                <div className="ctd-ensino-medio-div-nome-aluno">
                                    <h1>{this.state.documento.pessoaNome}</h1>
                                </div>

                                <div className="ctd-ensino-medio-container-conteudo-dados">
                                    <p>IDENTIDADE N.º
                                        <strong>
                                            {this.state.documento.tags2 + ' '}
                                        </strong>
                                        ORGÃO EMISSOR
                                        <strong>{this.state.documento.tags3}, </strong>
                                        NACIONALIDADE
                                        <strong>{this.state.documento.tags4}, </strong>
                                        NATURAL DE<strong>{this.state.documento.tags5}</strong>, UF<strong>{this.state.documento.endUF}, </strong>
                                        NASCIDO (A) EM <strong>{this.state.dataNascimento[2]}</strong> DE<strong>{this.state.mesNascimento}</strong> de <strong>{this.state.dataNascimento[0]}</strong>, POR TER CONCLUÍDO EM<strong>{this.state.dataConclusao[2]}</strong> DE<strong>{this.state.mesConclusao}</strong> DE<strong>{this.state.dataConclusao[0]}</strong>, O CURSO DE{this.state.listaDocumentoItem.map((item) => <strong>{item.itemNomeVitrine}, </strong>)}
                                        Da Educação Profissional Técnica de Nível Médio.
                                    </p>
                                </div>
                            </section>

                            <section className="ctd-ensino-medio-container-data">
                                <p>{this.state.documento.tags8}</p>
                                <p>DE</p>
                                <p>DE {this.state.documento.tags7}</p>
                            </section>

                            <section className="ctd-ensino-medio-container-assinaturas">
                                <div className="ctd-ensino-medio-box-assinatura">
                                    <div className="ctd-ensino-medio-linha-assinatura">
                                        <p>Diretor (a)</p>
                                    </div>
                                </div>
                                <div className="ctd-ensino-medio-box-assinatura">
                                    <div className="ctd-ensino-medio-linha-assinatura">
                                        <p>Concluinte (a)</p>
                                    </div>
                                </div>
                                <div className="ctd-ensino-medio-box-assinatura">
                                    <div className="ctd-ensino-medio-linha-assinatura">
                                        <p>Secretario (a)</p>
                                    </div>
                                </div>
                            </section>
                        </article>
                    </>
                    :
                    <div className="verso-ctd-tecnico-container">

                        <div className="verso-ctd-linha-header1">
                            <div className="verso-ctd-campo-header-texto">
                                <h1 className="verso-ctd-titulo-menor">DISCIPLINAS E CARGA HORÁRIA</h1>
                                <h1 className="verso-ctd-titulo-maior">CONTEÚDO PROGRAMÁTICO</h1>
                            </div><br />
                            {this.state.listaDocumentoItem.map((item) => (
                                <h1 className="verso-ctd-titulo-medio">CURSO: {item.itemDescricaoVitrine}</h1>
                            ))}
                        </div>


                        <div className="verso-ctd-linha-header2">
                            <div className="verso-ctd-campo-header-texto">
                                <h1 className="verso-ctd-titulo-maior">CURSO ANTERIOR E ANO DE CONCLUSÃO</h1><br />
                                <h1 className="verso-ctd-titulo-medio">ENSINO MÉDIO / 2006</h1>
                            </div>
                        </div>

                        <div className="verso-ctd-linha-header3">

                            <div className="verso-ctd-campo-header-texto-documento">
                                <h1 className="verso-ctd-titulo-menor">Documento emitido conforme previsto na deliberação CEE RJ nº 357 de 27 de Julho de 2016, publicada no D.O de 15 de Agosto de 2016.</h1>
                            </div>
                        </div>

                        <div className="verso-ctd-linha-materia-nota">
                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Língua P, Leitura e Produção de Texto</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Matemática</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Inglês Instrumental</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Segurança do Trabalho</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Desenho (CAD)</p>
                                <p className="verso-ctd-texto-nota-materia">60</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Matérias de Construção</p>
                                <p className="verso-ctd-texto-nota-materia">60</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Desenho Mecânico</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Eletro - Eletrônica</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Sistema térmicos</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Sistema Hidro-Pneumáticos</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- CPL</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Tecnologia Mecânica</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Resistência dos materiais</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Instrumentação</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>

                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Motores de combustão interna</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>
                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Manuntenção</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>
                            <div className="verso-ctd-box-texto-materia">
                                <p className="verso-ctd-texto-materia">- Projeto final</p>
                                <p className="verso-ctd-texto-nota-materia">40</p>
                            </div>
                        </div>
                        <div className="verso-ctd-linha-estabelecimento">
                            <h1 className="verso-ctd-titulo-maior">ESTABELECIMENTO</h1><br />
                            <p>CENTRO E.S PROFESSORA MARIA DIAS</p>
                        </div>

                        <div className="verso-ctd-linha-documento-sem-borda">
                            <div className="box-documento-texto">
                                <p>O PRESENTE DOCUMENTO FOI REGISTRADO SOB O Nº ______ EM FOLHAS ______ DO LIVRO Nº ______ DESTE ESTABELECIMENTO DE ENSINO</p>
                            </div>

                            <div className="box-linha">
                                <p>______________</p>
                            </div>

                            <div className="box-documento-footer">
                                <p>CONSELHO PROFISSIONAL DA ÁREA</p>
                                <h1>CADASTRO DO SISTEC</h1>
                                <h1>47074</h1>
                            </div>
                        </div>


                        <div className="verso-ctd-linha-observacoes">
                            <h1 className="verso-ctd-titulo-maior">Observações</h1>
                            <div className="box-documento-texto">
                                <p className="verso-ctd-titulo-menor">- Curso de educação profissional em 1 ano.</p>
                                <p className="verso-ctd-titulo-menor">- Estágio supervisionado realizado na empresa MASALUPRI ENGENHARIA, no período de 01/03/2021 à 14/06/2021</p>
                            </div>
                        </div>

                        <div className="verso-ctd-linha-localidade">
                            <h1 className="verso-ctd-titulo-maior">Localidade e unidade de ferederação</h1><br />
                            <p>CASIMIRO DE ABREU - RJ</p>
                        </div>

                        <div className="verso-ctd-linha-perfil-profissional">
                            <h1>PERFIL PROFISSIONAL</h1>
                            <br />
                            <div className="box-texto-perfil-profissional">
                                <p>-  ATUA NA ELABORAÇÃO DE PROJETOS DE PRODUTOS, FERRAMENTAS, MÁQUINAS E EQUIPAMENTOS</p>
                                <p>- PLANEJA, APLICA E CONTROLA PROCEDIMENTOS DE INSTALAÇÃO E DE MANUNTEÇÃO MECÂNICA DE MAQUINAS E EQUIPAMENTOS CONFOMRE NORMAS TÉCNICAS E NORMAS RELACIONADAS À SEGURANÇA.</p>
                                <p>- CONTROLA PROCESSOS DE FABRICAÇÃO</p>
                                <p>- APLICA TÉCNICAS DE MEDIÇÃO E ENSAIOS.</p>
                                <p>- ESPECIFICA MATERIAIS PARA CONSTRUÇÃO </p>
                            </div>
                        </div>

                        <div className="verso-ctd-linha-estagio">
                            <div className="verso-ctd-box-texto-estagio">
                                <h1 className="verso-ctd-texto-estagio">ESTAGIO SUPERVISIONADO</h1>
                                <h1 className="verso-ctd-texto-nota-estagio">468</h1>
                            </div>
                        </div>

                        <div className="verso-ctd-linha-total-geral">
                            <div className="verso-ctd-box-texto-estagio">
                                <h1 className="verso-ctd-texto-estagio">TOTAL GERADO</h1>
                                <h1 className="verso-ctd-texto-nota-estagio">1708</h1>
                            </div>
                        </div>
                    </div>
                }

            </main>
        )
    }
}