import React, { Component } from 'react';

export default class VersoHistoricoEscolarEm extends Component {
    render() {
        return (
            <main className='ctd-container-geral'>
                <div style={{ maxWidth: '80vw' }}>
                    <p className='texto-medio'>OBSERVAÇÕES</p>
                    <section className='container-grid-verso-historico-escolar-em'>
                        <div className='conteudo-verso-historico-escolar-em1'>
                            <div className='box-texto-esquerda'>
                                <h1 className='texto-medio'>ENSINO FUNDAMENTAL </h1>
                                <p className='texto-medio'> concluido em 2016 no (a)</p>
                                <h1 className='texto-medio'>INSTITUTO EDUCACIONAL LUMINIS - RIO DE JANEIRO / RJ</h1>
                            </div><br />

                            <div className='box-texto-esquerda'>
                                <p className='texto-medio'> <strong>*</strong> Disciplina Cursada sob-regime de adaptação, sendo considerado aprovado.</p><br /><br />

                                <p className='texto-medio'><strong>** </strong>Disciplina Cursada sob-regime de Dependência, sendo considerado aprovado.</p>
                            </div><br /><br />

                            <div className="box-texto-esquerda">
                                <h1 className='texto-medio'>ENSINO MÉDIO</h1>
                                <p className='texto-medio'> concluido em Dezembro de 2021 no (a)</p>
                                <h1 className='texto-medio'>COLÉGIO EL CURSOS SHADAY DUQUE DE CAXIA/RJ</h1>
                            </div><br />

                            <div className="box-texto-esquerda">
                                <h1 className='texto-pequeno'>OBS: AS DISCIPLINAS DE: MUICA E CULTATURA AFRO-BRAILEIRA E INDÍGENA ESTÃO INCLUSAS EM ARTE DE HISTORIA BRAILEIRAS.</h1>
                            </div>
                        </div>


                        <div className='conteudo-verso-historico-escolar-em2'>
                            <p className='texto-medio'>
                                <strong className='texto-underline'>Deliberação CEE N 357, de 26 de Julho de 2016 </strong>
                                Estabelece normas para expedição de históricos escolares, declarações de conclusão de série, certificados de conclusão de cursos ou etapas da Educação Básica e diplomas no Sistema de Ensino do Estado do Rio de Janeiro.
                            </p>
                            <p className='texto-medio'>
                                <strong className='texto-medio'>De acordo Art. 1 - A expedição de hitóricos escolares, declarações de conclusão de série, certificados de conclusão de cursos ou etapas da Educação Básica e diplomas, quando couber, com as especificações cabíveis, são da exclusiva responsabilidade da instituição de ensino, </strong>a partir da publicação desta Deliberação,
                                <p className='texto-pequeno'>tendo o (a) aluno(a) cumprido o exigido, estando o (a), mesmo (a) apto a dar sequência nos estudos e goazar dos direito que lhe são atribuídos pela Lei 9.394 de 20 de Dezembro de 1996, tendo o presente documento validade em todo o território nacional. </p>
                            </p>
                        </div>

                        <div className='conteudo-verso-historico-escolar-em3'>
                            <p className='texto-medio'>As informações de grau e menções emitidas por outras Instituições de Ensino e utilizadas neste histórico são dados fiéis extraídos de documentos arquivados em nossa Instituição.</p>
                        </div>
                    </section>
                    <h1 className='texto-pequeno'>ESTE DOCUMENTO FOI IMPRESSO EM AMBOS OS LADOS E NÃO CONTÉM EMENDAS, RESSALVAS OU RASURAS.</h1>

                    <div className='verso-historico-escolar-em-final'>

                        <h1 className='texto-medio'>Duque de Caxias, 20 dezembro 2021</h1><br/>

                        <div className='verso-historico-escolar-em-final-conteudo'>
                            <div>
                                <div className='linha-assinatura' />
                                <h1 className='texto-medio'>DIRETOR (A)</h1>
                            </div>

                            <div>
                                <div className='linha-assinatura' />
                                <h1 className='texto-medio'>SECRETÁRIO (A)</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}