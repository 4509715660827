import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/image/logo.png';
import './header.css';
import Util from '../../../../util/Util';
import Pessoa from 'lirida-main-js-service/service/Pessoa/Pessoa';
import Usuario from 'lirida-main-js-service/service/Usuario/Usuario';
import Constante from '../../../../util/Constante';

export default class Header extends Component {
    util = new Util();
    constante = new Constante();

    state = {
        pessoa: new Pessoa(),
        usuario: new Usuario(),
        mudarTela: false,
        urlBaseImagemItem: undefined,
    }

    async componentDidMount() {
        let pessoa = await this.util.buscarLocalStorage("PESSOA");

        this.setState({
            pessoa: pessoa,
        });
    }

    removerUsuario(){
        this.util.removerLocalStorage("PESSOA");
    }


    render() {
        return (
            <header className='sistema-header-container'>
                <img src={logo} alt="El cursos logo" className='sistema-header-logo-principal' />

                <nav className='sistema-header-nav'>
                    <Link
                        style={
                            this.props.selecionado === 'documento' ?
                                { color: '#6898e4', fontWeight: 'bold' } :
                                null
                        }
                        to="/documento" className="sistema-header-nav-link">
                        Requerimento
                    </Link>
                    {/* {this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrativo ?

                        <Link
                            style={
                                this.props.selecionado === 'checkout' ?
                                    { color: '#6898e4', fontWeight: 'bold' } :
                                    null
                            }
                            to="/checkout" className="sistema-header-nav-link">
                            Digitação rápida
                        </Link>
                        : null} */}

                    {this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrativo ?
                        <Link
                            style={
                                this.props.selecionado === 'pessoa' ?
                                    { color: '#6898e4', fontWeight: 'bold' } :
                                    null
                            }
                            to="/pessoa" className="sistema-header-nav-link">
                            Pessoa
                        </Link>
                        : null}

                    {/* {this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrativo ?

                        <Link
                            style={
                                this.props.selecionado === 'financeiro' ?
                                    { color: '#6898e4', fontWeight: 'bold' } :
                                    null
                            }
                            to="/financeiro" className="sistema-header-nav-link">
                            Financeiro
                        </Link>
                        : null} */}

                    {this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilPolo ?
                        <Link
                            style={
                                this.props.selecionado === 'requisicaoMatricula' ?
                                    { color: '#6898e4', fontWeight: 'bold' } :
                                    null
                            }
                            to="/matricula" className="sistema-header-nav-link">
                            Requisicão de matrícula
                        </Link>
                        : null}
                    <Link
                        style={
                            this.props.selecionado === 'perfil' ?
                                { color: '#6898e4', fontWeight: 'bold' } :
                                null
                        }
                        to="/perfil" className="sistema-header-nav-link">
                        Perfil
                    </Link>
                </nav>

                <nav className='sistema-header-nav-direita'>
                    <p><strong>Usuário: </strong> {this.state.pessoa.nome}</p>
                    <Link onClick={() => this.removerUsuario()} to="/login" className="sistema-header-nav-link">
                        Sair
                    </Link>
                </nav>
            </header>
        )
    }
}