import React, { Component } from "react";
import Header from "../Header/Header";
import './TermoDeUso.css';
export default class TermoDeUso extends Component {
  render() {
    return (
      <>
       
            <Header/>
        <div className="termoo-de-usoo-container">
          <div className="termoo-de-usoo-wrapper">
            <div className="termoo-de-usoo-titulos">
              <h1 className="h1-termo-de-uso"> Termos de uso </h1>
              <h2 className="h2-termo-de-uso"> Bem vindos à OneWork !!</h2>
            </div>
            <div className="termo-de-uso-conteudo">
              <p className="p--termo-de-uso">
                Caro Usuário, seja bem-vindo à Onework!
              </p> <br/>
              <p>
                {" "}
                Estes Termos e Condições de Uso (“Termos”) são um acordo
                vinculante entre os Usuários e a ONEWORK SOLUÇÕES INTELIGENTES (inscrita no CNPJ/ME sob nº 45.669894/0001-45), e se
                destinam ao estabelecimento de regras, direitos e obrigações
                relativos à contratação, acesso e uso do App Onework (“App”) e
                de seus Serviços Ofertados.{" "}
              </p>
              <p>
                {" "}
                É importante que o Usuário leia os Termos com a máxima atenção,
                utilizando o App tão somente se concordar expressamente com as
                disposições aqui estabelecidas.
              </p>
              <p>
                A relação do Usuário com a Onework inclui estes Termos, a nossa
                Política de Privacidade, que pode ser encontrada em: <a href="*">Politica de privacidade</a>,
                e quaisquer outros termos ou contratos adicionais que
                regulamentem ou venham a regulamentar os Serviços Ofertados.
              </p>
              <p>
                Para utilização dos Serviços Ofertados, o Usuário pessoa
                jurídica deverá se fazer representar por pessoa física investida
                dos poderes necessários para aderir a estes Termos e aos demais
                documentos legais que regulamentam nossos serviços. Quando o
                Usuário for pessoa física, deverá ser maior de 18 anos e estar
                gozando de plena capacidade para aderir aos Termos e utilizar o
                App.
              </p>
            </div>
            <div className="conheça-onework">
              <h2  className="center"> 1. Conheça oneWork</h2>
              <p>
                1.1. A Onework é uma solução de marketplace destinada a
                viabilizar a realização ágil e eficiente de eventos no Brasil,
                aproximando e facilitando a interação entre Usuários
                organizadores de eventos e Prestadores de Serviço
                pré-cadastrados no App.
              </p>
              <p>
                1.2. Importante destacar que a Onework apenas faz a função de
                intermediadora entre Usuários e Prestadores de Serviço,
                portanto, não é responsável direta e nem oferece garantias de
                qualquer natureza acerca dos Jobs oferecidos pelos Prestadores
                de Serviço, a quem deverão ser direcionadas quaisquer
                reclamações acerca da qualidade dos serviços.
              </p>
            </div>
            <div className="definicoes">
              <h2 className="center"> 2. Definições para facilitar o entendimento destes Termos</h2>
                    <p>
                      2.1 Os termos e expressões descritos abaixo, quando iniciados
                      com letra maiúscula, terão os seguintes significados:
                    </p><br/>
              <p>
                {" "}
                 <strong>I. Usuário:</strong> Pessoa física ou jurídica que, após
                o seu cadastro, utiliza o App Onework para interagir com
                Prestadores de Serviço e contratar os serviços destes, conforme
                disponibilidade.
              </p><br/>
              <p>
                {" "}
                 <strong>II. Prestador de Serviço:</strong> Pessoa física ou
                jurídica que celebrou previamente um Contrato de Agenciamento
                com a Onework e está devidamente cadastrado como Prestador de
                Serviço no App e habilitada para divulgação e oferecimento de
                seus Jobs.
              </p><br/>
              <p>
                {" "}
                 <strong>III. Dados Cadastrais:</strong> Conjunto de informações
                pessoais fornecidas pelo Usuário para a sua correta
                identificação.
              </p><br/>
              <p>
                {" "}
                 <strong>IV. Login:</strong> Identificação de e-mail ou telefone
                do Usuário para acesso ao App.
              </p><br/>
              <p>
                {" "}
                <strong>V. App ou App Onework:</strong> App desenvolvido pela
                Onework para disponibilização dos Serviços Ofertados.
              </p><br/>
              <p>
                {" "}
                 <strong>VI. Serviços Ofertados:</strong> Serviços de
                intermediação disponibilizados pela Onework aos Usuários,
                descritos com maior detalhe na cláusula 4 abaixo.
              </p><br/>
              <p>
                {" "}
                 <strong>VII. Jobs:</strong> São os serviços disponibilizados
                pelos Prestadores de Serviço cadastrados no App aos Usuários,
                podendo incluir: gerência de festas, serviços de limpeza,
                fotografia, etc.
              </p><br/>
              <p>
                {" "}
                 <strong>VIII. Onework: </strong> ONEWORK SOLUÇÕES INTELIGENTES
                COMPLETA], pessoa jurídica de direito privado, inscrita no
                CNPJ/ME sob nº 45.669894/0001-45, com sede à Av Eusebio de
                Queiroz, nº 1450 no bairro Tamatanduba, CEP:61.768-680,
                responsável pela disponibilização do App e prestação dos
                Serviços Ofertados.
              </p><br/>
              <p>
                {" "}
                 <strong>IX. Contrato de Agenciamento:</strong> Contrato
                celebrado entre a Onework e o Prestador de Serviço, nos quais
                foram estabelecidas as principais diretrizes para que este
                pudesse disponibilizar os Jobs no App e utilizar os recursos de
                divulgação oferecidos pela Onework.
              </p><br/>
              <p>
                {" "}
                 <strong>X. Senha:</strong> Conjunto de caracteres sigilosos
                intransferíveis do Usuário, utilizado em conjunto com o Login
                para a validação de acesso ao App.
              </p>
            </div>
            <div className="aplicabilidade">
              <h2 className="center">
                Aplicabilidade, Aceitação e Alteração dos Termos: Importante
                ficar atento!
              </h2>
              <p>
                {" "}
                3.1. A utilização e o acesso dos Usuários ao App Onework e aos
                Serviços Ofertados estão totalmente sujeitos a estes Termos, a
                Política de Privacidade e a outros contratos e políticas que, a
                qualquer momento, poderão ser instituídas e publicadas a
                exclusivo critério da Onework.
              </p><br/> 
              <p>
                {" "}
                3.2 A utilização do App pelos Usuários está condicionada à
                aceitação prévia da Onework, conforme os critérios de avaliação
                pré-definidos e à sua discricionariedade, sendo necessário, para
                utilização, que o Usuário encaminhe todos os dados, informações
                e a documentação solicitada pela Onework.
              </p><br/>
              <p>
                {" "}
                3.2.1 As mesmas regras serão aplicadas aos Prestadores de
                Serviço, adicionadas ainda da assinatura do Contrato de
                Agenciamento, que conterá todas as diretrizes da relação
                jurídica Prestador de Serviço x Onework, incluindo as exigências
                referentes aos Jobs oferecidos no App.
              </p><br/>
              <p>
                {" "}
                3.3. Ao acessar, se cadastrar ou adquirir Jobs de Prestadores de
                Serviço através do App, o Usuário manifesta concordância
                integral com as disposições destes Termos, de forma expressa,
                voluntária, irrevogável e irretratável, declarando estar ciente
                de todo o seu conteúdo, tendo lido, entendido e aceitado todas
                as regras, condições e obrigações aqui estabelecidas.
              </p><br/>
              <p>
                {" "}
                3.4. A aceitação destes Termos de Uso no App Onework valerá,
                para todos os fins, como assinatura eletrônica, nos termos do
                art. 10, parágrafo 2º, da MP 2200-2/2001, declarando o Usuário
                reconhecer como válido tal registro.
              </p><br/>
              <p>
                {" "}
                3.5. A Onework poderá, de tempos em tempos, e a seu exclusivo
                critério, revisar ou alterar as cláusulas destes Termos, visando
                ao aprimoramento, a melhoria dos Serviços Ofertados, ou a
                incrementação de novas funções e recursos para assegurar a
                operacionalidade ou a segurança dos Serviços Ofertados.
              </p><br/>
              <p>
                {" "}
                3.6. Qualquer nova versão dos Termos entrará em vigor a partir
                do momento em que for publicada. Os Usuários poderão ser
                informados com antecedência acerca da nova versão dos Termos,
                por meio de notificação e/ou e-mail, sendo que a continuidade de
                utilização dos Serviços Ofertados implica na aceitação, por
                parte do Usuário, das novas disposições.
              </p><br/>
              <p>
                {" "}
                3.7. Caso o Usuário não queira utilizar os Serviços Ofertados
                sob a nova versão dos Termos, este poderá encerrar sua conta
                entrando em contato a Onework. Neste caso, deverão ser cumpridas
                todas as obrigações pendentes (sobretudo as de pagamento), antes
                do procedimento de exclusão da conta e saída do Usuário do App.
              </p><br/>
            </div>
            <div className="Serviços ">
              <h2 className="center">Serviços Ofertados: O que oferecemos no App Onework?</h2>
              <p>
                {" "}
                4.1. A Onework, através do App, dedica-se unicamente a ofertar
                um ambiente ordenado e seguro para viabilizar a intermediação
                entre Prestadores de Serviço e Usuários, facilitando a
                contratação de Jobs entre essas partes.
              </p><br/>
              <p>
                {" "}
                4.2. A Onework presta aos Usuários cadastrados os seguintes e
                únicos serviços:
              </p><br/>
              <p>
                {" "}
                i) Disponibilização de espaço virtual aos Prestadores de Serviço
                cadastrados para exposição de seus Jobs, facilitando a
                localização e a interação negocial entre Prestadores de Serviço
                e Usuários;
              </p><br/>
              <p>
                {" "}
                ii) Disponibilização de ambiente de cotações automatizadas, para
                viabilizar a aquisição, por um bom custo-benefício, de Jobs que
                se encaixem no perfil desejado pelo Usuário; e
              </p><br/>
              <p>
                iii) Disponibilização do processamento de pagamentos on-line,
                por meio de solução integrada no App, fornecida por empresa
                contratada, a fim de viabilizar o pagamento pelos Jobs que o
                Usuário adquire diretamente do Prestador de Serviço.
              </p><br/>
              <p>
                {" "}
                4.3. A Onework esclarece (e o Usuário desde já fica ciente) de
                que os Serviços Ofertados por si estão limitados aos descritos
                nos itens acima, não abrangendo os serviços de produção,
                qualidade, procedência, logística e/ou entrega dos Jobs
                adquiridos e/ou quaisquer outros não previstos expressamente
                nestes Termos.
              </p><br/>
              <p>
                {" "}
                4.4. Os valores, tarifas e demais condições comerciais de
                utilização do App serão disponibilizadas no próprio ambiente
                virtual, estando sujeitos às informações disponibilizadas pelos
                Prestadores de Serviço, as quais podem ser alteradas sem aviso
                prévio.
              </p><br/>
            </div>
            <div className="regras">
              <h2 className="center">Algumas regras relacionadas ao cadastro</h2>
              <p>
                {" "}
                5.1. O cadastro é condição essencial para que o Usuário usufrua
                dos Serviços Ofertados. O Usuário deverá preencher as
                informações obrigatórias exigidas no cadastro – tanto da pessoa
                física quanto da pessoa jurídica, se for o caso – assumindo
                integralmente a responsabilidade pela exatidão, veracidade e
                atualização dos Dados Cadastrais, que poderão ser verificados, a
                qualquer momento, pela Onework.
              </p><br/>
              <p>
                {" "}
                5.2. Caso seja constatado o fornecimento de informações
                incorretas, desatualizadas, inverídicas ou não confirmadas, bem
                como na hipótese da negativa em corrigi-las ou de enviar
                documentação que comprove a correção, a Onework se reserva no
                direito de suspender, temporária ou definitivamente, a conta de
                Usuário até que a situação de anomalia esteja regularizada, sem
                prejuízo da adoção de outras medidas cabíveis.
              </p><br/>
              <p>
                {" "}
                5.3. Em nenhuma hipótese será permitida a cessão, venda, aluguel
                ou outra forma de transferência da conta de Usuário. Também não
                se permitirá a criação de novos cadastros por Usuários cujos
                cadastros originais tenham sido suspensos, temporária ou
                definitivamente, por infrações a estes Termos, às políticas da
                Onework ou à legislação vigente.
              </p><br/>
              <p>
                {" "}
                5.4. O Usuário, para acessar os Serviços Ofertados, deverá criar
                Login e Senha durante o cadastro, ficando sob sua exclusiva
                responsabilidade qualquer solicitação dos Serviços Ofertados que
                seja feita com o uso de Login e Senha de sua titularidade, não
                devendo divulgar esses dados, em conjunto, a terceiros.
              </p>
              <p>
                {" "}
                5.5. A confidencialidade do Login e da Senha, bem como quaisquer
                danos potencialmente resultantes da não manutenção do sigilo
                dessas informações serão de exclusiva responsabilidade do
                Usuário.
              </p><br/>
              <p>
                {" "}
                5.6. Em caso de esquecimento, o Usuário poderá requerer o envio
                de sua senha para seu e-mail cadastrado, ao clicar na opção
                “Esqueci Minha Senha”.
              </p><br/>
            </div>
            <div className="responsabilidades">
              <h2 className="center">
                Quais são as obrigações e responsabilidades do Usuário no App?
              </h2>
              <p>
                {" "}
                6.1. O Usuário assume integralmente todos os ônus e
                responsabilidades decorrentes de seus atos no App, respondendo,
                ainda, pelos atos praticados por terceiros que se valerem do uso
                de Login e Senha de sua titularidade.
              </p><br/>
              <p>
                {" "}
                6.2. O Usuário deverá indenizar a Onework ou terceiros por
                quaisquer ações ou omissões que representem o descumprimento das
                regras estabelecidas nestes Termos, nas demais diretrizes e
                políticas da Onework ou na lei vigente e que ocasionem prejuízos
                de qualquer natureza.
              </p><br/>
              <p>
                {" "}
                6.3. O Usuário se obriga a não fazer uso do App na como meio de
                spam, abstendo-se, entre outras práticas, de (i) enviar
                publicidade ou outros comunicados a outros Usuários, sem a
                prévia solicitação ou o consentimento destes; e/ou (ii) remeter
                quaisquer outras mensagens não solicitadas, nem previamente
                consentidas.
              </p><br/>
              <p>
                {" "}
                6.4. O Usuário concorda em indenizar a Onework por todo e
                qualquer dano ou perda que possa eventualmente surgir decorrente
                do seu acesso e uso do App ou por qualquer demanda promovida por
                outros Usuários, Prestadores de Serviço ou terceiros decorrentes
                da violação de qualquer cláusula destes Termos, de outras
                políticas e regras estabelecidas pela Onework, ou da legislação
                vigente.
              </p><br/>
              <p>
                {" "}
                6.5. “Hiperlinks” e outros redirecionamentos eventualmente
                incluídos no App por Usuários e/ou Prestadores de Serviço não
                implicam, em nenhum caso, na existência de relações entre a
                Onework e o proprietário da página na qual se estabeleça, nem a
                aceitação e aprovação por parte da Onework de seus conteúdos ou
                serviços ali oferecidos ou disponibilizados ao público, não se
                responsabilizando a Onework de maneira alguma por isso, mas sim
                o próprio Usuário.
              </p><br/>
              <p>
                Ao utilizar os Serviços Ofertados, o Usuário compromete-se a:
              </p><br/>
              <p> I. Manter os Dados Cadastrais atualizados e verídicos;</p><br/>
              <p> II. Manter em sigilo sua Senha de acesso ao App;</p><br/>
              <p>
                {" "}
                III. Não veicular conteúdos considerados ofensivos, abusivos,
                difamatórios, pornográficos, ameaçadores, obscenos, ilegais,
                violadores das normas de defesa do consumidor e de proteção de
                dados pessoais ou que se destinem a promover ou cometer ato
                ilegal de qualquer natureza, que incluam conteúdo malicioso,
                tais como vírus ou malwares, ou que de qualquer forma interfiram
                no acesso de qualquer Usuário aos Serviços Ofertados;
              </p><br/>
              <p>
                {" "}
                IV. Conduzir de boa-fé as negociações e as transações comerciais
                dos Jobs adquiridos dos Prestadores de Serviço
              </p><br/>
              <p>
                {" "}
                V.Não agredir, caluniar, injuriar ou difamar outros
                Usuários/Prestadores de Serviço.
              </p><br/>
            </div>
            <div className="garantias">
              <h2 className="center">
                Garantias e Reponsabilidades: O que está fora do nosso alcance?
              </h2>
              <p>
                {" "}
                7.1. A Onework se isenta de garantias de qualquer tipo,
                incluindo, porém não se limitando a garantias relacionadas à
                segurança, confiabilidade, conveniência e performance do App.
              </p><br/>
              <p>
                {" "}
                7.2. A Onework não oferece diretamente, assim como não controla
                e não é responsável pela qualidade, segurança, legalidade,
                integridade, quantidade e disponibilidade dos Jobs oferecidos
                pelos Prestadores de Serviço, assim como não representa
                Prestadores de Serviço ou Usuários a nenhum título. A Onework
                nem se responsabiliza pela capacidade dos Prestadores de Serviço
                de oferecerem os Jobs adequadamente ou pela capacidade dos
                Usuários de efetuarem quaisquer aquisições. Por essa razão, a
                Onework fica isenta de quaisquer garantias sobre informações
                veiculadas pelos Prestadores de Serviço no App, sobretudo
                àquelas relacionadas às condições de execução dos Jobs, assim
                como recebimento e pagamento dos valores correlatos.
              </p><br/>
              <p>
                {" "}
                7.3. Clientes e Prestadores de Serviço são os únicos
                responsáveis por estabelecer e executar os termos e condições
                das transações que conduzirem, através ou como resultado do uso
                do App, incluindo, mas não se limitando, a termos relativos a
                pagamentos, garantias, cancelamentos, entregas, seguros, taxas,
                permissões, transportes e quaisquer outros aspectos relacionados
                aos Jobs. Portanto, a Onework não será responsável pelo efetivo
                cumprimento das obrigações assumidas por Usuários e Prestadores
                de Serviço, que, ao realizarem as negociações, o fazem por sua
                própria conta e risco, reconhecendo a Onework como um
                marketplace, que atua como mero fornecedor dos Serviços
                Ofertados.
              </p><br/>
              <p>
                {" "}
                7.4. Os Prestadores de Serviço assumem todos os riscos e
                responsabilidades por danos ou perdas, de qualquer tipo,
                relacionados direta ou indiretamente com os Jobs anunciados,
                incluindo reclamações relativas obrigações consumeristas,
                trabalhistas e/ou tributárias, assim como quaisquer outras, de
                idêntica natureza, que deverão ser sempre a estes dirigidas.
              </p><br/>
              <p>
                {" "}
                7.5. A Onework se empenha em manter o App seguro e funcionando
                de forma apropriada, mas não pode garantir integralmente o
                acesso ou a disponibilidade ininterrupta dos Serviços Ofertados,
                em decorrência da insegurança e instabilidade próprias do
                ambiente virtual, estando o App, portanto, sujeita a eventuais
                problemas de interrupção, falha técnica e indisponibilidade de
                funcionamento temporário.
              </p><br/>
              <p>
                {" "}
                7.6. A Onework não faz nenhuma representação, nem garante,
                endossa, assegura ou assume responsabilidade por quaisquer Apps
                de terceiros (ou seu conteúdo), pelo conteúdo postado por
                Usuários/Prestadores de Serviço, dispositivos ou quaisquer
                outros produtos ou serviços anunciados, promovidos ou oferecidos
                por terceiros no ou através dos Serviços Ofertados, ou qualquer
                site vinculado por hiperlink. A Onework não será
                responsabilizada por qualquer transação entre o
                Usuário/Prestador de Serviço e terceiros fornecedores do acima
                exposto.
              </p><br/>
              <p>
                {" "}
                7.7. A Onework não será responsável, e desde já o Usuário
                concorda em não responsabilizar a Onework por quaisquer danos ou
                perdas, de qualquer tipo, resultantes, direta ou indiretamente,
                de:
              </p><br/>
              <p>
                Utilização, pelo Usuário, ou sua inabilidade em usar os Serviços
                Ofertados;
              </p><br/>
              <p> I. Atrasos ou interrupções nos Serviços Ofertados;</p><br/>
              <p>
                {" "}
                II. Falhas, bugs, erros ou inexatidões de qualquer natureza nos
                Serviços Ofertados;
              </p><br/>
              <p>
                {" "}
                III. Ações, omissões ou conteúdo de outros Usuários, de
                Prestadores de Serviço e de terceiros, incluindo, mas não se
                limitando, a Jobs anunciados, orientações e informações sobre
                qualidade, preço, forma de execução ou outras orientações
                fornecidas pelos Prestadores de Serviço;
              </p><br/>
              <p>
                {" "}
                IV. Aplicação de sanção em decorrência da violação de qualquer
                cláusula destes Termos;{" "}
              </p><br/>
              <p>
                {" "}
                V. Quaisquer condutas ou acontecimentos que fujam da esfera de
                vigilância da Onework;{" "}
              </p><br/>
              <p>
                {" "}
                VII. Acesso não autorizado por terceiros na conta dos Usuários;
                e/ou
              </p><br/>
              <p>
                {" "}
                VIII. Indisponibilidade ou erros ocorridos no processamento de
                pagamentos online, realizado por empresa contratada para este
                fim.
              </p><br/>
            </div>
            <div className="sançoes">
              <h2 className="center">
                Sanções: Esperamos não recorrer a isso, mas é importante que
                você seja informado
              </h2>
              <p>
                {" "}
                8.1. Sem prejuízo de outras medidas cabíveis, a Onework poderá
                advertir, suspender e/ou cancelar, temporária ou
                definitivamente, a conta de um Usuário, bem como excluir ou
                censurar conteúdos veiculados por este no App, iniciando as
                ações legais cabíveis e/ou cancelando a prestação dos Serviços
                Ofertados se:
              </p><br/>
              <p>
                O Usuário descumprir as cláusulas destes Termos, as demais
                políticas da Onework ou a legislação vigente;
              </p><br/>
              <p>
                O Usuário descumprir quaisquer de seus deveres e
                responsabilidades;
              </p><br/>
              <p>O Usuário praticar atos fraudulentos ou dolosos;</p><br/>
              <p>
                Não puder ser verificada a identidade do Usuário ou se qualquer
                informação fornecida por ele estiver incorreta, incompleta ou
                falsa; e/ou
              </p><br/>
              <p>
                A Onework entender que a atitude do Usuário possa ou tenha
                causado algum dano a ela ou a terceiros.
              </p><br/>
            </div>
            <div className="propiedade">
              <h2 className="center">
                Propriedade Intelectual: Como os direitos da Onework são
                protegidos?
              </h2>
              <p>
                {" "}
                9.1. A Onework concede aos Usuários uma permissão limitada, não
                exclusiva, não sublicenciável, não transferível e revogável para
                fazer uso do App e dos Serviços Ofertados.
              </p><br/>
              <p>
                {" "}
                9.2. O Usuário reconhece que é a Onework quem detém os direitos
                de propriedade intelectual associados aos Serviços Ofertados,
                além dos direitos necessários à utilização e exploração
                comercial do App, aos conteúdos de suas telas, programas, base
                de dados, redes, códigos, software, hardware, informação,
                tecnologia, funcionalidades, arquivos, marcas, patentes,
                direitos autorais, modelos industriais, nomes comerciais, entre
                outros direitos protegidos pelas leis nacionais e internacionais
                em vigor.
              </p><br/>
              <p>
                {" "}
                9.3. O Usuário não poderá fazer cópias ou alterações não
                autorizadas do App, no todo ou em parte (sejam relacionadas ao
                código fonte, interface, layout, “look and feel” entre outros).
              </p><br/>
              <p>
                {" "}
                9.4. Não é permitido ao Usuário reconfigurar, desmontar ou
                praticar, de qualquer forma, engenharia reversa no App.
              </p><br/>
              <p>
                {" "}
                9.5. O Usuário não poderá utilizar a marca e/ou quaisquer sinais
                distintivos utilizados pela Onework ou pelos outros Usuários,
                sem a devida autorização prévia e expressa.
              </p><br/>
              <p>
                {" "}
                9.6. Todos os direitos e licenças não concedidos expressamente
                nestes Termos são reservados aos proprietários dos conteúdos ou
                serviços, vez que os presentes Termos não concedem quaisquer
                licenças implícitas.
              </p><br/>
            </div>
            <div className="tempo ">
              <h2 className="center">Qual o tempo de vigência destes Termos?</h2>
              <p>
                {" "}
                10.1. Estes Termos permanecerão vigentes por tempo
                indeterminado, desde a sua adesão até a sua rescisão, podendo
                esta última ser realizada a qualquer tempo pelo Usuário ou pela
                Onework.
              </p><br/>
              <p>
                {" "}
                10.2. A Onework poderá rescindir estes Termos, suspendendo ou
                cancelando seu acesso ao App a qualquer momento, inclusive no
                caso de acesso não autorizado, suspeitas de utilização irregular
                dos Serviços Ofertados, ou em caso de retirada do App do ar.
              </p><br/>
              <p>
                {" "}
                10.3. Caso a rescisão – por parte da Onework ou do Usuário –
                seja efetivada quando ainda restarem pendentes quaisquer
                obrigações, seja referente a pedidos, pagamentos ou cotações em
                aberto, a Produtos adquiridos ou relativo aos pagamentos de
                compras realizadas por meio do App, o cancelamento da conta do
                Usuário somente será efetivado após o saneamento de todas estas
                pendências.
              </p><br/>
            </div>
            <div className="Usuário">
              <h2 className="center"> Como o Usuário pode falar com a Onework?</h2>
              <p>
                {" "}
                11.1. O Usuário poderá se comunicar com a Onework sempre que
                necessário, a fim de esclarecer dúvidas sobre o App, sobre estes
                Termos ou outras políticas da Onework, bem como para solucionar
                quaisquer problemas ou para formalizar elogios, sugestões e/ou
                críticas.
              </p><br/>
              <p>
                {" "}
                11.2. A comunicação deverá ser direcionada exclusivamente para o
                e-mail atendimento@onework.app.br, não sendo consideradas como recebidas qualquer
                comunicação realizada em via diversa.
              </p><br/>
            </div>
            <div className="  regras-gerais ">
              <h2 className="center"> Algumas regras gerais antes de finalizar</h2>
              <p>
                {" "}
                12.1. Se qualquer cláusula destes Termos for considerada
                inválida ou inaplicável deverá ser excluída e a parte restante
                continuará válida e plenamente aplicável.
              </p><br/>
              <p>
                Estes Termos, assim como a Política de Privacidade e quaisquer
                outras diretrizes e regras divulgadas pela Onework constituem o
                acordo integral das Partes com relação ao objeto aqui tratado,
                substituindo todos os acordos anteriores, sejam escritos ou
                verbais.
              </p><br/>
              <p>
                {" "}
                12.3. A tolerância ou o não exercício, pelos Usuários ou pela
                Onework, de quaisquer direitos a eles assegurados nestes Termos
                de Uso ou na lei em geral não significa novação ou renúncia a
                qualquer desses direitos, podendo a referida o Usuário ou a
                Onework exercê-los durante a vigência destes Termos de Uso.
              </p><br/>
              <p>
                {" "}
                12.4. A Onework será a controladora dos dados pessoais coletados
                em conexão com o uso dos Serviços Ofertados. Os seus dados
                pessoais serão tratados de acordo as leis aplicáveis, e em
                conformidade com as regras indicadas na Política de Privacidade
                da Onework, que pode ser consultada em <a href="politica-de-privacidade"> Politica de privacidade</a>.
              </p><br/>
            </div>
            <div className=" resolvemos-problemas">
              <h2 className="center"> Como resolvemos nossos problemas?</h2>
              <p>
                {" "}
                13.1. A relação estabelecida entre os Usuários e a Onework será
                regida exclusivamente pelas Leis da República Federativa do
                Brasil, sendo estas as únicas aplicáveis em caso de qualquer
                discussão ou litígio relativo a estes Termos ou a quaisquer
                outras políticas/regulamentações da Onework.
              </p><br/>
              <p>
                13.2. Caso seja necessário dirimir qualquer dúvida ou
                controvérsia decorrente destes Termos, fica eleito o foro da
                Comarca de [inserir], com renúncia de todos os outros, por mais
                privilegiados que sejam.
              </p><br/>
            </div>
            <div>
              <h1 className="center">
                Agora que você já leu todos os nossos Termos de Uso, poderá
                indicar se está de acordo com todas as disposições, realizando o
                seu cadastro e se tornando usuário do App Onework. Esperamos que
                você aproveite bastante! 
              </h1>
              <a href="principal"> voltar a pagina principal</a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
