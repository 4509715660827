import React, { Component } from 'react';
import '../styleCertificados.css'

export default class CertificadoEnsinoMedio extends Component {
    state = {
        nomeAluno: "Patrick Fonseca",
    }

    render() {
        return (
            <main className="ctd-container-geral">
                <article className="ctd-ensino-medio-container-conteudo">
                    <div className="ctd-ensino-medio-div-texto-titulo">
                        <h2>PARECER Nº 001.MV.01055019/SEEDUC/COGIE/2019</h2>
                        <h2>PROCESSO Nº E-03/016/2194/2019</h2>
                        <h1>CERTIFICADO DE CONCLUSÃO</h1>
                    </div>

                    <div className="ctd-ensino-medio-div-texto-titulo">
                        <p>A  DIREÇÃO  DO  COLÉGIO  EL CURSOS SHADAY,  NOS  TERMOS  DO ARTIGO</p>
                        <p>Nº 24, INCISO VII DA LEI 9.394, DE 20 DE DEZEMBRO DE 1996, CONFERE O PRESENTE CERTIFICADO A:</p>
                    </div>

                    <section className="ctd-ensino-medio-div-container-nome-aluno">
                        <div className="ctd-ensino-medio-div-nome-aluno">
                            <h1>{this.state.nomeAluno}</h1>
                        </div>

                        <div className="ctd-ensino-medio-container-conteudo-dados">
                            <p>IDENTIDADE N.º</p>
                            <u> 13.200.286.6</u>
                            <p>ORGÃO EMISSOR, </p>
                            <u> DIC/RJ,</u>
                            <p>NACIONALIDADE</p>
                            <u>BRASILEIRO</u>
                        </div>
                        <div className="ctd-ensino-medio-container-conteudo-dados">
                            <p>NATURAL DE</p>
                            <u>RIO DE JANEIRO,</u>
                            <p>UF</p>
                            <u>RJ,</u>
                            <p>NASCIDO (A) EM</p>
                            <u>01</u>
                            <p>DE</p>
                            <u>MAIO</u>
                            <p>DE</p>
                            <u>1981,</u>
                            <p>POR TER</p>
                        </div>
                        <div className="ctd-ensino-medio-container-conteudo-dados">
                            <p>CONCLUÍDO EM</p>
                            <u>20</u>
                            <p>DE</p>
                            <u>DEZEMBRO</u>
                            <p>DE</p>
                            <u>2021,</u>
                            <p>O CURSO DE</p>
                            <u>ENSINO MÉDIO</u>
                        </div>
                    </section>

                    <section className="ctd-ensino-medio-container-data">
                        <p>DUQUE DE CAXIAS</p>
                        <p>DE</p>
                        <p>DE 2022</p>
                    </section>

                    <section className="ctd-ensino-medio-container-assinaturas">
                        <div className="ctd-ensino-medio-box-assinatura">
                            <div className="ctd-ensino-medio-linha-assinatura">
                                <p>Diretor (a)</p>
                            </div>
                        </div>
                        <div className="ctd-ensino-medio-box-assinatura">
                            <div className="ctd-ensino-medio-linha-assinatura">
                                <p>Concluinte (a)</p>
                            </div>
                        </div>
                        <div className="ctd-ensino-medio-box-assinatura">
                            <div className="ctd-ensino-medio-linha-assinatura">
                                <p>Secretario (a)</p>
                            </div>
                        </div>
                    </section>
                </article>
            </main>
        )
    }
}