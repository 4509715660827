import { Component } from "react";
import './SecaoCheckoutStyles.css';


export default class ContainerInput extends Component{
    render(){
        return(
            <div className='secao-chekout-container-input'
            >
                {this.props.children}
            </div>
        )
    }
}