import DocumentoService from "lirida-main-js-service/service/Documento/DocumentoService";
import Documento from "lirida-main-js-service/service/Documento/Documento";
import React, { Component } from "react";
import Util from "../../../util/Util";
import Container from "../sistema-components/Container/Container";
import Header from "../sistema-components/Header/Header";
import Sidebar from "../sistema-components/Sidebar/Sidebar";
import "../sistema.css";
import { FaArrowLeft, FaPen, } from "react-icons/fa";
import Constante from "../../../util/Constante";
import Pesquisa from "lirida-main-js-service/service/Pesquisa/Pesquisa";
import PessoaService from "lirida-main-js-service/service/Pessoa/PessoaService";
import TipoDocumentoProcessoService from "lirida-main-js-service/service/TipoDocumentoProcesso/TipoDocumentoProcessoService";
import DepartamentoService from "lirida-main-js-service/service/Departamento/DepartamentoService";
import TipoDocumentoProcesso from "lirida-main-js-service/service/TipoDocumentoProcesso/TipoDocumentoProcesso";
import TabelaPrecoService from "lirida-main-js-service/service/TabelaPreco/TabelaPrecoService";
import DocumentoItem from "lirida-main-js-service/service/Documento/DocumentoItem";
import TabelaPreco from "lirida-main-js-service/service/TabelaPreco/TabelaPreco";
import MsgOK from "../../../component/MsgOK/MsgOK";
import Contato from 'lirida-main-js-service/service/Contato/Contato';
import TipoContato from 'lirida-main-js-service/service/TipoContato/TipoContato';
import PopUp from "../../../component/PopUp/PopUp";
import Dropdown from "../../../component/Dropdown/Dropdown";
import Loading from "../../../component/Loading/Loading";
import TipoRelacionamentoService from 'lirida-main-js-service/service/TipoRelacionamento/TipoRelacionamentoService';
import TipoRelacionamento from 'lirida-main-js-service/service/TipoRelacionamento/TipoRelacionamento';
import DocumentoRelacionamento from 'lirida-main-js-service/service/DocumentoRelacionamento/DocumentoRelacionamento';
import DocumentoCaracteristicaDocumentoService from 'lirida-main-js-service/service/DocumentoCaracteristicaDocumento/DocumentoCaracteristicaDocumentoService';
import TipoArquivo from 'lirida-main-js-service/service/TipoArquivo/TipoArquivo';
import DocumentoArquivo from 'lirida-main-js-service/service/DocumentoArquivo/DocumentoArquivo';
import TipoArquivoService from 'lirida-main-js-service/service/TipoArquivo/TipoArquivoService';
import TipoContatoService from 'lirida-main-js-service/service/TipoContato/TipoContatoService';
import TipoAtendimento from 'lirida-main-js-service/service/TipoAtendimento/TipoAtendimento';
import DocumentoAtendimento from 'lirida-main-js-service/service/DocumentoAtendimento/DocumentoAtendimento';
import TipoAtendimentoService from 'lirida-main-js-service/service/TipoAtendimento/TipoAtendimentoService';
import VwContadorDocumento from 'lirida-main-js-service/service/VwContadorDocumento/VwContadorDocumento';
import { Link, Navigate } from "react-router-dom";
import Pessoa from "lirida-main-js-service/service/Pessoa/Pessoa";
import Input from "../../../component/Input/Input";

export default class DocumentoPg extends Component {
  util = new Util();
  tipoDocumentoProcessoService = new TipoDocumentoProcessoService();
  documentoService = new DocumentoService();
  pessoaService = new PessoaService();
  documentoCaracteristicaDocumentoService = new DocumentoCaracteristicaDocumentoService();
  tipoRelacionamentoService = new TipoRelacionamentoService();
  tabelaPrecoService = new TabelaPrecoService();
  constante = new Constante();
  tipoContatoService = new TipoContatoService();
  departamentoService = new DepartamentoService();
  tipoArquivoService = new TipoArquivoService();
  tipoAtendimentoService = new TipoAtendimentoService();
  // setAsyncState = (newState) =>
  //   new Promise((resolve) => this.setState(newState, resolve));

  state = {
    pessoaUsuario: new Pessoa(),
    documento: new Documento(),
    documentoItem: new DocumentoItem(),
    contato: new Contato(),
    listaTipoContato: [],
    listaContato: [],
    tipoContato: new TipoContato(),
    quantidade: 1,
    filtro: "com filtro",
    listaDocumento: [],
    listaPessoa: [],
    listaTabelaPreco: [],
    listaDocumentoItem: [],
    tabelaPreco: new TabelaPreco(),
    urlBaseImagemItem: undefined,
    urlBaseUrlArquivoDocumento: undefined,
    tela: "1",
    conteudo: "",
    listaTipoDocumentoProcesso: [],
    mensagemVisivel: false,
    mensagem: "",
    popupVisivel: false,
    mensagemExcluir: "",
    dropdownVisivel: false,
    listaTipoDocumentoProcessoItem: [],
    tipoDocumentoProcesso: new TipoDocumentoProcesso(),
    listaDepartamento: [],
    carregando: false,
    tag: "dados_serviços",
    listaDocumentoRelacionamento: [],
    listaPessoaDoRelacionamento: [],
    listaVwContadorDocumento: [],
    vwContadorDocumento: new VwContadorDocumento(),
    documentoRelacionamento: new DocumentoRelacionamento(),
    tipoRelacionamento: new TipoRelacionamento(),
    listaTipoRelacionamento: [],
    popupDeletarDocumentoRelacionamentoVisivel: false,
    listaDocumentoCaracteristica: [],
    listaTipoArquivo: [],
    listaDocumentoArquivo: [],
    documentoArquivo: new DocumentoArquivo(),
    tipoArquivo: new TipoArquivo(),
    popupDeletarDocumentoArquivoVisivel: false,
    listaDocumentoAtendimento: [],
    listaTipoAtendimento: [],
    documentoAtendimento: new DocumentoAtendimento(),
    tipoAtendimento: new TipoAtendimento(),
    navegarParaCertificado: false,
    documentoArquivoRg: new DocumentoArquivo(),
    documentoArquivoCpf: new DocumentoArquivo(),
    documentoArquivoComprovanteResidencia: new DocumentoArquivo(),
    documentoArquivoCertidaoNascimento: new DocumentoArquivo(),
    documentoArquivoCertidaoCasamento: new DocumentoArquivo(),
    documentoArquivoHistoricoEscolar: new DocumentoArquivo(),
    documentoArquivoCertificadoEnsinoMedio: new DocumentoArquivo(),
    documentoArquivoDiarioOficial: new DocumentoArquivo(),
    documentoArquivoFotoTresPorQuatro: new DocumentoArquivo(),
    documentoArquivoCertidaoReservista: new DocumentoArquivo(),
    documentoArquivoTituloEleitor: new DocumentoArquivo(),
    documentoArquivoQuitacaoEleitoral: new DocumentoArquivo(),
  };

  async componentDidMount() {
    await this.iniciar();
  }

  async iniciar() {
    this.urlBaseUrlArquivoDocumento = await this.util.buscarUrlBaseArquivo();
    let pessoa = await this.util.buscarLocalStorage("PESSOA");

    this.setState({
      pessoaUsuario: pessoa,
      urlBaseUrlArquivoDocumento: this.urlBaseUrlArquivoDocumento,
    });

    let parametros = this.buscarParametrosUrl();

    if (parametros) {
      // muda tela
    }

    let retornoDepartamento = await this.departamentoService.listar(
      this.constante.seqUsuario
    );

    let retornoListaVwContadorDocumento = await this.listarVwContadorDocumento();
    console.log("*********** VWDOCUMENTO");
    console.log(retornoListaVwContadorDocumento);

    let retornoListaDocumento = await this.listarDocumentoComFiltro(retornoListaVwContadorDocumento.objeto[0].seqTipoDocumentoProcesso);
    // let retornoPessoa = await this.pessoaService.listar(
    //   usuario.seqUsuario,
    //   this.state.conteudo
    // );
    // let retornoTabelaPreco = await this.tabelaPrecoService.listarTabelaPreco(
    //   this.constante.seqTabelaPreco
    // );

    // console.log(retornoTabelaPreco);
    this.setState({
      urlBaseUrlArquivoDocumento: this.urlBaseUrlArquivoDocumento,
      listaVwContadorDocumento: retornoListaVwContadorDocumento.objeto,
      vwContadorDocumento: retornoListaVwContadorDocumento.objeto[0],
      listaDocumento: retornoListaDocumento.objeto,
      listaDepartamento: retornoDepartamento.objeto,
    });
    console.log("******** departamento *********");
    console.log(retornoDepartamento);

    this.listarTipoAtendimento();

  }

  async listarVwContadorDocumento() {
    let retornoListaVwContadorDocumento = await this.documentoService.listarContadorDocumento(this.constante.seqUsuario, this.constante.seqTipoDocumento);
    return retornoListaVwContadorDocumento
  }

  async listarDocumentoComFiltro(pSeqTipoDocumentoProcesso) {
    this.listaPesquisa = []

    this.pesquisa = new Pesquisa();
    this.pesquisa.campo = 'documento.seq_tipo_documento_processo';
    this.pesquisa.conteudo = pSeqTipoDocumentoProcesso;
    this.pesquisa.tipo = 'NUMERO';
    this.pesquisa.operacao = 'VAZIO';
    this.listaPesquisa.push(this.pesquisa);

    if (this.state.pessoaUsuario.seqPessoaPerfil === this.constante.seqPessoaPerfilPolo) {
      this.pesquisaPessoaUsuarioVendedor = new Pesquisa();
      this.pesquisaPessoaUsuarioVendedor.campo = 'documento.seq_usuario_vendedor';
      this.pesquisaPessoaUsuarioVendedor.conteudo = this.state.pessoaUsuario.seqPessoa;
      this.pesquisaPessoaUsuarioVendedor.tipo = 'NUMERO';
      this.pesquisaPessoaUsuarioVendedor.operacao = 'AND';
      this.listaPesquisa.push(this.pesquisaPessoaUsuarioVendedor);
    }

    this.retorno = await this.documentoService.listarComFiltro(this.listaPesquisa);

    return this.retorno;
  }

  async listarTipoAtendimento() {
    let retorno = await this.tipoAtendimentoService.listar(this.constante.seqUsuario, 'D')
    this.setState({ listaTipoAtendimento: retorno.objeto });
  }

  async listarTipoArquivo() {
    let retorno = await this.tipoArquivoService.listar(this.constante.seqUsuario, 'D')
    this.setState({ listaTipoArquivo: retorno.objeto });
  }

  async listarTipoRelacionamento() {
    let retorno = await this.tipoRelacionamentoService.listar(this.constante.seqUsuario, 'D');
    this.setState({ listaTipoRelacionamento: retorno.objeto })
  }

  async listarCaracteristicaDocumento(pDocumento) {
    let documento;

    if (pDocumento) {
      documento = pDocumento;
    } else {
      documento = this.state.documento;
    }

    console.log("********************")
    console.log(documento)

    let retorno = await this.documentoCaracteristicaDocumentoService.listar(documento);
    this.setState({
      listaDocumentoCaracteristica: retorno.objeto,
    })

    console.log(retorno)
  }

  marcarDesmarcarCaracteristica(pIndex) {
    let listaDocumentoCaracteristica = this.state.listaDocumentoCaracteristica;

    if (listaDocumentoCaracteristica[pIndex].marcado === "true") {
      listaDocumentoCaracteristica[pIndex].marcado = "false";
    } else if (listaDocumentoCaracteristica[pIndex].marcado === "false") {
      listaDocumentoCaracteristica[pIndex].marcado = "true";
    }

    listaDocumentoCaracteristica[pIndex].seqDocumento = this.state.documento.seqDocumento;

    this.setState({
      listaDocumentoCaracteristica: listaDocumentoCaracteristica,
    })
  }

  async salvarCaracteristica() {
    let retorno = await this.documentoCaracteristicaDocumentoService.salvar(this.state.listaDocumentoCaracteristica);
    console.log(retorno);

    if (retorno.resultado === true) {
      this.setState({
        msgOKVisivel: true,
        mensagem: retorno.msg
      })
    }

    if (retorno.resultado === false) {
      this.setState({
        msgErrorVisivel: true,
        mensagem: retorno.msg
      })
    }

    setTimeout(
      () => this.setState({ msgOKVisivel: false, msgErrorVisivel: false })
      , 5000
    )
  }

  novoDocumento() {
    this.documento = new Documento();

    this.setState({
      tela: '2',
      documento: this.documento
    })
  }



  formatarData(pData) {
    console.log(pData);
    let dataSplitada = pData?.split("-");
    let data = new Date(dataSplitada[0], dataSplitada[1], dataSplitada[2])
      .toLocaleString('')
      .split(" ")
      .join("T")
      .replace("/", "-")
      .replace("/", "-");

    console.log(data);
    return data;
  }

  retornaDataFormatada(pData) {
    this.data = String(pData);
    this.data = this.data.split('T')[0].split('-').join('/');
    return this.data;
  }

  buscarParametrosUrl() {
    if (!window.location.search) {
      return null;
    }

    let query = window.location.search.slice(1);
    let partes = query.split("&");
    let data = {};
    partes.forEach(function (parte) {
      let chaveValor = parte.split("=");
      let chave = chaveValor[0];
      let valor = chaveValor[1];
      data[chave] = valor;
    });

    return data;
  }

  criarObjetoPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
    let pesquisa = new Pesquisa();

    pesquisa.campo = pCampo;
    pesquisa.conteudo = pConteudo;
    pesquisa.operacao = pOperacao;
    pesquisa.tipo = pTipo;

    return pesquisa;
  }

  async listar(pTipoDocumentoProcesso) {
    let tipoDocumentoProcesso = pTipoDocumentoProcesso;

    if (!pTipoDocumentoProcesso) {
      tipoDocumentoProcesso = this.state.tipoDocumentoProcesso;
    }

    let listaPesquisa = [
      this.criarObjetoPesquisa(
        "documento.seq_usuario_vendedor",
        this.state.pessoaUsuario.seqUsuario,
        "",
        "NUMERO"
      ),
      this.criarObjetoPesquisa(
        "documento.seq_tipo_documento_processo",
        tipoDocumentoProcesso.seqTipoDocumentoProcesso,
        "AND",
        "NUMERO"
      ),
    ];

    let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
    this.setState({
      listaDocumento: retorno.objeto,
    });

    console.log(retorno.objeto);
  }

  async listarDocumentoItem(pDocumento) {
    let documento;

    if (pDocumento) {
      documento = pDocumento;
    } else {
      documento = this.state.documento;
    }

    let retornoDocumentoItem = await this.documentoService.listarDocumentoItem(
      documento
    );
    this.setState({ listaDocumentoItem: retornoDocumentoItem.objeto });
    console.log(retornoDocumentoItem);
  }

  // async salvar() {
  //   let documento = this.state.documento;
  //   documento.seqUsuario = this.constante.seqUsuario;
  //   documento.seqUsuarioVendedor = this.state.usuario.seqUsuario;
  //   documento.seqTipoDocumento = this.constante.seqTipoDocumento;

  //   console.log(documento);

  //   let retorno = await this.documentoService.salvar(documento);
  //   console.log(retorno);

  //   documento = retorno.objeto



  //   this.listarDocumentoItem(documento);
  //   this.listarTipoDocumentoProcessoItem(documento.seqTipoDocumentoProcesso);
  //   this.listarDocumentoRelacionamento(documento);
  //   this.listarCaracteristicaDocumento(documento);
  //   this.listarDocumentoArquivo(documento);
  //   this.listarDocumentoAtendimento(documento);
  // }


  async salvarDocumentoCertificado() {
    let documento = this.state.documento
    if (documento.tagd1 !== '' && documento.tags6 !== '') {
      let retornoDocumento = await this.documentoService.salvar(documento);
      this.setState({
        documento: retornoDocumento.objeto,
        mensagemVisivel: true,
        mensagem: "Informações salvas com sucesso!",
        documento: documento
      })
      console.log('CERTIFICADO SALVO - PRONTO PARA SER GERADO');
    }
  }


  selecionarDocumentoItem(pItem) {
    console.log(pItem);
    this.setState({
      documentoItem: pItem,
      quantidade: pItem.quantidade,
      mensagemVisivel: false,
    });
  }

  async novo() {
    let documento = new Documento();
    this.setState({
      documentoItem: new DocumentoItem(),
    });

    documento.seqUsuario = this.constante.seqUsuario;
    documento.seqUsuarioVendedor = this.state.usuario.seqUsuario;
    documento.seqTipoDocumento = this.constante.seqTipoDocumento;

    let retorno = await this.documentoService.novo(documento);
    documento = retorno.objeto
    this.setState({
      documento: retorno.objeto,
      tela: "2",
    });

  }

  async deletarItem() {
    await this.documentoService.deletarItem(this.state.documentoItem);
    this.setState({
      documentoItem: new DocumentoItem(),
      popupVisivel: false,
    });
    this.listarDocumentoItem();
  }

  async selecionar(pDocumento) {
    let documento = pDocumento;

    this.setState({
      carregando: true,
      dropdownVisivel: false,
      mensagemVisivel: false,
      documento: documento,
      tela: "2",
    });

    await this.listarDocumentoItem(documento);
    await this.listarTipoDocumentoProcessoItem(documento.seqTipoDocumentoProcesso);
    await this.listarDocumentoRelacionamento(documento);
    await this.listarCaracteristicaDocumento(documento);
    await this.listarDocumentoArquivo(documento);
    await this.listarDocumentoAtendimento(documento);

    this.setState({ carregando: false, })
  }

  buscarTipoAtendimento(pSeqTipoAtendimento) {
    let listaTipoAtendimento = this.state.listaTipoAtendimento;
    console.log(pSeqTipoAtendimento)

    console.log(listaTipoAtendimento)

    for (let i = 0; i < listaTipoAtendimento.length; i++) {
      if (listaTipoAtendimento[i].seqTipoAtendimento === Number(pSeqTipoAtendimento)) {
        this.setState({
          tipoAtendimento: listaTipoAtendimento[i]
        })
        console.log(listaTipoAtendimento[i])
      }
    }
  }

  selecionarDocumentoAtendimento(pDocumentoAtendimento) {
    let documentoAtendimento = pDocumentoAtendimento;
    this.setState({
      documentoAtendimento: documentoAtendimento,
    })
    this.buscarTipoAtendimento(documentoAtendimento.seqDocumentoAtendimento);
  }

  novoDocumentoAtendimento() {
    this.tipoAtendimento = this.state.tipoAtendimento;
    this.tipoAtendimento = new TipoAtendimento();
    console.log(this.tipoAtendimento.seqTipoAtendimento);


    this.setState({
      documentoAtendimento: new DocumentoAtendimento(),
      tipoAtendimento: new TipoAtendimento(),
    })
  }


  async listarDocumentoAtendimento(pDocumento) {
    let documento;

    if (pDocumento) {
      documento = pDocumento;
    } else {
      documento = this.state.documento;
    }

    let retornoListaDocumentoAtendimento = await this.documentoService.listarAtendimento(documento);
    this.setState({
      listaDocumentoAtendimento: retornoListaDocumentoAtendimento.objeto
    })
    console.log(retornoListaDocumentoAtendimento.objeto);
  }

  alerta_deletarDocumentoAtendimento() {
    this.setState({
      popupDeletarDocumentoAtendimentoVisivel: true
    })
  }

  async deletarDocumentoAtendimento() {
    await this.documentoService.deletarAtendimento(this.state.documentoAtendimento);

    this.setState({
      documentoAtendimento: new DocumentoAtendimento(),
      popupDeletarDocumentoAtendimentoVisivel: false,
    })

    this.novoDocumentoAtendimento();
    this.listarDocumentoAtendimento();
  }


  async salvarDocumentoAtendimento() {
    let documentoAtendimento = this.state.documentoAtendimento;
    documentoAtendimento.seqDocumento = this.state.documento.seqDocumento;
    documentoAtendimento.seqUsuario = this.state.documento.seqUsuario;

    console.log(documentoAtendimento)
    await this.documentoService.salvarAtendimento(documentoAtendimento);

    this.novoDocumentoAtendimento();
    this.listarDocumentoAtendimento();
  }

  alerta_deletarDocumentoArquivo(pItem) {
    this.setState({
      documentoArquivo: pItem,
      popupDeletarDocumentoArquivoVisivel: true
    });
  }


  selecionarDocumentoArquivo(pDocumentoArquivo) {
    let documentoArquivo = pDocumentoArquivo;
    this.setState({
      documentoArquivo: documentoArquivo,
    })
    this.buscarTipoArquivo(documentoArquivo.seqTipoDocumentoArquivo);
  }



  async listarTipoDocumentoProcessoItem(pSeqTipoDocumentoProcesso) {
    let retorno =
      await this.tipoDocumentoProcessoService.listarTipoDocumentoProcessoItem(
        pSeqTipoDocumentoProcesso
      );
    this.setState({ listaTipoDocumentoProcessoItem: retorno.objeto });
    console.log(retorno.objeto);
  }

  selecionarTabelaPreco(pSeqItem) {
    console.log(pSeqItem);
    let listaTabelaPreco = this.state.listaTabelaPreco;
    let tabelaPreco = new TabelaPreco();

    for (let i = 0; i < listaTabelaPreco.length; i++) {
      if (listaTabelaPreco[i].seqItem === Number(pSeqItem)) {
        tabelaPreco = listaTabelaPreco[i];
        console.log(tabelaPreco);
      }
    }

    this.setState({
      tabelaPreco: tabelaPreco,
      mensagemVisivel: false,
    });
  }

  selecionarPessoa(pSeqPessoa) {
    console.log(pSeqPessoa);
    let listaPessoa = this.state.listaPessoa;
    let documento = this.state.documento;

    for (let i = 0; i < listaPessoa.length; i++) {
      if (listaPessoa[i].seqPessoa === Number(pSeqPessoa)) {
        documento.seqPessoa = listaPessoa[i].seqPessoa;
      }
    }

    console.log(documento);

    this.setState({
      documento: documento,
      mensagemVisivel: false,
    });
  }

  async listarPessoaRelacionamento(pPessoa) {
    let pessoa;

    if (pPessoa) {
      pessoa = pPessoa;
    } else {
      pessoa = this.state.pessoaUsuario;
    }

    let retornoListaPessoaRelacionamento = await this.pessoaService.listarRelacionamento(pessoa);
    this.setState({
      listaPessoaRelacionamento: retornoListaPessoaRelacionamento.objeto
    })
    console.log(retornoListaPessoaRelacionamento.objeto);
  }


  mostrarMensagem(pTipo, pMensagem) {
    if (pTipo === "0") {
    }

    if (pTipo === "1") {
      this.setState({
        mensagemVisivel: true,
        mensagem: pMensagem,
      });
    }

    if (pTipo === "2") {
    }
  }

  async salvarItem() {
    this.setState({ carregando: true, });
    let documentoItem = new DocumentoItem();

    if (this.state.documentoItem.seqDocumento) {
      documentoItem = this.state.documentoItem;
    }

    if (this.state.tabelaPreco) {
      documentoItem.seqItem = this.state.tabelaPreco.seqItem;
    }

    documentoItem.quantidade = this.state.quantidade;
    documentoItem.seqDocumento = this.state.documento.seqDocumento;
    documentoItem.seqUsuario = this.constante.seqUsuario;

    console.log(documentoItem)

    let retorno = await this.documentoService.salvarItem(documentoItem);
    console.log(retorno);

    this.listarDocumentoItem();

    this.setState({
      documentoItem: new DocumentoItem(),
      tabelaPreco: new TabelaPreco(),
      quantidade: 1,
      carregando: false,
    });

  }

  async deletar() {
    await this.documentoService.deletar(this.state.documento);
    this.setState({
      popupExcluirDocumentoVisivel: false,
      tela: "1",
    });
    // this.listar();
  }

  async mudarStatusDocumentoProcesso(pStatus) {
    let documentoAtendimento = new DocumentoAtendimento();
    let documento = this.state.documento;
    documento.seqTipoDocumentoProcesso = pStatus.seqTipoDocumentoProcessoDestino;

    documentoAtendimento.seqTipoAtendimento = this.constante.seqTipoAtendimentoPadrao;
    documentoAtendimento.seqDocumento = documento.seqDocumento;
    documentoAtendimento.descricao = 'Troca de status do documento de ' + pStatus.origem + ' para ' + pStatus.destino + '. [Usuário: ' + this.state.pessoaUsuario.nome + ']';

    await this.documentoService.salvar(documento);
    await this.documentoService.salvarAtendimento(documentoAtendimento);

    let retorno = await this.listarVwContadorDocumento();
    this.setState({
      dropdownVisivel: false,
      tela: "1",
      listaVwContadorDocumento: retorno.objeto
    });

    // this.listar();
  }

  async selecionarTipoDocumentoProcesso(pItem) {
    this.setState({ vwContadorDocumento: pItem });

    let retornoListaDocumento = await this.listarDocumentoComFiltro(pItem.seqTipoDocumentoProcesso);
    console.log(retornoListaDocumento);
    this.setState({ listaDocumento: retornoListaDocumento.objeto });
  }


  // DOCUMENTO RELACIONAMENTO

  buscarTipoRelacionamento(pSeqTipoRelacionamento) {
    let listaTipoRelacionamento = this.state.listaTipoRelacionamento;
    console.log(pSeqTipoRelacionamento)

    for (let i = 0; i < listaTipoRelacionamento.length; i++) {
      if (listaTipoRelacionamento[i].seqTipoRelacionamento === Number(pSeqTipoRelacionamento)) {
        this.setState({
          tipoRelacionamento: listaTipoRelacionamento[i]
        })
        console.log(listaTipoRelacionamento[i])
      }
    }
  }

  async listarDocumentoRelacionamento(pDocumento) {
    let documento;

    if (pDocumento) {
      documento = pDocumento;
    } else {
      documento = this.state.documento;
    }

    let retornoListaDocumentoRelacionamento = await this.documentoService.listarRelacionamento(documento);
    this.setState({
      listaDocumentoRelacionamento: retornoListaDocumentoRelacionamento.objeto
    })
    console.log(retornoListaDocumentoRelacionamento.objeto);
  }

  novoDocumentoRelacionamento() {
    this.setState({
      documentoRelacionamento: new DocumentoRelacionamento(),
      tipoRelacionamento: new TipoRelacionamento(),
    })
  }

  async salvarDocumentoRelacionamento() {
    let documentoRelacionamento = this.state.documentoRelacionamento;
    documentoRelacionamento.seqDocumento = this.state.documento.seqDocumento;
    documentoRelacionamento.seqUsuario = this.state.documento.seqUsuario;

    if (!documentoRelacionamento.situacao) {
      documentoRelacionamento.situacao = 'ATIVO'
    }

    console.log(documentoRelacionamento);
    await this.documentoService.salvarRelacionamento(documentoRelacionamento);

    this.novoDocumentoRelacionamento();
    this.listarDocumentoRelacionamento();
  }

  alerta_deletarDocumentoRelacionamento() {
    this.setState({
      popupDeletarDocumentoRelacionamentoVisivel: true
    })
  }

  async deletarDocumentoRelacionamento() {
    await this.documentoService.deletarRelacionamento(this.state.documentoRelacionamento);

    this.setState({
      documentoRelacionamento: new DocumentoRelacionamento(),
      popupDeletarDocumentoRelacionamentoVisivel: false,
    })

    this.novoDocumentoRelacionamento();
    this.listarDocumentoRelacionamento();
  }

  selecionarDocumentoRelacionamento(pDocumentoRelacionamento) {
    let documentoRelacionamento = pDocumentoRelacionamento;
    this.setState({
      documentoRelacionamento: documentoRelacionamento,
    })
    this.buscarTipoRelacionamento(documentoRelacionamento.seqTipoRelacionamento);
  }

  // CRUD ARQUIVO 

  novoDocumentoArquivo() {
    this.setState({
      documentoArquivo: new DocumentoArquivo(),
      tipoArquivo: new TipoArquivo(),
    })
  }

  async deletarDocumentoArquivo() {
    await this.documentoService.deletarArquivo(this.state.documentoArquivo);

    this.setState({
      documentoArquivo: new DocumentoArquivo(),
      popupDeletarDocumentoArquivoVisivel: false,
    })

    this.novoDocumentoArquivo();
    this.listarDocumentoArquivo();
  }

  

  buscarTipoArquivo(pSeqTipoArquivo) {
    let listaTipoArquivo = this.state.listaTipoArquivo;
    console.log(pSeqTipoArquivo)

    for (let i = 0; i < listaTipoArquivo.length; i++) {
      if (listaTipoArquivo[i].seqTipoArquivo === Number(pSeqTipoArquivo)) {
        this.setState({
          tipoArquivo: listaTipoArquivo[i]
        })
        console.log(listaTipoArquivo[i])
      }
    }
  }

  async salvarDocumentoArquivo() {
    this.setState({ carregando: true });
    this.documentoArquivo = this.state.documentoArquivo;
    this.documentoArquivo.tipoArquivoNome = this.state.tipoArquivo.nome;
    this.documentoArquivo.seqTipoArquivo = this.state.tipoArquivo.seqTipoArquivo;
    this.documentoArquivo.seqDocumento = this.state.documento.seqDocumento;
    console.log(this.documentoArquivo)
    await this.documentoService.salvarArquivo(this.documentoArquivo);

    this.setState({
      documentoArquivo: new DocumentoArquivo(),
      tipoArquivo: new TipoArquivo(),
      carregando: false,
    })

    this.listarDocumentoArquivo()
  }

  async listarDocumentoArquivo(pDocumento) {
    if (pDocumento) {
      this.documento = pDocumento;
    } else {
      this.documento = this.state.documento;
    }
    this.listaDocumentoArquivo = await this.documentoService.listarArquivo(this.documento);
    this.setState({ listaDocumentoArquivo: this.listaDocumentoArquivo.objeto });
    console.log(this.listaDocumentoArquivo.objeto)
  }


  buscarTabelaPrecoItem(pItem) {
    console.log(pItem)
    this.listaTabelaPreco = this.state.listaTabelaPreco;
    console.log(this.listaTabelaPreco)
    for (let i = 0; i < this.listaTabelaPreco.length; i++) {
      if (this.listaTabelaPreco[i].seqTabelaPrecoItem === Number(pItem)) {
        this.setState({
          tabelaPreco: this.listaTabelaPreco[i],
        })
        console.log(this.listaTabelaPreco[i]);
      }
    }
  }

  formatarParaUsarNoValue(pData) {
    console.log(pData);
    return
    this.data = String(pData);
    this.data = this.data.split('T')[0]
    this.data = this.data.split('-').reverse().join('-')
    return this.data;
  }

  navegarParaCertificado() {
    this.setState({
      navegarParaCertificado: true
    })
    console.log(this.state.navegarParaCertificado);
  }


  async selecionarArquivoAnexo(pEvento, item) {
    this.arquivo = pEvento.target.files[0];
    console.log(this.arquivo)
    const reader = new FileReader();
    let arquivoDocumento = this.state.documentoArquivo

    reader.addEventListener('load', () => arquivoDocumento.arquivo = reader.result.split(',')[1])

    if (this.arquivo) {
      reader.readAsDataURL(this.arquivo);
      arquivoDocumento.arquivoOriginal = this.arquivo.name
    }

    arquivoDocumento.seqDocumento = this.state.documento.seqDocumento;
    arquivoDocumento.seqUsuario = this.constante.seqUsuario;
    arquivoDocumento.seqTipoArquivo = item.seqTipoArquivo;

    console.log(arquivoDocumento);
    await this.documentoService.deletarArquivo(item);
    let retornoArquivoDocumento = await this.documentoService.salvarArquivo(arquivoDocumento);
    retornoArquivoDocumento = retornoArquivoDocumento.objeto
    console.log(retornoArquivoDocumento);
    this.listarDocumentoArquivo()
  }



  render() {
    return (
      <>
        <Loading visivel={this.state.carregando} />
        {this.state.tela === "1" ? (
          <Container>
            <Header selecionado="documento" />
            <div className="sistema-wrapper-lista">
              <Sidebar>
                <nav
                  style={{
                    width: "100%",
                    height: "100%",
                    overflowY: "scroll",
                  }}
                >
                  {this.state.listaDepartamento.map((itemDepartamento, indexD) => (
                    <div key={indexD} className="sistema-sidebar-documento-filtro">
                      <h1 style={{ marginTop: "10px" }}>
                        {itemDepartamento.nome}
                      </h1>
                      {this.state.listaVwContadorDocumento.map(
                        (itemTipoDocumentoProcesso, indexDP) =>
                          itemTipoDocumentoProcesso.seqDepartamento ===
                          itemDepartamento.seqDepartamento && (
                            <button
                              key={indexDP}
                              onClick={() =>
                                this.selecionarTipoDocumentoProcesso(
                                  itemTipoDocumentoProcesso
                                )
                              }
                              className={`btn-lg ${this.state.vwContadorDocumento ===
                                itemTipoDocumentoProcesso
                                ? "btn-com-fundo"
                                : "btn-secundario"
                                }`}
                            >
                              {itemTipoDocumentoProcesso?.tipoDocumentoProcessoNome}
                            </button>
                          )
                      )}
                    </div>
                  ))}
                </nav>
              </Sidebar>
              <div className="sistema-conteudo-wrapper">
                <section className="sistema-input-wrapper">
                  <h1>Requerimento</h1>
                </section>

                <table className="sistema-table">
                  <thead className="sistema-thead">
                    <tr className="sistema-tr-head">
                      <th>Id</th>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Opção</th>
                    </tr>
                  </thead>
                  <tbody className="sistema-tbody" style={{ maxHeight: 535 }}>
                    {this.state.listaDocumento.map((item, index) => (
                      <>
                        <tr key={index} className="sistema-tr-body">
                          <td className="sistema-td">{item.seqDocumento}</td>
                          <td className="sistema-td">{item.pessoaNome}</td>
                          <td className="sistema-td">{item.pessoaEmail}</td>
                          <td className="sistema-td">
                            <button
                              onClick={() => this.selecionar(item)}
                              className="btn btn-icone btn-com-fundo"
                            >
                              <FaPen />
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>

                {this.state.listaDocumento.length === 0 ?
                  <p style={{ textAlign: "center" }}>
                    Nenhuma informação encontrada
                  </p>
                  : null}
              </div>
            </div>
          </Container>
        ) : this.state.tela === "2" ? (
          <>
            <PopUp
              visivel={this.state.popupDeletarDocumentoArquivoVisivel}
              msg={"Deseja realmente excluir o arquivo " + this.state.documentoArquivo.arquivoOriginal + " ?"}
              aoConfirmar={() => this.deletarDocumentoArquivo()}
              aoCancelar={() => this.setState({ popupDeletarDocumentoArquivoVisivel: false, })}
            />


            <PopUp
              visivel={this.state.popupDeletarDocumentoRelacionamentoVisivel}
              msg={"Deseja realmente excluir o relacionamento com " + this.state.documentoRelacionamento.pessoaRelacionadaNome + " ?"}
              aoConfirmar={() => this.deletarDocumentoRelacionamento()}
              aoCancelar={() => this.setState({ popupDeletarDocumentoRelacionamentoVisivel: false, })}
            />

            <PopUp
              visivel={this.state.popupExcluirDocumentoVisivel}
              msg={this.state.mensagemExcluir}
              aoConfirmar={() => this.deletar()}
              aoCancelar={() =>
                this.setState({ popupExcluirDocumentoVisivel: false })
              }
            />

            <PopUp
              visivel={this.state.popupVisivel}
              msg={this.state.mensagemExcluir}
              aoConfirmar={() => this.deletarItem()}
              aoCancelar={() => this.setState({ popupVisivel: false })}
            />
            <PopUp
              visivel={this.state.popupDeletarDocumentoAtendimentoVisivel}
              msg={"Deseja realmente excluir o atendimento " + this.state.documentoAtendimento.tipoAtendimentoNome + " ?"}
              aoConfirmar={() => this.deletarDocumentoAtendimento()}
              aoCancelar={() => this.setState({ popupDeletarDocumentoAtendimentoVisivel: false, })}
            />

            <Container>
              <div className="sistema-wrapper-detalhe">
                <Header selecionado="documento" />

                <section className="sistema-input-wrapper">
                  <MsgOK
                    visivel={this.state.mensagemVisivel}
                    msg={this.state.mensagem}
                  />
                  <div className="sistema-detalhe-voltar">
                    <button
                      onClick={() => {
                        this.setState({
                          tela: "1",
                          mensagemVisivel: false,
                        });
                        // this.listar();
                      }}
                      className="btn btn-icone btn-com-fundo"
                    >
                      <FaArrowLeft />
                    </button>

                    <h1>Requerimento</h1>

                    <div className="sistema-detalhe-botao">
                      {this.state.pessoaUsuario.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrativo ?
                        <div className="btn-dropdown-wrapper">
                          <button
                            onClick={() =>
                              this.setState({
                                dropdownVisivel: !this.state.dropdownVisivel,
                              })
                            }
                            className="btn btn-com-fundo"
                          >
                            Enviar
                          </button>

                          <Dropdown visivel={this.state.dropdownVisivel}>
                            {this.state.listaTipoDocumentoProcessoItem.map(
                              (item, index) => (
                                <button
                                  key={index}
                                  onClick={() =>
                                    this.mudarStatusDocumentoProcesso(item)
                                  }
                                  className="dropdown-option"
                                >
                                  {item.destino}
                                </button>
                              )
                            )}
                          </Dropdown>
                        </div> : null}
                    </div>
                  </div>
                  <form className="sistema-form-wrapper">
                    <div>
                      <label>Nome</label><br />
                      <h1>{this.state.documento.pessoaNome}</h1>
                    </div>

                    <section>
                      <label>CPF</label><br />
                      <h1>{this.state.documento.pessoaDocumento}</h1>
                    </section>

                    <div>
                      <label>Data nascimento</label>
                      <br />
                      <h1>{this.retornaDataFormatada(this.state.documento.tagd0)}</h1>
                    </div>


                    <div>
                      <label>Data de cadastro</label>
                      <br />
                      <h1>{this.retornaDataFormatada(this.state.documento.dataCadastro)}</h1>
                    </div>
                  </form>

                  <form className="sistema-form-wrapper">

                    <section>
                      <label>RG</label><br />
                      <h1>{this.state.documento.tags2}</h1>
                    </section>

                    <section>
                      <label>Orgão emissor</label><br />
                      <h1>{this.state.documento.tags3}</h1>
                    </section>

                    <section>
                      <label>Nacionalidade</label><br />
                      <h1>{this.state.documento.tags4}</h1>
                    </section>
                  </form>

                  <form className="sistema-form-wrapper">
                    <section>
                      <label>Natural de</label><br />
                      <h1>{this.state.documento.tags5}</h1>
                    </section>

                    <section>
                      <label>Endereço</label><br />
                      <h1>{this.state.documento.endLogradouro} {this.state.documento.endNumero} - {this.state.documento.endBairro}, {this.state.documento.endCidade}</h1>
                    </section>

                    <div>
                      <label>UF</label><br />
                      <h1>{this.state.documento.endUF}</h1>
                    </div>
                  </form>


                  <form className="sistema-form-wrapper">
                    <div>
                      <label>telefone 1</label><br />
                      <h1>{this.state.documento.tags0}</h1>
                    </div>
                    <div>
                      <label>telefone 2</label><br />
                      <h1>{this.state.documento.tags1}</h1>
                    </div>
                    <div>
                      <label>Email</label><br />
                      <h1>{this.state.documento.pessoaEmail}</h1>
                    </div>
                  </form>
                </section>
                {this.state.documento.seqDocumento ? <div className="sistema-estrutura-pessoa">
                  <div className="sistema-sidebar-detalhe">

                    <button
                      onClick={() => this.setState({ tag: "dados_serviços" })}
                      className={`btn btn-lg ${this.state.tag === "dados_serviços"
                        ? "btn-com-fundo"
                        : "btn-secundario"
                        }`}
                    >Dados do serviço</button>
                    <button
                      onClick={() => this.setState({ tag: 'Caracteristicas' })}
                      className={`btn btn-lg ${this.state.tag === 'Caracteristicas' ?
                        'btn-com-fundo' :
                        'btn-secundario'
                        }`}>Características</button>
                    <button
                      onClick={() => this.setState({ tag: "Relacionamentos" })}
                      className={`btn btn-lg ${this.state.tag === "Relacionamentos"
                        ? "btn-com-fundo"
                        : "btn-secundario"
                        }`}
                    >Relacionamentos</button>
                    <button
                      onClick={() => this.setState({ tag: "Arquivos_anexados" })}
                      className={`btn btn-lg ${this.state.tag === "Arquivos_anexados"
                        ? "btn-com-fundo"
                        : "btn-secundario"
                        }`}
                    >Documentos anexados</button>
                    <button
                      onClick={() => this.setState({ tag: 'Atendimento' })}
                      className={`btn btn-lg ${this.state.tag === 'Atendimento' ?
                        'btn-com-fundo' :
                        'btn-secundario'
                        }`}>Atendimento</button>


                    <button
                      onClick={() => this.setState({ tag: 'Certificado' })}
                      className={`btn btn-lg ${this.state.tag === 'Certificado' ?
                        'btn-com-fundo' :
                        'btn-secundario'
                        }`}>Certificado</button>


                  </div>

                  <div className="sistema-campo-detalhe">

                    {this.state.tag === "dados_serviços" ? (
                      <>
                        <table>
                          <thead className="sistema-thead">
                            <tr className="sistema-tr-head">
                              <th>Nome</th>
                            </tr>
                          </thead>

                          <tbody
                            style={{
                              maxHeight: 250,
                            }}
                            className="sistema-tbody"
                          >
                            {this.state.listaDocumentoItem.map((item, index) => (
                              <tr key={index} className="sistema-tr-body">
                                <td>{item.itemNomeVitrine}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : null}

                    {this.state.tag === 'Caracteristicas' ?
                      <div
                        className='sistema-campo-detalhe-form'>
                        <div className='sistema-campo-detalhe-input-wrapper'>
                          {this.state.listaDocumentoCaracteristica.map((item, index) => (
                            item.marcado === 'true' ? <label> <strong>Estado civil aluno: </strong> {item.nome}</label> : null))}
                        </div>
                      </div>
                      : null}

                    {this.state.tag === 'Relacionamentos' ?
                      <>
                        <table className='sistema-table'>
                          <thead className='sistema-thead'>
                            <tr className='sistema-tr-head'>
                              <th>Pessoa</th>
                              <th>Tipo de Relacionamento</th>
                              <th>Dt. Cadastro</th>
                            </tr>
                          </thead>
                          <tbody
                            className='sistema-tbody'
                            style={{ maxHeight: 370 }}>
                            {this.state.listaDocumentoRelacionamento.map((item, index) => (
                              <tr key={index} className='sistema-tr-body'>
                                <td className='sistema-td'>{item.pessoaRelacionadaNome}</td>
                                <td className='sistema-td'>{item.tipoRelacionamentoNome}</td>
                                <td className='sistema-td'>{this.retornaDataFormatada(item.dataCadastro)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                      : null}

                    {this.state.tag === 'Arquivos_anexados' ?
                      <>
                        <div>
                        </div>
                        <table className='sistema-table'>
                          <thead className='sistema-thead'>
                            <tr className='sistema-tr-head'>
                              <th>Tipo de Documento</th>
                              <th>Nome</th>
                              <th>Dt. Cadastro</th>
                              <th>Reenviar arquivo</th>
                            </tr>
                          </thead>
                          <tbody
                            className='sistema-tbody'
                            style={{ maxHeight: 440 }}>
                            {this.state.listaDocumentoArquivo.map((item, index) => (
                              <tr key={index} className='sistema-tr-body'>
                                <td className='sistema-td'>{item.tipoArquivoNome}</td>
                                <td className='sistema-td'><a href={this.state.urlBaseUrlArquivoDocumento + item.arquivo} rel="noreferrer" target="_blank">{item.arquivoOriginal}</a></td>
                                <td className='sistema-td'>{this.retornaDataFormatada(item.dataCadastro)}</td>
                                <td>
                                  <div style={{ width: 120 }}>
                                    <Input titulo='Reenviar'
                                      style={{ width: 140 }}
                                      type='file'
                                      onChange={pEvento => this.selecionarArquivoAnexo(pEvento, item)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                      : null}

                    {this.state.tag === 'Atendimento' ?
                      <>
                        <div
                          className='sistema-campo-detalhe-form'>
                          <div className='sistema-campo-detalhe-input-wrapper-atendimento'>
                            <div>
                              <label>Tipo de atendimento</label><br />
                              <select
                                style={{ width: '100%' }}
                                value={this.state.documentoAtendimento.seqTipoAtendimento}
                                onChange={pEvento => this.setState({
                                  documentoAtendimento: {
                                    ...this.state.documentoAtendimento,
                                    seqTipoAtendimento: Number(pEvento.target.value)
                                  }
                                })}
                                className='input'>

                                <option selected={this.state.documentoAtendimento.seqTipoAtendimento === undefined ?
                                  true : false} value={null}>Selecione um tipo de atendimento</option>

                                {this.state.listaTipoAtendimento.map((item, index) => (
                                  <option
                                    key={index}
                                    selected={
                                      this.state.tipoAtendimento.seqTipoAtendimento === item.seqTipoAtendimento ?
                                        true : false
                                    }
                                    value={item.seqTipoAtendimento}>{item.nome}</option>
                                ))}
                              </select>
                            </div>

                            <div>
                              <label>Situação</label><br />
                              <select
                                value={this.state.documentoAtendimento.situacao}
                                onChange={pEvento => this.setState({
                                  documentoAtendimento: {
                                    ...this.state.documentoAtendimento,
                                    situacao: pEvento.target.value
                                  }
                                })}
                                style={{ width: '100%' }}
                                className='input'>
                                <option value="ATIVO">ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <label>Descrição</label><br />
                            <textarea
                              style={{ width: '100%', height: 100 }}
                              className="input"
                              onChange={pTexto => this.setState({
                                documentoAtendimento: {
                                  ...this.state.documentoAtendimento,
                                  descricao: pTexto.target.value
                                }
                              })}
                              value={this.state.documentoAtendimento.descricao || ''}
                            />
                          </div>
                          <div className='sistema-campo-detalhe-button-wrapper'>
                            {this.state.documentoAtendimento.seqDocumentoAtendimento ?
                              <button
                                onClick={() => this.alerta_deletarDocumentoAtendimento()}
                                className='btn btn-secundario'
                                style={{
                                  borderColor: 'red',
                                  color: 'red'
                                }}
                              >
                                Excluir
                              </button>
                              : null}
                            {this.state.documentoAtendimento.seqDocumentoAtendimento ? <button
                              onClick={() => this.novoDocumentoAtendimento()}
                              className='btn btn-secundario'>Cancelar</button> : null}

                            <button
                              onClick={() => this.salvarDocumentoAtendimento()}
                              className='btn btn-com-fundo'>Salvar</button>
                          </div>
                        </div>

                        <table className='sistema-table'>
                          <thead className='sistema-thead'>
                            <tr className='sistema-tr-head'>
                              <th>Tipo de Atendimento</th>
                              <th>Descricao</th>
                              <th>Dt. Cadastro</th>
                            </tr>
                          </thead>
                          <tbody
                            className='sistema-tbody'
                            style={{ maxHeight: 480 }}>
                            {this.state.listaDocumentoAtendimento.map((item, index) => (
                              <tr key={index} className='sistema-tr-body'>
                                <td className='sistema-td'>{item.tipoAtendimentoNome}</td>
                                <td className='sistema-td'>{item.descricao}</td>
                                <td className='sistema-td'>{this.retornaDataFormatada(item.dataCadastro)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                      : null}


                    {this.state.tag === 'Certificado' ?
                      <>
                        <div className='sistema-campo-detalhe-form'>
                          <div className='sistema-campo-detalhe-input-wrapper-atendimento'>
                            <Input
                              titulo='Data de conclusão'
                              type='date'
                              onChange={pTexto => this.setState({
                                documento: {
                                  ...this.state.documento, tagd1: pTexto.target.value
                                }
                              })}
                            // value={this.formatarParaUsarNoValue(this.state.documento.tagd1) || ''}
                            />
                            {/* <Input
                              titulo='Eixo do curso'
                              onChange={pTexto => this.setState({
                                documento: {
                                  ...this.state.documento, tags6: pTexto.target.value
                                }
                              })}
                              value={this.state.documento.tags6 || ''}
                            /> */}

                            <Input
                              titulo='Ano assinatura'
                              onChange={pTexto => this.setState({
                                documento: {
                                  ...this.state.documento, tags7: pTexto.target.value
                                }
                              })}
                              value={this.state.documento.tags7 || ''}
                            />


                            <Input
                              titulo='Local assinatura'
                              onChange={pTexto => this.setState({
                                documento: {
                                  ...this.state.documento, tags8: pTexto.target.value
                                }
                              })}
                              value={this.state.documento.tags8 || ''}
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: 'center', gap: 20 }}>
                            <button
                              onClick={() => this.salvarDocumentoCertificado()}
                              className='btn  btn-sem-fundo'
                            >
                              Salvar
                            </button>
                            <button
                              className='btn btn-com-fundo'><Link style={{ color: 'white' }} to={'/certificadoTec?seq_documento=' + this.state.documento.seqDocumento} target='_blank'>Gerar certificado</Link></button>
                          </div>
                        </div>
                      </>
                      : null}
                  </div>
                </div>
                  : null}
              </div>
            </Container>
          </>
        ) : null}
      </>
    );
  }
}
