import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Pessoa from '../pages/sistema/Pages/Pessoa';
import Financeiro from '../pages/sistema/Pages/Financeiro';
import Hotsite from '../pages/hotsite/Hotsite';
import Login from '../pages/hotsite/hotsite-components/Login/Login';
import Cadastro from '../pages/hotsite/hotsite-components/Login/Cadastro/Cadastro';
import RecuperarSenha from '../pages/hotsite/hotsite-components/Login/RecuperarSenha/RecuperarSenha';
import Documento from '../pages/sistema/Pages/Documento';
import Perfil from '../pages/sistema/Pages/Perfil';
import TermoDeUso from '../pages/hotsite/hotsite-components/TermosDeUso/TermoDeUso';
import AgenciamentoDeServiços from '../pages/hotsite/hotsite-components/AgenciamentoDeServiços/AgenciamentoDeServiços';
import Checkout from '../pages/checkout/Checkout';
import CertificadoEnsinoMedio from '../pages/certificados/medio/certificadoEnsinoMedio';
import CertificadoTecnico from '../pages/certificados/tecnico/certificadoTecnico';
import HistoricoEscolarTecnico from '../pages/certificados/tecnico/historicoEscolarTecnico';
import VersoCertificadoEm from '../pages/certificados/medio/versoCertificadoEm';
import VersoHistoricoEscolarEm from '../pages/certificados/medio/versoHistoricoEscolarEm';
import Matricula from '../pages/sistema/Pages/Matricula';

export default class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Navigate to="/login" />} />
                    {/* <Route index path="/principal" element={<Hotsite />} /> */}
                    <Route path="/pessoa" element={<Pessoa />} />
                    <Route path="/documento" element={<Documento />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/financeiro" element={<Financeiro />} />
                    <Route path="/perfil" element={<Perfil />} />
                    <Route path="/recuperar-senha" element={<RecuperarSenha />} />
                    <Route path="/cadastro" element={<Cadastro />} />
                    <Route path="/termo-de-uso" element={<TermoDeUso />} />
                    <Route path="/agenciamento" element={<AgenciamentoDeServiços />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/matricula" element={<Matricula />} />
                    
                    {/* CERTIFICADOS */}
                    <Route path="/certificadoEm" element={<CertificadoEnsinoMedio />} />
                    <Route path="/versoEm" element={<VersoCertificadoEm />} />
                    <Route path="/versoHistoricoEm" element={<VersoHistoricoEscolarEm />} />
                    <Route path="/certificadoTec" element={<CertificadoTecnico />} />
                    <Route path="/historicoTec" element={<HistoricoEscolarTecnico />} />
                </Routes>
            </BrowserRouter>
        )
    }
}