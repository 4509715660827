import Contato from "lirida-main-js-service/service/Contato/Contato";
import Pessoa from "lirida-main-js-service/service/Pessoa/Pessoa";
import PessoaService from "lirida-main-js-service/service/Pessoa/PessoaService";
import PessoaRelacionamento from "lirida-main-js-service/service/PessoaRelacionamento/PessoaRelacionamento";
import PessoaCaracteristicaPessoa from "lirida-main-js-service/service/PessoaCaracteristicaPessoa/PessoaCaracteristicaPessoa";
import PessoaCaracteristicaPessoaService from "lirida-main-js-service/service/PessoaCaracteristicaPessoa/PessoaCaracteristicaPessoaService";
import PessoaArquivo from "lirida-main-js-service/service/PessoaArquivo/PessoaArquivo";
import DocumentoCaracteristicaDocumento from 'lirida-main-js-service/service/DocumentoCaracteristicaDocumento/DocumentoCaracteristicaDocumento'
import DocumentoCaracteristicaDocumentoService from 'lirida-main-js-service/service/DocumentoCaracteristicaDocumento/DocumentoCaracteristicaDocumentoService';
import DocumentoService from "lirida-main-js-service/service/Documento/DocumentoService";
import DocumentoRelacionamento from "lirida-main-js-service/service/DocumentoRelacionamento/DocumentoRelacionamento";
import Documento from "lirida-main-js-service/service/Documento/Documento";
import DocumentoItem from "lirida-main-js-service/service/Documento/DocumentoItem";
import DocumentoArquivo from "lirida-main-js-service/service/DocumentoArquivo/DocumentoArquivo";
import React, { Component } from "react";
import TabelaPrecoService from "lirida-main-js-service/service/TabelaPreco/TabelaPrecoService";
import Input from "../../../component/Input/Input";
import Constante from "../../../util/Constante";
import Container from "../sistema-components/Container/Container";
import GoogleAPI from "lirida-main-js-service/service/Google/GoogleAPI";
import Endereco from "lirida-main-js-service/service/Endereco/Endereco";
import Logo from '../../../assets/image/logo.png'
import Header from "../sistema-components/Header/Header";
import Util from "../../../util/Util";
import { Navigate } from "react-router";
import Loading from '../../../component/Loading/Loading';



export default class Matricula extends Component {
    constante = new Constante();
    pessoaService = new PessoaService();
    pessoaCaracteristicaPessoaService = new PessoaCaracteristicaPessoaService();
    documentoCaracteristicaDocumentoService = new DocumentoCaracteristicaDocumentoService();
    googleAPI = new GoogleAPI();
    tabelaPrecoService = new TabelaPrecoService();
    documentoService = new DocumentoService();
    util = new Util();

    state = {
        limparTela: false,
        pessoa: new Pessoa(),
        pessoaMae: new Pessoa(),
        pessoaPai: new Pessoa(),
        pessoaUsuario: new Pessoa(),
        pessoaConjuge: new Pessoa(),
        pessoaRelacionamento: new PessoaRelacionamento(),
        pessoaCaracteristicaPessoaEstadoCivil: new PessoaCaracteristicaPessoa(),
        contatoFixo: new Contato(),
        contatoCelular: new Contato(),
        contatoEmail: new Contato(),
        endereco: new Endereco(),
        listaEnderecoGoogle: [],
        pessoaArquivoRg: new PessoaArquivo(),
        pessoaArquivoCpf: new PessoaArquivo(),
        pessoaArquivoComprovanteResidencia: new PessoaArquivo(),
        pessoaArquivoCertidaoNascimento: new PessoaArquivo(),
        pessoaArquivoCertidaoCasamento: new PessoaArquivo(),
        pessoaArquivoHistoricoEscolar: new PessoaArquivo(),
        pessoaArquivoCertificadoEnsinoMedio: new PessoaArquivo(),
        pessoaArquivoDiarioOficial: new PessoaArquivo(),
        pessoaArquivoFotoTresPorQuatro: new PessoaArquivo(),
        pessoaArquivoCertidaoReservista: new PessoaArquivo(),
        pessoaArquivoTituloEleitor: new PessoaArquivo(),
        pessoaArquivoQuitacaoEleitoral: new PessoaArquivo(),
        documento: new Documento(),
        documentoItem: new DocumentoItem(),
        documentoArquivoRg: new DocumentoArquivo(),
        documentoArquivoCpf: new DocumentoArquivo(),
        documentoArquivoComprovanteResidencia: new DocumentoArquivo(),
        documentoArquivoCertidaoNascimento: new DocumentoArquivo(),
        documentoArquivoCertidaoCasamento: new DocumentoArquivo(),
        documentoArquivoHistoricoEscolar: new DocumentoArquivo(),
        documentoArquivoCertificadoEnsinoMedio: new DocumentoArquivo(),
        documentoArquivoDiarioOficial: new DocumentoArquivo(),
        documentoArquivoFotoTresPorQuatro: new DocumentoArquivo(),
        documentoArquivoCertidaoReservista: new DocumentoArquivo(),
        documentoArquivoTituloEleitor: new DocumentoArquivo(),
        documentoArquivoQuitacaoEleitoral: new DocumentoArquivo(),
        documentoCaracteristicaEstadoCivil: new DocumentoCaracteristicaDocumento(),
        documentoRelacionamento: new DocumentoRelacionamento(),
        listaTabelaPreco: [],
        mudarTela: false,
        loadingVisivel: false,
    }


    async componentDidMount() {
        await this.iniciar();
    }

    async iniciar() {
        let pessoaUsuario = await this.util.buscarLocalStorage('PESSOA');
        this.setState({ pessoaUsuario: pessoaUsuario });

        if (this.state.pessoaUsuario.seqPessoaPerfil !== this.constante.seqPessoaPerfilPolo) {
            await this.util.removerLocalStorage('PESSOA');
            this.setState({ mudarTela: true });
        }

        let retornoTabelaPreco = await this.tabelaPrecoService.listarTabelaPreco(this.constante.seqTabelaPreco, '', '');
        console.log(retornoTabelaPreco);
        this.setState({ listaTabelaPreco: retornoTabelaPreco.objeto });
    }

    async salvar(pSubmit) {
        pSubmit.preventDefault();

        this.setState({
            loadingVisivel: true
        })
        let pessoa = await this.salvarPessoa();
        let pessoaMae = await this.salvarPessoaMae();
        let pessoaPai = await this.salvarPessoaPai();
        let pessoaConjuge = this.state.pessoaConjuge;

        // SALVAR ENDERECO
        let endereco = await this.salvarEndereco(pessoa);

        // SALVAR CONTATO
        await this.salvarContatoFixo(pessoa);
        await this.salvarContatoCelular(pessoa);
        await this.salvarContatoEmail(pessoa);

        // SALVAR DOCUMENTO
        let documento = await this.salvarDocumento(pessoa, endereco);
        await this.salvarDocumentoItem(documento);

        // SALVAR ARQUIVOS PESSOAS
        await this.salvarArquivoRg(pessoa);
        await this.salvarArquivoCpf(pessoa);
        await this.salvarArquivoComprovanteResidencia(pessoa);
        await this.salvarArquivoCertidaoNascimento(pessoa);
        await this.salvarArquivoHistoricoEscolar(pessoa);
        await this.salvarArquivoCertificadoEnsinoMedio(pessoa);
        await this.salvarArquivoFotoTresPorQuatro(pessoa);
        if (this.state.documento.tags9 !== 'Feminino') {

            await this.salvarArquivoCertidaoReservista(pessoa);
        }
        await this.salvarArquivoTituloEleitor(pessoa);
        await this.salvarDocumentoArquivoQuitacaoEleitoral(pessoa);

        // SALVAR CARACTERISTICA PESSOA
        // await this.salvarPessoaCaracteristicaPessoaEstadoCivil(pessoa);

        //SALVAR CARACTERISTICA DOCUMENTO
        await this.salvarDocumentoCaracteristicaPessoaEstadoCivil(documento);


        // DOCUMENTO RELACIONAMENTO
        await this.salvarDocumentoRelacionamentoPolo(documento)
        await this.salvarDocumentoRelacionamentoAluno(documento, pessoa);
        await this.salvarDocumentoRelacionamentoMae(documento, pessoaMae);
        await this.salvarDocumentoRelacionamentoPai(documento, pessoaPai);

        if (this.state.documentoCaracteristicaEstadoCivil.seqDocumentoCaracteristica === this.constante.seqDocumentoCaracteristicaCasado) {
            let documentoConjuge = await this.salvarDocumentoConjuge();
            await this.salvarDocumentoRelacionamentoConjuge(documento, documentoConjuge);
            await this.salvarArquivoCertidaoCasamento(documento);
            await this.salvarDocumentoArquivoCertidaoCasamento(documento);
        }


        // SALVAR ARQUIVOS DO DOCUMENTO
        await this.salvarDocumentoArquivoRg(documento);
        await this.salvarDocumentoArquivoCpf(documento);
        await this.salvarDocumentoArquivoComprovanteResidencia(documento);
        await this.salvarDocumentoArquivoCertidaoNascimento(documento);
        await this.salvarDocumentoArquivoHistoricoEscolar(documento);
        await this.salvarDocumentoArquivoCertificadoEnsinoMedio(documento);
        await this.salvarDocumentoArquivoFotoTresPorQuatro(documento);
        if (this.state.documento.tags9 !== 'Feminino'){
            await this.salvarDocumentoArquivoCertidaoReservista(documento);
        }
        await this.salvarDocumentoArquivoTituloEleitor(documento);
        await this.salvarDocumentoArquivoQuitacaoEleitoral(documento);

        this.setState({
            limparTela: true
        })

        alert("Informações armazenadas com sucesso");
    }

    async salvarPessoa() {
        let pessoa = this.state.pessoa;
        console.log(pessoa);
        console.log("FUNCAO SALVAR PESSOA");
        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilAluno;
        pessoa.tipoDocumento = 'CPF';
        let retornoPessoa = await this.pessoaService.salvar(pessoa);
        console.log(retornoPessoa);
        pessoa = retornoPessoa.objeto;
        return pessoa;
    }

    // async salvarPessoaCaracteristicaPessoaEstadoCivil(pPessoa) {
    //     let listaPessoaCaracteristicaPessoa = [];

    //     let pessoaCaracteristicaPessoaEstadoCivil = this.state.pessoaCaracteristicaPessoaEstadoCivil;
    //     pessoaCaracteristicaPessoaEstadoCivil.seqPessoa = pPessoa.seqPessoa;
    //     pessoaCaracteristicaPessoaEstadoCivil.marcado = 'true';

    //     listaPessoaCaracteristicaPessoa.push(pessoaCaracteristicaPessoaEstadoCivil);

    //     let retornoPessoaCaracteristicaPessoa = await this.pessoaCaracteristicaPessoaService.salvar(listaPessoaCaracteristicaPessoa);

    // }

    async salvarDocumentoCaracteristicaPessoaEstadoCivil(pDocumento) {
        let listaDocumentoCaracteristicaPessoaEstadoCivil = [];

        let documentoCaracteristicaPessoaEstadoCivil = this.state.documentoCaracteristicaEstadoCivil;
        documentoCaracteristicaPessoaEstadoCivil.seqDocumento = pDocumento.seqDocumento;
        documentoCaracteristicaPessoaEstadoCivil.marcado = 'true';
        listaDocumentoCaracteristicaPessoaEstadoCivil.push(documentoCaracteristicaPessoaEstadoCivil);

        await this.documentoCaracteristicaDocumentoService.salvar(listaDocumentoCaracteristicaPessoaEstadoCivil);
    }

    formatarCPF(pTexto) {
        this.setState({
            pessoa: {
                ...this.state.pessoa,
                documento: this.util.formatarCPF(pTexto)
            }
        })
    }

    formatarCelular(pTexto) {
        this.setState({
            documento: {
                ...this.state.documento, tags1: this.util.formatarTelefone(pTexto)
            }
        })
    }

    formatarTelefone(pTexto) {
        this.setState({
            documento: {
                ...this.state.documento, tags0: this.util.formatarTelefone(pTexto)
            }
        })
    }


    async salvarPessoaMae() {
        let pessoaMae = this.state.pessoaMae;
        pessoaMae.seqUsuario = this.constante.seqUsuario;
        pessoaMae.seqPessoaPerfil = this.constante.seqPessoaPerfilParenteAluno;
        let retornoPessoaMae = await this.pessoaService.salvar(pessoaMae);
        pessoaMae = retornoPessoaMae.objeto;
        return pessoaMae;
    }

    async salvarPessoaPai() {
        let pessoaPai = this.state.pessoaPai;
        pessoaPai.seqUsuario = this.constante.seqUsuario;
        pessoaPai.seqPessoaPerfil = this.constante.seqPessoaPerfilParenteAluno;
        let retornopessoaPai = await this.pessoaService.salvar(pessoaPai);
        pessoaPai = retornopessoaPai.objeto;
        return pessoaPai;
    }


    async salvarPessoaConjuge(pPessoaConjuge) {
        let pessoaConjuge;
        if (pPessoaConjuge) {
            pessoaConjuge = pPessoaConjuge;
        } else {
            pessoaConjuge = this.state.pessoaConjuge;
        }
        pessoaConjuge.seqUsuario = this.constante.seqUsuario;
        pessoaConjuge.seqPessoaPerfil = this.constante.seqPessoaPerfilParenteAluno;
        let retornopessoaConjuge = await this.pessoaService.salvar(pessoaConjuge);
        pessoaConjuge = retornopessoaConjuge.objeto;
        return pessoaConjuge;
    }


    async salvarContatoFixo(pPessoa) {
        // CONTATO 
        let pessoa = pPessoa;
        let contatoFixo = this.state.contatoFixo;
        contatoFixo.seqUsuario = this.constante.seqUsuario;
        contatoFixo.seqPessoa = pessoa.seqPessoa;
        contatoFixo.seqTipoContato = this.constante.seqTipoContatoCelular;
        console.log(contatoFixo);
        let retornoContatoFixo = await this.pessoaService.salvarContato(contatoFixo);
        contatoFixo = retornoContatoFixo.objeto;
    }

    async salvarContatoCelular(pPessoa) {
        let pessoa = pPessoa;
        let contatoCelular = this.state.contatoCelular;
        contatoCelular.seqUsuario = this.constante.seqUsuario;
        contatoCelular.seqPessoa = pessoa.seqPessoa;
        contatoCelular.seqTipoContato = this.constante.seqTipoContatoWhatsApp;
        let retornoContatoCelular = await this.pessoaService.salvarContato(contatoCelular);
        contatoCelular = retornoContatoCelular.objeto;
    }




    async salvarContatoEmail(pPessoa) {
        let pessoa = pPessoa;
        let contatoEmail = this.state.contatoEmail;
        contatoEmail.seqUsuario = this.constante.seqUsuario;
        contatoEmail.seqPessoa = pessoa.seqPessoa;
        contatoEmail.seqTipoContato = this.constante.seqTipoContatoEmail;
        let retornoContatoEmail = await this.pessoaService.salvarContato(contatoEmail);
        contatoEmail = retornoContatoEmail.objeto;
    }


    async salvarEndereco(pPessoa) {
        let endereco = this.state.endereco;
        endereco.seqUsuario = this.constante.seqUsuario;
        endereco.seqPessoa = pPessoa.seqPessoa;
        let retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
        endereco = retornoEndereco.objeto;
        return endereco;
    }

    async listarEnderecoGoogle(pTexto) {
        this.retorno = await this.googleAPI.listarEndereco(pTexto);
        console.log(this.retorno.predictions)
        this.setState({
            listaEnderecoGoogle: this.retorno.predictions
        })
        if (this.retorno.predictions.length === 1 && pTexto === this.retorno.predictions[0].description) {
            this.buscarEnderecoGoogle(this.retorno.predictions[0].place_id)
        }
    }

    async buscarEnderecoGoogle(pPlaceID) {
        console.log(pPlaceID)
        this.retorno = await this.googleAPI.buscarEndereco(pPlaceID);

        console.log(this.retorno.result.address_components[2].long_name)

        this.setState({
            endereco: {
                ...this.state.endereco,
                latitude: this.retorno.result.geometry.location.lat,
                longitude: this.retorno.result.geometry.location.lng,
                logradouro: this.retorno.result.address_components[1].long_name,
                bairro: this.retorno.result.address_components[2].long_name,
                numero: this.retorno.result.address_components[0].long_name,
                cidade: this.retorno.result.address_components[3].long_name,
                uf: this.retorno.result.address_components[4].short_name,
            }
        })

    }


    async selecionarArquivoRg(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoRg: {
                        ...this.state.pessoaArquivoRg,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoRg: {
                        ...this.state.documentoArquivoRg,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoRg: {
                    ...this.state.pessoaArquivoRg,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoRg: {
                    ...this.state.documentoArquivoRg,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }

    // SALVAR ARQUIVO RG EM PESSOA
    async salvarArquivoRg(pPessoa) {
        let pessoaArquivoRg = this.state.pessoaArquivoRg;
        pessoaArquivoRg.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoRg.seqPessoa = pPessoa.seqPessoa;
        pessoaArquivoRg.seqTipoArquivo = this.constante.seqTipoArquivoAnexoRg;
        let retornoArquivoRg = await this.pessoaService.salvarArquivo(pessoaArquivoRg);
        pessoaArquivoRg = retornoArquivoRg.objeto;
    }

    // SALVAR ARQUIVO RG EM DOCUMENTO
    async salvarDocumentoArquivoRg(pDocumento) {
        let documentoArquivoRg = this.state.documentoArquivoRg;
        documentoArquivoRg.seqUsuario = this.constante.seqUsuario;
        documentoArquivoRg.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoRg.seqTipoArquivo = this.constante.seqTipoArquivoAnexoRg;
        let retornoDocumentoArquivoRg = await this.documentoService.salvarArquivo(documentoArquivoRg);
        documentoArquivoRg = retornoDocumentoArquivoRg.objeto;
    }


    async selecionarArquivoCpf(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoCpf: {
                        ...this.state.pessoaArquivoCpf,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoCpf: {
                        ...this.state.documentoArquivoCpf,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoCpf: {
                    ...this.state.pessoaArquivoCpf,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoCpf: {
                    ...this.state.documentoArquivoCpf,
                    arquivoOriginal: this.arquivo.name

                }
            })
        }
    }


    // SALVANDO ARQUIVO CPF EM PESSOA
    async salvarArquivoCpf(pPessoa) {
        let pessoa = pPessoa
        let pessoaArquivoCpf = this.state.pessoaArquivoCpf;
        pessoaArquivoCpf.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoCpf.seqPessoa = pessoa.seqPessoa;
        pessoaArquivoCpf.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCpf;
        let retornoArquivoCpf = await this.pessoaService.salvarArquivo(pessoaArquivoCpf);
        pessoaArquivoCpf = retornoArquivoCpf.objeto;
    }

    // SALVANDO ARQUIVO CPF EM DOCUMENTO
    async salvarDocumentoArquivoCpf(pDocumento) {
        let documentoArquivoCpf = this.state.documentoArquivoCpf;
        documentoArquivoCpf.seqUsuario = this.constante.seqUsuario;
        documentoArquivoCpf.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoCpf.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCpf;
        let retornoDocumentoArquivoCpf = await this.documentoService.salvarArquivo(documentoArquivoCpf);
        documentoArquivoCpf = retornoDocumentoArquivoCpf.objeto;
    }

    async selecionarArquivoComprovanteResidencia(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoComprovanteResidencia: {
                        ...this.state.pessoaArquivoComprovanteResidencia,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoComprovanteResidencia: {
                        ...this.state.documentoArquivoComprovanteResidencia,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoComprovanteResidencia: {
                    ...this.state.pessoaArquivoComprovanteResidencia,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoComprovanteResidencia: {
                    ...this.state.documentoArquivoComprovanteResidencia,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }


    // SALVANDO ARQUIVO EM PESSOA
    async salvarArquivoComprovanteResidencia(pPessoa) {
        let pessoa = pPessoa
        let pessoaArquivoComprovanteResidencia = this.state.pessoaArquivoComprovanteResidencia;
        pessoaArquivoComprovanteResidencia.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoComprovanteResidencia.seqPessoa = pessoa.seqPessoa;
        pessoaArquivoComprovanteResidencia.seqTipoArquivo = this.constante.seqTipoArquivoAnexoComprovanteResidencia;
        let retornoArquivoComprovanteResidencia = await this.pessoaService.salvarArquivo(pessoaArquivoComprovanteResidencia);
        pessoaArquivoComprovanteResidencia = retornoArquivoComprovanteResidencia.objeto;
    }


    // SALVANDO ARQUIVO EM DOCUMENTO
    async salvarDocumentoArquivoComprovanteResidencia(pDocumento) {
        let documentoArquivoComprovanteResidencia = this.state.documentoArquivoComprovanteResidencia;
        documentoArquivoComprovanteResidencia.seqUsuario = this.constante.seqUsuario;
        documentoArquivoComprovanteResidencia.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoComprovanteResidencia.seqTipoArquivo = this.constante.seqTipoArquivoAnexoComprovanteResidencia;
        let retornoDocumentoArquivoComprovanteResidencia = await this.documentoService.salvarArquivo(documentoArquivoComprovanteResidencia);
        documentoArquivoComprovanteResidencia = retornoDocumentoArquivoComprovanteResidencia.objeto;
    }

    async selecionarArquivoCertidaoNascimento(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoCertidaoNascimento: {
                        ...this.state.pessoaArquivoCertidaoNascimento,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoCertidaoNascimento: {
                        ...this.state.documentoArquivoCertidaoNascimento,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoCertidaoNascimento: {
                    ...this.state.pessoaArquivoCertidaoNascimento,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoCertidaoNascimento: {
                    ...this.state.documentoArquivoCertidaoNascimento,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }


    // SALVANDO ARQUIVO CERTIDAO NASCIMENTO EM PESSOA
    async salvarArquivoCertidaoNascimento(pPessoa) {
        let pessoaArquivoCertidaoNascimento = this.state.pessoaArquivoCertidaoNascimento;
        pessoaArquivoCertidaoNascimento.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoCertidaoNascimento.seqPessoa = pPessoa.seqPessoa;
        pessoaArquivoCertidaoNascimento.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCertidaoNascimento;
        let retornoArquivoCertidaoNascimento = await this.pessoaService.salvarArquivo(pessoaArquivoCertidaoNascimento);
        pessoaArquivoCertidaoNascimento = retornoArquivoCertidaoNascimento.objeto;
    }

    // SALVANDO ARQUIVO CERTIDAO NASCIMENTO EM DOCUMENTO
    async salvarDocumentoArquivoCertidaoNascimento(pDocumento) {
        let documentoArquivoCertidaoNascimento = this.state.documentoArquivoCertidaoNascimento;
        documentoArquivoCertidaoNascimento.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoCertidaoNascimento.seqUsuario = this.constante.seqUsuario;
        documentoArquivoCertidaoNascimento.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCertidaoNascimento;
        let retornoDocumentoArquivoCertidaoNascimento = await this.documentoService.salvarArquivo(documentoArquivoCertidaoNascimento);
        documentoArquivoCertidaoNascimento = retornoDocumentoArquivoCertidaoNascimento.objeto;
    }



    async selecionarArquivoCertidaoCasamento(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoCertidaoCasamento: {
                        ...this.state.pessoaArquivoCertidaoCasamento,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoCertidaoCasamento: {
                        ...this.state.documentoArquivoCertidaoCasamento,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoCertidaoCasamento: {
                    ...this.state.pessoaArquivoCertidaoCasamento,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoCertidaoCasamento: {
                    ...this.state.documentoArquivoCertidaoCasamento,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }

    // SALVAR ARQUIVO CERTIDAO CASAMENTO EM PESSOA
    async salvarArquivoCertidaoCasamento(pPessoa) {
        let pessoaArquivoCertidaoCasamento = this.state.pessoaArquivoCertidaoCasamento;
        pessoaArquivoCertidaoCasamento.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoCertidaoCasamento.seqPessoa = pPessoa.seqPessoa;
        pessoaArquivoCertidaoCasamento.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCertidaoCasamento;
        let retornoArquivoCertidaoCasamento = await this.pessoaService.salvarArquivo(pessoaArquivoCertidaoCasamento);
        pessoaArquivoCertidaoCasamento = retornoArquivoCertidaoCasamento.objeto;
    }

    // SALVAR ARQUIVO CERTIDAO CASAMENTO EM DOCUMENTO
    async salvarDocumentoArquivoCertidaoCasamento(pDocumento) {
        let documentoArquivoCertidaoCasamento = this.state.documentoArquivoCertidaoCasamento;
        documentoArquivoCertidaoCasamento.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoCertidaoCasamento.seqUsuario = this.constante.seqUsuario;
        documentoArquivoCertidaoCasamento.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCertidaoCasamento;
        let retornoDocumentoArquivoCertidaoCasamento = await this.documentoService.salvarArquivo(documentoArquivoCertidaoCasamento);
        documentoArquivoCertidaoCasamento = retornoDocumentoArquivoCertidaoCasamento.objeto;
    }

    async selecionarArquivoHistoricoEscolar(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoHistoricoEscolar: {
                        ...this.state.pessoaArquivoHistoricoEscolar,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoHistoricoEscolar: {
                        ...this.state.documentoArquivoHistoricoEscolar,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoHistoricoEscolar: {
                    ...this.state.pessoaArquivoHistoricoEscolar,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoHistoricoEscolar: {
                    ...this.state.documentoArquivoHistoricoEscolar,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }


    // SALVAR ARQUIVO HISTORICO ESCOLAR EM PESSOA
    async salvarArquivoHistoricoEscolar(pPessoa) {
        let pessoaArquivoHistoricoEscolar = this.state.pessoaArquivoHistoricoEscolar;
        pessoaArquivoHistoricoEscolar.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoHistoricoEscolar.seqPessoa = pPessoa.seqPessoa;
        pessoaArquivoHistoricoEscolar.seqTipoArquivo = this.constante.seqTipoArquivoAnexoHistoricoEscolar;
        let retornoArquivoHistoricoEscolar = await this.pessoaService.salvarArquivo(pessoaArquivoHistoricoEscolar);
        pessoaArquivoHistoricoEscolar = retornoArquivoHistoricoEscolar.objeto;
    }

    // SALVAR ARQUIVO HISTORICO ESCOLAR EM DOCUMENTO
    async salvarDocumentoArquivoHistoricoEscolar(pDocumento) {
        let documentoArquivoHistoricoEscolar = this.state.documentoArquivoHistoricoEscolar;
        documentoArquivoHistoricoEscolar.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoHistoricoEscolar.seqTipoArquivo = this.constante.seqTipoArquivoAnexoHistoricoEscolar;
        documentoArquivoHistoricoEscolar.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoArquivoHistoricoEscolar = await this.documentoService.salvarArquivo(documentoArquivoHistoricoEscolar);
        documentoArquivoHistoricoEscolar = retornoDocumentoArquivoHistoricoEscolar.objeto;
    }



    async selecionarArquivoCertificadoEnsinoMedio(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoCertificadoEnsinoMedio: {
                        ...this.state.pessoaArquivoCertificadoEnsinoMedio,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoCertificadoEnsinoMedio: {
                        ...this.state.documentoArquivoCertificadoEnsinoMedio,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoCertificadoEnsinoMedio: {
                    ...this.state.pessoaArquivoCertificadoEnsinoMedio,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoCertificadoEnsinoMedio: {
                    ...this.state.documentoArquivoCertificadoEnsinoMedio,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }


    // SALVAR ARQUIVO CERTIFICADO ENSIO MEDIO EM PESSOA
    async salvarArquivoCertificadoEnsinoMedio(pPessoa) {
        let pessoa = pPessoa
        let pessoaArquivoCertificadoEnsinoMedio = this.state.pessoaArquivoCertificadoEnsinoMedio;
        pessoaArquivoCertificadoEnsinoMedio.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoCertificadoEnsinoMedio.seqPessoa = pessoa.seqPessoa;
        pessoaArquivoCertificadoEnsinoMedio.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCertificadoEnsinoMedio;
        let retornoArquivoCertificadoEnsinoMedio = await this.pessoaService.salvarArquivo(pessoaArquivoCertificadoEnsinoMedio);
        pessoaArquivoCertificadoEnsinoMedio = retornoArquivoCertificadoEnsinoMedio.objeto;
    }


    // SALVAR ARQUIVO CERTIFICADO ENSIO MEDIO EM DOCUMENTO

    async salvarDocumentoArquivoCertificadoEnsinoMedio(pDocumento) {
        let documentoArquivoCertificadoEnsinoMedio = this.state.documentoArquivoCertificadoEnsinoMedio;
        documentoArquivoCertificadoEnsinoMedio.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoCertificadoEnsinoMedio.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCertificadoEnsinoMedio;
        documentoArquivoCertificadoEnsinoMedio.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoArquivoCertificadoEnsinoMedio = await this.documentoService.salvarArquivo(documentoArquivoCertificadoEnsinoMedio);
        documentoArquivoCertificadoEnsinoMedio = retornoDocumentoArquivoCertificadoEnsinoMedio.objeto;
    }



    async selecionarArquivoDiarioOficial(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoDiarioOficial: {
                        ...this.state.pessoaArquivoDiarioOficial,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoDiarioOficial: {
                        ...this.state.documentoArquivoDiarioOficial,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoDiarioOficial: {
                    ...this.state.pessoaArquivoDiarioOficial,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoDiarioOficial: {
                    ...this.state.documentoArquivoDiarioOficial,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }

    // SALVAR ARQUIVO DIARIO OFICIAL EM PESSOA
    async salvarArquivoDiarioOficial(pPessoa) {
        let pessoaArquivoDiarioOficial = this.state.pessoaArquivoDiarioOficial;
        pessoaArquivoDiarioOficial.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoDiarioOficial.seqPessoa = pPessoa.seqPessoa;
        pessoaArquivoDiarioOficial.seqTipoArquivo = this.constante.seqTipoArquivoAnexoDiarioOficial;
        let retornoArquivoDiarioOficial = await this.pessoaService.salvarArquivo(pessoaArquivoDiarioOficial);
        pessoaArquivoDiarioOficial = retornoArquivoDiarioOficial.objeto;
    }


    // SALVAR ARQUIVO DIARIO OFICIAL EM DOCUMENTO

    async salvarDocumentoArquivoDiarioOficial(pDocumento) {
        let documentoArquivoDiarioOficial = this.state.documentoArquivoDiarioOficial;
        documentoArquivoDiarioOficial.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoDiarioOficial.seqTipoArquivo = this.constante.seqTipoArquivoAnexoDiarioOficial;
        documentoArquivoDiarioOficial.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoArquivoDiarioOficial = await this.documentoService.salvarArquivo(documentoArquivoDiarioOficial);
        documentoArquivoDiarioOficial = retornoDocumentoArquivoDiarioOficial.objeto;
    }

    async selecionarArquivoFotoTresPorQuatro(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoFotoTresPorQuatro: {
                        ...this.state.pessoaArquivoFotoTresPorQuatro,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoFotoTresPorQuatro: {
                        ...this.state.documentoArquivoFotoTresPorQuatro,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoFotoTresPorQuatro: {
                    ...this.state.pessoaArquivoFotoTresPorQuatro,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoFotoTresPorQuatro: {
                    ...this.state.documentoArquivoFotoTresPorQuatro,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }


    // SALVAR ARQUIVO FOTO TRES POR QUATRO EM PESSOA
    async salvarArquivoFotoTresPorQuatro(pPessoa) {
        let pessoaArquivoFotoTresPorQuatro = this.state.pessoaArquivoFotoTresPorQuatro;
        pessoaArquivoFotoTresPorQuatro.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoFotoTresPorQuatro.seqPessoa = pPessoa.seqPessoa;
        pessoaArquivoFotoTresPorQuatro.seqTipoArquivo = this.constante.seqTipoArquivoAnexoFotoTresPorQuatro;
        let retornoArquivoFotoTresPorQuatro = await this.pessoaService.salvarArquivo(pessoaArquivoFotoTresPorQuatro);
        pessoaArquivoFotoTresPorQuatro = retornoArquivoFotoTresPorQuatro.objeto;
    }

    // SALVAR ARQUIVO FOTO TRES POR QUATRO EM DOCUMENTO
    async salvarDocumentoArquivoFotoTresPorQuatro(pDocumento) {
        let documentoArquivoFotoTresPorQuatro = this.state.documentoArquivoFotoTresPorQuatro;
        documentoArquivoFotoTresPorQuatro.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoFotoTresPorQuatro.seqTipoArquivo = this.constante.seqTipoArquivoAnexoFotoTresPorQuatro;
        documentoArquivoFotoTresPorQuatro.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoArquivoFotoTresPorQuatro = await this.documentoService.salvarArquivo(documentoArquivoFotoTresPorQuatro);
        documentoArquivoFotoTresPorQuatro = retornoDocumentoArquivoFotoTresPorQuatro.objeto;
    }


    async selecionarArquivoCertidaoReservista(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoCertidaoReservista: {
                        ...this.state.pessoaArquivoCertidaoReservista,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoCertidaoReservista: {
                        ...this.state.documentoArquivoCertidaoReservista,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoCertidaoReservista: {
                    ...this.state.pessoaArquivoCertidaoReservista,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoCertidaoReservista: {
                    ...this.state.documentoArquivoCertidaoReservista,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }



    // SALVAR ARQUIVO CERTIDAO RESERVITAR EM PESSOA
    async salvarArquivoCertidaoReservista(pPessoa) {
        let pessoaArquivoCertidaoReservista = this.state.pessoaArquivoCertidaoReservista;
        pessoaArquivoCertidaoReservista.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoCertidaoReservista.seqPessoa = pPessoa.seqPessoa;
        pessoaArquivoCertidaoReservista.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCertidaoReservista;
        let retornoArquivoCertidaoReservista = await this.pessoaService.salvarArquivo(pessoaArquivoCertidaoReservista);
        pessoaArquivoCertidaoReservista = retornoArquivoCertidaoReservista.objeto;
    }

    // SALVAR ARQUIVO CERTIDAO RESERVITAR EM DOCUMENTO

    async salvarDocumentoArquivoCertidaoReservista(pDocumento) {
        let documentoArquivoCertidaoReservista = this.state.documentoArquivoCertidaoReservista;
        documentoArquivoCertidaoReservista.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoCertidaoReservista.seqTipoArquivo = this.constante.seqTipoArquivoAnexoCertidaoReservista;
        documentoArquivoCertidaoReservista.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoArquivoCertidaoReservista = await this.documentoService.salvarArquivo(documentoArquivoCertidaoReservista);
        documentoArquivoCertidaoReservista = retornoDocumentoArquivoCertidaoReservista.objeto;
    }


    async selecionarArquivoTituloEleitor(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoTituloEleitor: {
                        ...this.state.pessoaArquivoTituloEleitor,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoTituloEleitor: {
                        ...this.state.documentoArquivoTituloEleitor,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoTituloEleitor: {
                    ...this.state.pessoaArquivoTituloEleitor,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoTituloEleitor: {
                    ...this.state.documentoArquivoTituloEleitor,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }


    // SALVAR ARQUIVO TITULO ELEITOR EM PESSOA
    async salvarArquivoTituloEleitor(pPessoa) {
        let pessoaArquivoTituloEleitor = this.state.pessoaArquivoTituloEleitor;
        pessoaArquivoTituloEleitor.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoTituloEleitor.seqPessoa = pPessoa.seqPessoa;
        pessoaArquivoTituloEleitor.seqTipoArquivo = this.constante.seqTipoArquivoAnexoTituloEleitor;
        let retornoArquivoTituloEleitor = await this.pessoaService.salvarArquivo(pessoaArquivoTituloEleitor);
        pessoaArquivoTituloEleitor = retornoArquivoTituloEleitor.objeto;
    }

    // SALVAR ARQUIVO TITULO ELEITOR EM DOCUMENTO
    async salvarDocumentoArquivoTituloEleitor(pDocumento) {
        let documentoArquivoTituloEleitor = this.state.documentoArquivoTituloEleitor;
        documentoArquivoTituloEleitor.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoTituloEleitor.seqTipoArquivo = this.constante.seqTipoArquivoAnexoTituloEleitor;
        documentoArquivoTituloEleitor.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoArquivoTituloEleitor = await this.documentoService.salvarArquivo(documentoArquivoTituloEleitor);
        documentoArquivoTituloEleitor = retornoDocumentoArquivoTituloEleitor.objeto;
    }



    async selecionarArquivoQuitacaoEleitoral(pEvento) {
        this.arquivo = pEvento.target.files[0];
        console.log(this.arquivo)

        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.setState({
                    pessoaArquivoQuitacaoEleitoral: {
                        ...this.state.pessoaArquivoQuitacaoEleitoral,
                        arquivo: reader.result.split(',')[1]
                    },
                    documentoArquivoQuitacaoEleitoral: {
                        ...this.state.documentoArquivoQuitacaoEleitoral,
                        arquivo: reader.result.split(',')[1]
                    }
                })
            },
            false
        );

        if (this.arquivo) {
            reader.readAsDataURL(this.arquivo);
            this.setState({
                pessoaArquivoQuitacaoEleitoral: {
                    ...this.state.pessoaArquivoQuitacaoEleitoral,
                    arquivoOriginal: this.arquivo.name
                },
                documentoArquivoQuitacaoEleitoral: {
                    ...this.state.documentoArquivoQuitacaoEleitoral,
                    arquivoOriginal: this.arquivo.name
                }
            })
        }
    }





    // SALVAR ARQUIVO QUITACAO ELEITORAL EM PESSOA
    async salvarArquivoQuitacaoEleitoral(pPessoa) {
        let pessoaArquivoQuitacaoEleitoral = this.state.pessoaArquivoQuitacaoEleitoral;
        pessoaArquivoQuitacaoEleitoral.seqUsuario = this.constante.seqUsuario;
        pessoaArquivoQuitacaoEleitoral.seqPessoa = pPessoa.seqPessoa;
        pessoaArquivoQuitacaoEleitoral.seqTipoArquivo = this.constante.seqTipoArquivoAnexoQuitacaoEleitoral;
        let retornoArquivoQuitacaoEleitoral = await this.pessoaService.salvarArquivo(pessoaArquivoQuitacaoEleitoral);
        pessoaArquivoQuitacaoEleitoral = retornoArquivoQuitacaoEleitoral.objeto;
    }

    // SALVAR ARQUIVO QUITACAO ELEITORAL  EM DOCUMENTO
    async salvarDocumentoArquivoQuitacaoEleitoral(pDocumento) {
        let documentoArquivoQuitacaoEleitoral = this.state.documentoArquivoQuitacaoEleitoral;
        documentoArquivoQuitacaoEleitoral.seqDocumento = pDocumento.seqDocumento;
        documentoArquivoQuitacaoEleitoral.seqTipoArquivo = this.constante.seqTipoArquivoAnexoQuitacaoEleitoral;
        documentoArquivoQuitacaoEleitoral.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoArquivoQuitacaoEleitoral = await this.documentoService.salvarArquivo(documentoArquivoQuitacaoEleitoral);
        documentoArquivoQuitacaoEleitoral = retornoDocumentoArquivoQuitacaoEleitoral.objeto;
    }

    async salvarDocumento(pPessoa, pEndereco) {
        let documento = this.state.documento;
        documento.seqPessoa = pPessoa.seqPessoa;
        documento.seqUsuario = this.constante.seqUsuario;
        documento.seqUsuarioVendedor = this.state.pessoaUsuario.seqPessoa;
        documento.seqTipoDocumento = this.constante.seqTipoDocumento;
        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoNovo;
        documento.seqEndereco = pEndereco.seqEndereco;
        let retornoDocumento = await this.documentoService.salvar(documento);
        documento = retornoDocumento.objeto;
        return documento;
    }

    async salvarDocumentoItem(pDocumento) {
        let documentoItem = this.state.documentoItem;
        documentoItem.seqDocumento = pDocumento.seqDocumento;
        documentoItem.seqItem = this.state.documentoItem.seqItem;
        documentoItem.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoItem = await this.documentoService.salvarItem(documentoItem);
        documentoItem = retornoDocumentoItem.objeto
    }



    async salvarDocumentoRelacionamentoPolo(pDocumento) {
        let pessoa = this.state.pessoaUsuario;
        let documentoRelacionamentoPolo = this.state.documentoRelacionamento;
        documentoRelacionamentoPolo.seqDocumento = pDocumento.seqDocumento;
        documentoRelacionamentoPolo.seqPessoaRelacionada = pessoa.seqPessoa;
        documentoRelacionamentoPolo.seqUsuario = this.constante.seqUsuario;
        documentoRelacionamentoPolo.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoPolo;
        let retornoDocumentoRelacionamentoPolo = await this.documentoService.salvarRelacionamento(documentoRelacionamentoPolo);
        documentoRelacionamentoPolo = retornoDocumentoRelacionamentoPolo.objeto;
    }


    async salvarDocumentoRelacionamentoAluno(pDocumento, pPessoa) {
        let documento = pDocumento;
        let pessoa = pPessoa;
        let documentoRelacionamentoAluno = this.state.documentoRelacionamento;
        documentoRelacionamentoAluno.seqPessoaRelacionada = pessoa.seqPessoa;
        documentoRelacionamentoAluno.seqDocumento = documento.seqDocumento;
        documentoRelacionamentoAluno.seqUsuario = this.constante.seqUsuario;
        documentoRelacionamentoAluno.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoAluno;
        let retornoDocumentoRelacionamentoAluno = await this.documentoService.salvarRelacionamento(documentoRelacionamentoAluno);
        documentoRelacionamentoAluno = retornoDocumentoRelacionamentoAluno.objeto;
    }


    async salvarDocumentoRelacionamentoMae(pDocumento, pPessoaMae) {
        let documento = pDocumento;
        let pessoaMae = pPessoaMae;
        let documentoRelacionamentoMae = this.state.documentoRelacionamento;
        documentoRelacionamentoMae.seqDocumento = documento.seqDocumento;
        documentoRelacionamentoMae.seqPessoaRelacionada = pessoaMae.seqPessoa;
        documentoRelacionamentoMae.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoMae;
        documentoRelacionamentoMae.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoRelacionamentoMae = await this.documentoService.salvarRelacionamento(documentoRelacionamentoMae);
        documentoRelacionamentoMae = retornoDocumentoRelacionamentoMae.objeto;
    }


    async salvarDocumentoRelacionamentoPai(pDocumento, pPessoaPai) {
        let documento = pDocumento;
        let pessoaPai = pPessoaPai;
        let documentoRelacionamentoPai = this.state.documentoRelacionamento;
        documentoRelacionamentoPai.seqDocumento = documento.seqDocumento;
        documentoRelacionamentoPai.seqPessoaRelacionada = pessoaPai.seqPessoa;
        documentoRelacionamentoPai.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoPai;
        documentoRelacionamentoPai.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentodocumentoRelacionamentoPai = await this.documentoService.salvarRelacionamento(documentoRelacionamentoPai);
        documentoRelacionamentoPai = retornoDocumentodocumentoRelacionamentoPai.objeto;
    }


    async salvarDocumentoRelacionamentoConjuge(pDocumento, pPessoaConjuge) {

        let documento = pDocumento;
        let pessoaConjuge = pPessoaConjuge;
        let documentoRelacionamentoConjuge = this.state.documentoRelacionamento;
        documentoRelacionamentoConjuge.seqDocumento = documento.seqDocumento;
        documentoRelacionamentoConjuge.seqPessoaRelacionada = pessoaConjuge.seqPessoa;
        documentoRelacionamentoConjuge.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoConjuge;
        documentoRelacionamentoConjuge.seqUsuario = this.constante.seqUsuario;
        let retornoDocumentoRelacionamentoConjuge = await this.documentoService.salvarRelacionamento(documentoRelacionamentoConjuge);
        documentoRelacionamentoConjuge = retornoDocumentoRelacionamentoConjuge.objeto;
    }


    async limparCampos() {
        this.setState({
            pessoa: new Pessoa(),
            pessoaMae: new Pessoa(),
            pessoaPai: new Pessoa(),
            pessoaUsuario: new Pessoa(),
            pessoaConjuge: new Pessoa(),
            pessoaRelacionamento: new PessoaRelacionamento(),
            pessoaCaracteristicaPessoaEstadoCivil: new PessoaCaracteristicaPessoa(),
            contatoFixo: new Contato(),
            contatoCelular: new Contato(),
            contatoEmail: new Contato(),
            endereco: new Endereco(),
            pessoaArquivoRg: new PessoaArquivo(),
            pessoaArquivoCpf: new PessoaArquivo(),
            pessoaArquivoComprovanteResidencia: new PessoaArquivo(),
            pessoaArquivoCertidaoNascimento: new PessoaArquivo(),
            pessoaArquivoCertidaoCasamento: new PessoaArquivo(),
            pessoaArquivoHistoricoEscolar: new PessoaArquivo(),
            pessoaArquivoCertificadoEnsinoMedio: new PessoaArquivo(),
            pessoaArquivoFotoTresPorQuatro: new PessoaArquivo(),
            pessoaArquivoCertidaoReservista: new PessoaArquivo(),
            pessoaArquivoTituloEleitor: new PessoaArquivo(),
            documento: new Documento(),
            documentoItem: new DocumentoItem(),
            documentoArquivoRg: new DocumentoArquivo(),
            documentoArquivoCpf: new DocumentoArquivo(),
            documentoArquivoComprovanteResidencia: new DocumentoArquivo(),
            documentoArquivoCertidaoNascimento: new DocumentoArquivo(),
            documentoArquivoCertidaoCasamento: new DocumentoArquivo(),
            documentoArquivoHistoricoEscolar: new DocumentoArquivo(),
            documentoArquivoCertificadoEnsinoMedio: new DocumentoArquivo(),
            documentoArquivoFotoTresPorQuatro: new DocumentoArquivo(),
            documentoArquivoCertidaoReservista: new DocumentoArquivo(),
            documentoArquivoTituloEleitor: new DocumentoArquivo(),
            documentoRelacionamento: new DocumentoRelacionamento(),
            loadingVisivel: false,
        })
    }
    render() {
        return (
            <Container>
                <Loading
                    visivel={this.state.loadingVisivel}
                />
                {this.state.mudarTela && (
                    <Navigate to='/login' replace={true} />
                )}
                {this.state.limparTela && <Navigate to="/documento" />}
                <Header selecionado="requisicaoMatricula" />

                <img src={Logo} alt="Logo El Cursos" width={200} height={200} style={{ alignSelf: 'center' }} />
                <form onSubmit={(pSubmit) => this.salvar(pSubmit)}
                    className="form-matricula"
                    autoComplete={false}>


                    <Input
                        titulo="Nome Completo *"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.setState({
                            pessoa: {
                                ...this.state.pessoa, nome: pTexto.target.value
                            }
                        })}
                        value={this.state.pessoa.nome || ''}
                        required
                    />

                    <label>Gênero *</label><br />
                    <select
                        className='input'
                        onChange={(pOption) => this.setState({
                            documento: {
                                ...this.state.documento, tags9: pOption.target.value
                            }
                        })}
                        style={{ width: '100%', backgroundColor: 'white' }}
                        required
                    >
                        <option value={null}>Selecione</option>
                        <option>Feminino</option>
                        <option>Masculino</option>
                    </select>


                    <Input
                        titulo="RG *"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.setState({
                            documento: {
                                ...this.state.documento, tags2: pTexto.target.value
                            }
                        })}
                        value={this.state.documento.tags2 || ''}
                        required
                    />


                    <Input
                        titulo="Orgão emissor *"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.setState({
                            documento: {
                                ...this.state.documento, tags3: pTexto.target.value
                            }
                        })}
                        value={this.state.documento.tags3 || ''}
                        required
                    />


                    <Input
                        titulo="CPF *"
                        style={{ width: '100%' }}
                        onChange={pTexto => this.formatarCPF(pTexto.target.value)}
                        value={this.state.pessoa.documento || ''}
                        required
                    />
                    <Input
                        titulo="Nacionalidade *"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.setState({
                            documento: {
                                ...this.state.documento, tags4: pTexto.target.value
                            }
                        })}
                        value={this.state.documento.tags4 || ''}
                        required
                    />

                    <Input
                        titulo="Naturalidade *"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.setState({
                            documento: {
                                ...this.state.documento, tags5: pTexto.target.value
                            }
                        })}
                        value={this.state.documento.tags5 || ''}
                        required
                    />



                    <Input
                        titulo="Data de nascimento *"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.setState({
                            documento: {
                                ...this.state.documento, tagd0: pTexto.target.value
                            }
                        })}
                        type='date'
                        value={this.state.documento.tagd0 || ''}
                        required
                    />




                    <label>Estado civil *</label><br />
                    <select
                        className='input'
                        onChange={(pOption) => this.setState({
                            // pessoaCaracteristicaPessoaEstadoCivil: {
                            //     ...this.state.pessoaCaracteristicaPessoaEstadoCivil, seqPessoaCaracteristica: pOption.target.value,
                            // },
                            documentoCaracteristicaEstadoCivil: {
                                ...this.state.documentoCaracteristicaEstadoCivil, seqDocumentoCaracteristica: pOption.target.value,
                            }
                        })}
                        style={{ width: '100%', backgroundColor: 'white' }}
                        required
                    >
                        <option value={null}>Selecione</option>
                        <option value={this.constante.seqDocumentoCaracteristicaSolteiro}>Solteiro(a)</option>
                        <option value={this.constante.seqDocumentoCaracteristicaCasado}>Casado(a)</option>
                        <option value={this.constante.seqDocumentoCaracteristicaViuvo}>Viúvo(a)</option>
                    </select>


                    {this.state.documentoCaracteristicaEstadoCivil.seqDocumentoCaracteristica == this.constante.seqDocumentoCaracteristicaCasado ?
                        <Input
                            titulo="Nome do cônjuge *"
                            style={{ width: '100%' }}
                            onChange={(pTexto) => this.setState({
                                pessoaConjuge: {
                                    ...this.state.pessoaConjuge, nome: pTexto.target.value
                                }
                            })}
                            value={this.state.pessoaConjuge.nome || ''}
                            required

                        />
                        : null}


                    <Input
                        titulo="Nome da mãe *"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.setState({
                            pessoaMae: {
                                ...this.state.pessoaMae, nome: pTexto.target.value
                            }
                        })}
                        value={this.state.pessoaMae.nome || ''}
                        required

                    />



                    <Input
                        titulo="Nome do pai"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.setState({
                            pessoaPai: {
                                ...this.state.pessoaPai, nome: pTexto.target.value
                            }
                        })}
                        value={this.state.pessoaPai.nome || ''}
                    />



                    <label>Endereço *</label>
                    <input
                        style={{ width: '100%' }}
                        onChange={pTexto => this.listarEnderecoGoogle(pTexto.target.value)}
                        list="listagoogle"
                        required
                    />
                    <datalist id='listagoogle'>
                        {this.state.listaEnderecoGoogle.map(
                            item =>
                                <option value={item.description}>{item.description}</option>
                        )}
                    </datalist>
                    <br />

                    <Input
                        titulo="Fone residência"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.formatarTelefone(pTexto.target.value)}
                        value={this.state.documento.tags0 || ''}
                        required
                    />



                    <Input
                        titulo="Celular"
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.formatarCelular(pTexto.target.value)}
                        value={this.state.documento.tags1 || ''}
                        required
                    />



                    <Input
                        titulo="E-mail *"
                        type='email'
                        style={{ width: '100%' }}
                        onChange={(pTexto) => this.setState({
                            pessoa: {
                                ...this.state.pessoa, emailPlataformaLirida: pTexto.target.value
                            }
                        })}
                        value={this.state.pessoa.emailPlataformaLirida || ''}
                        required
                    />




                    <label>Curso desejado *</label><br />
                    <select
                        className='input'
                        onChange={(pOption) => this.setState({
                            documentoItem: {
                                ...this.state.documentoItem, seqItem: Number(pOption.target.value),
                            }
                        })}
                        style={{ width: '100%', backgroundColor: 'white' }}
                    >

                        <option value={null}>Selecione um item</option>

                        {this.state.listaTabelaPreco.map((item) =>
                            <option
                                key={item.seqTabelaPrecoItem}
                                selected={
                                    this.state.documentoItem.seqItem === item.seqItem ?
                                        true : false
                                }
                                value={item.seqItem}>
                                {item.itemNomeVitrine}
                            </option>
                        )}
                    </select>


                    <div className="box-inputs-anexos">
                        <div>
                            <Input
                                titulo='Anexo documento com foto'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoRg(pEvento)}
                                required
                            />

                            <Input
                                titulo='Anexo CPF'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoCpf(pEvento)}
                                required
                            />


                            <Input
                                titulo='Anexo comprovante de residência'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoComprovanteResidencia(pEvento)}
                                required
                            />

                            <Input
                                titulo='Anexo Certidão de Nascimento'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoCertidaoNascimento(pEvento)}
                                required
                            />

                            <Input
                                titulo='Anexo Histórico Escolar'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoHistoricoEscolar(pEvento)}
                                required
                            />

                            <Input
                                titulo='Certificado de Conclusão do Ensino Médio'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoCertificadoEnsinoMedio(pEvento)}
                                required
                            />


                        </div>

                        <div>
                            {this.state.documentoCaracteristicaEstadoCivil.seqDocumentoCaracteristica == this.constante.seqDocumentoCaracteristicaCasado ?

                                <Input
                                    titulo='Certidão casamento'
                                    type='file'
                                    onChange={pEvento => this.selecionarArquivoCertidaoCasamento(pEvento)}
                                    required
                                /> : null}

                            <Input
                                titulo='Diario oficial'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoDiarioOficial(pEvento)}
                                required
                            />



                            <Input
                                titulo='Anexo 01 FOTO 3X4'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoFotoTresPorQuatro(pEvento)}
                                required
                            />

                            {this.state.documento.tags9 !== 'Feminino' &&
                                <Input
                                    titulo='Anexo Certidão de Reservista'
                                    type='file'
                                    onChange={pEvento => this.selecionarArquivoCertidaoReservista(pEvento)}
                                    required
                                />
                            }

                            <Input
                                titulo='Anexo Título de Eleitor'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoTituloEleitor(pEvento)}
                                required
                            />


                            <Input
                                titulo='Anexo quitação eleitoral'
                                type='file'
                                onChange={pEvento => this.selecionarArquivoQuitacaoEleitoral(pEvento)}
                                required
                            />

                        </div>
                    </div>

                    <button
                        type="submit"
                        style={{ width: '100%', marginTop: 20, }}
                        className='btn btn-com-fundo'>Salvar</button>
                </form>
            </Container>
        )
    }
}